import { useQuery } from 'react-query'
import { getRecentSubmissions, CacheKeys } from './common'

export default function useRecentSubmissions() {
  return useQuery(CacheKeys.RecentSubmissions, getRecentSubmissions(), {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    retry: false,
  })
}

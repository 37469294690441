import { ListItems } from './db'
import { ListItem } from 'core/ListItem'
import { reject, pipe } from 'ramda'

export function sortItems(items: ListItem[]) {
  return items.sort((a, b) => {
    if (a.displayOrder != null && b.displayOrder == null) return -1
    if (
      a.displayOrder != null &&
      b.displayOrder != null &&
      a.displayOrder < b.displayOrder
    )
      return -1
    return 0
  })
}

export const filterReadOnly = (items: ListItem[]) => {
  return reject((item: ListItem) => item.readOnly || false, items)
}

export function getListItems(listName: string) {
  return ListItems.where('listName')
    .equals(listName)
    .toArray()
    .then(pipe(filterReadOnly, sortItems))
    .catch((err) => {
      console.error('Error retrieving list items from IDB')
      throw err
    })
}

import React from 'react'
import { Controller } from 'react-hook-form'

import { SelectField } from 'core/ContentField'

import SelectContainer from '../SelectContainer'

interface SelectFilterFieldProps {
  fieldModel: SelectField
}

const SelectFilterField: React.FC<SelectFilterFieldProps> = (props) => {
  const { fieldModel } = props
  const { name } = fieldModel

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <SelectContainer
          isMultiple
          // HACK `label` is required in `FieldProps` interface, but if we pass
          // an empty string we achieve the same result as not having a label
          // (disregarding accesibility implications)
          label=""
          listName={fieldModel.listName}
          name={name}
          value={field.value}
          onChange={(_name, value) => field.onChange(value)}
        />
      )}
    />
  )
}

export default SelectFilterField

import React, { useEffect, useRef, useCallback } from 'react'
import {
  IonList,
  IonItemGroup,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
  IonIcon,
} from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import AppLayout from 'ui/layouts/AppLayout'
import ListSkeleton from 'ui/components/loaders/ListSkeleton'

import useTranslation from 'hooks/useTranslation'
import useDocuments from 'hooks/useDocuments'
import { fetchRemoteFile } from 'services/network/api'

const Help: React.FC = () => {
  const t = useTranslation()
  const cancelController = useRef(new AbortController())

  const { data: docs, isLoading } = useDocuments()
  const handleGetDocument = useCallback(
    async (url: string, fileName: string) => {
      await fetchRemoteFile(url, cancelController.current.signal)
        .then((blob: any) => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((err: any) => {
          console.error('Error getting document', err)
        })
    },
    [],
  )
  useEffect(() => {
    let controller = cancelController.current
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <AppLayout pageTitle={t('settings.help')} showOffline>
      <IonList>
        <IonItemGroup>
          {isLoading ? (
            <ListSkeleton />
          ) : (
            docs?.map((doc) => (
              <IonItem
                key={doc.id}
                onClick={() => handleGetDocument(doc.url, doc.filename)}
                target="_blank"
                /*                 href={doc.url}
                download={doc.filename}
                 */
              >
                <IonLabel>
                  <IonText>{doc.filename}</IonText>
                  <br />
                  {doc.description ? (
                    <IonNote color="medium">{doc.description}</IonNote>
                  ) : null}
                </IonLabel>
                <IonIcon
                  slot="end"
                  icon={cloudDownloadOutline}
                  color="primary"
                />
              </IonItem>
            ))
          )}
        </IonItemGroup>
      </IonList>
    </AppLayout>
  )
}

export default Help

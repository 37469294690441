import { AppTheme } from 'core/Theme'
import React, { useEffect, useState } from 'react'
import defaultLogo from '../../../logos/logo-ines.png'
const AppLogo: React.FC = () => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null)
  useEffect(() => {
    import('../../../logos/' + AppTheme.logoUrl)
      .then((mod) => setLogoUrl(mod.default))
      .catch((err) => {
        console.error('Error al cargar el logotipo ' + AppTheme.logoUrl, err)
        setLogoUrl(defaultLogo)
      })
  }, [])
  return logoUrl ? <img src={logoUrl} alt="Logotipo" /> : null
}

export default AppLogo

import { Models, ListItems, Layouts, ModelSummaries, clearConfig } from './db'
import { fetchRemoteFile, get } from './network/api'
import { Config } from '../core/Config'
import { API_URL } from './network/constants'
import { getJWT } from './user'
import { makeModelSummary } from 'core/ModelSummary'
import { requestPersistentStorageIfNeeded } from './permissions'
import {
  ContentModel,
  getFieldsWithImage,
  getSketchsBackgrounds,
} from 'core/ContentModel'
import { saveFile } from './files'
import { ListItem } from 'core/ListItem'

export const fetchAppConfig = (token?: string) => {
  return get(`${API_URL}/app-config?source=inspector`, {
    Authorization: token || getJWT(),
  })
}

export const removeConfigFromDB = async () => {
  await clearConfig()
}

export const saveConfigOnDb = async (config: Config) => {
  await requestPersistentStorageIfNeeded()
  await removeConfigFromDB()
  const { models = [], layouts = [], listItems = [] } = config

  Models.bulkAdd(models)
  Layouts.bulkAdd(layouts)
  ListItems.bulkAdd(listItems)
  ModelSummaries.bulkAdd(models.map(makeModelSummary))
  await saveFieldImages(models)
  await saveListItemImages(listItems)
}

const saveFieldImages = async (models: ContentModel[]) => {
  const fileIdsToDownload = extractFieldImages(models)
  let count = 0
  for (const fileId of fileIdsToDownload) {
    await downloadRemoteFile(fileId)
    count++
  }
  process.env.NODE_ENV === 'development' &&
    console.log('Saved ' + count + ' images for offline usage')
}
const saveListItemImages = async (listItems: ListItem[]) => {
  const fileIdsListItemsToDownload = extractItemImages(listItems)
  let count = 0

  for (const fileId of fileIdsListItemsToDownload) {
    await downloadRemoteFile(fileId)
    count++
  }

  process.env.NODE_ENV === 'development' &&
    console.log('Saved ' + count + ' images for offline usage')
}

const downloadRemoteFile = (fileId: string) =>
  fetchRemoteFile(`${API_URL}/images/${fileId}`)
    .then((blob) => {
      if (blob) {
        return saveFile(fileId, blob)
      }
    })
    .catch((err) => {
      console.warn('Error downloading image', fileId, err)
    })

const extractFieldImages = (models: ContentModel[]): string[] => {
  const distinctFileIds = models.reduce((acc, model) => {
    const fieldWithImages = getFieldsWithImage(model)
    getSketchsBackgrounds(model).forEach((f) => {
      acc.add(f.backgroundFileId!)
    })
    fieldWithImages.forEach((f) => {
      acc.add(f.fileId!)
    })
    return acc
  }, new Set())
  return Array.from(distinctFileIds.values()) as string[]
}

const extractItemImages = (listItems: ListItem[]): string[] => {
  const distinctFileIds = listItems.reduce((acc, listItem) => {
    listItem.mediaFileId != null && acc.add(listItem.mediaFileId!)
    return acc
  }, new Set())

  return Array.from(distinctFileIds.values()) as string[]
}

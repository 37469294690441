import { useQuery } from 'react-query'
import { CacheKeys } from './common'
import { getSubmission } from 'services/submissions'

export default function useSubmission(id: string, options: {}) {
  return useQuery([CacheKeys.Submission, id], () => getSubmission(id), {
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  })
}

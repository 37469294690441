import qs from 'qs'

import { get } from './network/api'
import { API_URL } from './network/constants'

import { GEOLOCATION_OPTIONS } from 'configuration'

export interface LocalConfiguration {
  lastRefresh: number | null
  locale: string
  gpsTimeout: number
  autoDownloadImages: boolean
  zipWithImages: boolean
}

const KEY = 'configuration'

const DEFAULT_CONFIG: LocalConfiguration = {
  lastRefresh: null,
  locale: process.env.REACT_APP_DEFAULT_LOCALE || 'es-ES',
  gpsTimeout: GEOLOCATION_OPTIONS.timeout || 10000,
  autoDownloadImages: false,
  zipWithImages: false,
}

export function saveConfig(config: LocalConfiguration): boolean {
  try {
    localStorage.setItem(KEY, JSON.stringify(config))
    return true
  } catch (err) {
    console.warn('Error saving config', err)
    return false
  }
}

export function loadConfig(): LocalConfiguration {
  const storedConfig = localStorage.getItem(KEY)
  if (storedConfig) {
    try {
      return JSON.parse(storedConfig)
    } catch (err) {
      console.warn('Error loading config', err)
      return DEFAULT_CONFIG
    }
  }
  return DEFAULT_CONFIG
}

// help section
const HELP_DOCUMENTS_DOCTYPE = process.env.REACT_APP_HELP_DOCUMENTS_DOCTYPE

interface Document {
  id: string
  uri: string
  filename: string
  fileType: string
  size: number
  createdAt: string
  createdBy: string
  updatedAt: string
  // NOTE included in API spec (OpenAPI), missing in response
  // updatedBy: string
  description?: string
  docType: string
  fileGroup: string
  // NOTE missing in API spec (OpenAPI), included in response
  mainLogo?: boolean
}

interface ExtendedDocument extends Document {
  url: string
}

const addDocumentUrl = (doc: Document): ExtendedDocument =>
  Object.assign({}, doc, {
    url: `${API_URL}/uploads/${doc.uri}`,
  })

export const fetchDocuments = async (): Promise<ExtendedDocument[]> => {
  let URL = `${API_URL}/documents`

  if (HELP_DOCUMENTS_DOCTYPE) {
    const query = {
      metadata: {
        key: 'docType',
        value: HELP_DOCUMENTS_DOCTYPE,
      },
    }
    URL += qs.stringify(query, { addQueryPrefix: true })
  }

  return get(URL).then((docs) => docs.map(addDocumentUrl))
}

import React from 'react'
import {
  IonList,
  IonItem,
  IonAccordionGroup,
  IonAccordion,
  IonSpinner,
} from '@ionic/react'

import InspectionItem, {
  ValidationStatus,
} from 'ui/components/items/InspectionItem'
import Typography, { Variant } from 'ui/components/typography/Typography'
import { EmptyItem } from 'ui/components/loaders/EmptyItem'

import useTranslation from 'hooks/useTranslation'
import useAssetInspections from 'hooks/useAssetInspections'

import './DownloadAssetMenu.css'

interface DownloadAssetMenuProps {
  id: string
  onDownloadAsset: () => void
  onDownloadAssetWithInspection: (inspectionId: string) => void
}

const DownloadAssetMenu: React.FC<DownloadAssetMenuProps> = (props) => {
  const { id, onDownloadAsset, onDownloadAssetWithInspection } = props
  const t = useTranslation()

  const { data, isLoading } = useAssetInspections(id)

  return (
    <IonList>
      <IonItem button lines="none" onClick={onDownloadAsset}>
        {t('import.download.asset')}
      </IonItem>
      <IonAccordionGroup>
        <IonAccordion>
          <IonItem slot="header">{t('import.download.inspection')}</IonItem>
          {isLoading ? (
            <div className="download-asset-menu-spinner" slot="content">
              <IonSpinner color="primary" />
              <Typography variant={Variant.subtitle2}>
                {t('import.searching.inspection')}
              </Typography>
            </div>
          ) : (
            <IonList slot="content">
              {data?.length === 0 ? (
                <EmptyItem text={t('empty.data')} />
              ) : (
                data?.map((inspection) => (
                  <InspectionItem
                    key={inspection.inspectionId}
                    type={inspection.inspectionModelId}
                    date={inspection.inspectionDate}
                    status={
                      inspection.validated
                        ? ValidationStatus.Validated
                        : ValidationStatus.Pending
                    }
                    onClick={() =>
                      onDownloadAssetWithInspection(inspection.inspectionId)
                    }
                  />
                ))
              )}
            </IonList>
          )}
        </IonAccordion>
      </IonAccordionGroup>
    </IonList>
  )
}

export default DownloadAssetMenu

import React from 'react'
import { Controller } from 'react-hook-form'
import {
  IonItemGroup,
  IonItem,
  IonLabel,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
} from '@ionic/react'

import useTranslation from 'hooks/useTranslation'
import { useCurrentLocale } from 'context/LocaleContext'

import { DateTimeField } from 'core/ContentField'

interface ControlledDatetimeProps {
  name: string
}

const ControlledDatetime: React.FC<ControlledDatetimeProps> = (props) => {
  const { name } = props

  const locale = useCurrentLocale()
  const t = useTranslation()

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <>
          <IonDatetimeButton datetime={name} />
          <IonModal keepContentsMounted>
            <IonDatetime
              locale={locale}
              presentation="date"
              firstDayOfWeek={1}
              showClearButton
              clearText={t('buttons.removeSelection')}
              id={name}
              name={name}
              value={field.value}
              onIonChange={field.onChange}
              onIonBlur={field.onBlur}
            />
          </IonModal>
        </>
      )}
    />
  )
}

interface DatetimeFilterFieldProps {
  fieldModel: DateTimeField
}

const DatetimeFilterField: React.FC<DatetimeFilterFieldProps> = (props) => {
  const { fieldModel } = props
  const { name } = fieldModel

  const t = useTranslation()

  return (
    <IonItemGroup>
      <IonItem>
        <IonLabel>{t('field.from')}</IonLabel>
        <ControlledDatetime name={`${name}.gte`} />
      </IonItem>
      <IonItem>
        <IonLabel>{t('field.to')}</IonLabel>
        <ControlledDatetime name={`${name}.lte`} />
      </IonItem>
    </IonItemGroup>
  )
}

export default DatetimeFilterField

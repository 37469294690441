import React from 'react'
import AppLayout from 'ui/layouts/AppLayout'
import CtaButton from 'ui/components/buttons/CtaButton'
import './Import.css'

const Import: React.FC = () => {
  return (
    <AppLayout pageTitle="Importar" showOffline>
      <div className="import-content-wrapper">
        <CtaButton
          mainText="Activos"
          fill="solid"
          routerLink="/import/assets"
        />
      </div>
    </AppLayout>
  )
}

export default Import

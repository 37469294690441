import { useMemo } from 'react'

import { FullAsset, AssetType } from 'core/Assets'
import { ContentModel } from 'core/ContentModel'
import { Submission } from 'core/Submission'

import { SortOptions } from 'ui/components/order/Order'

type Asset = FullAsset | Submission

const setCategoryToAsset = (asset: Asset, category: AssetType) => ({
  ...asset,
  category,
})

const getAssetName = (asset: Asset) =>
  asset.label || asset.meanings?.title || ''

const parseDate = (dateString?: string | Date): number => {
  if (dateString instanceof Date) {
    return dateString.getTime()
  }
  return new Date(dateString ?? '').getTime()
}

const isFullAsset = (asset: Asset): asset is FullAsset => {
  return (asset as FullAsset).updatedAt !== undefined
}

const isSubmission = (asset: Asset): asset is Submission => {
  return (asset as Submission).lastUpdatedAt !== undefined
}

const useOrderAssets = (
  selectedModels: ContentModel[],
  remoteAssetsByModel: Record<string, Asset[]>,
  localAssetsByModel: Record<string, Asset[]>,
  sortOptions: SortOptions,
) => {
  const sortedAssets = useMemo(() => {
    const assetsByModel: Record<string, Asset[]> = {}

    selectedModels.forEach((model) => {
      const remoteAssets =
        remoteAssetsByModel[model.id]?.map((asset) =>
          setCategoryToAsset(asset, AssetType.Remote),
        ) ?? []

      const localAssets =
        localAssetsByModel[model.id]?.map((asset) =>
          setCategoryToAsset(asset, AssetType.Local),
        ) ?? []

      assetsByModel[model.id] = [...remoteAssets, ...localAssets]
    })

    if (sortOptions.name.enabled) {
      Object.keys(assetsByModel).forEach((modelId) => {
        assetsByModel[modelId] = [...assetsByModel[modelId]].sort((a, b) => {
          const labelA = getAssetName(a)
          const labelB = getAssetName(b)

          if (sortOptions.name.direction === 'asc') {
            return labelA.localeCompare(labelB)
          } else {
            return labelB.localeCompare(labelA)
          }
        })
      })
    }

    if (sortOptions.date.enabled) {
      Object.keys(assetsByModel).forEach((modelId) => {
        assetsByModel[modelId] = [...assetsByModel[modelId]].sort((a, b) => {
          const dateA = isFullAsset(a)
            ? a.updatedAt
            : isSubmission(a)
              ? a.lastUpdatedAt
              : ''
          const dateB = isFullAsset(b)
            ? b.updatedAt
            : isSubmission(b)
              ? b.lastUpdatedAt
              : ''

          const timestampA = parseDate(dateA)
          const timestampB = parseDate(dateB)

          if (sortOptions.date.direction === 'asc') {
            return timestampA - timestampB
          } else {
            return timestampB - timestampA
          }
        })
      })
    }

    return assetsByModel
  }, [
    localAssetsByModel,
    remoteAssetsByModel,
    selectedModels,
    sortOptions.date.direction,
    sortOptions.date.enabled,
    sortOptions.name.direction,
    sortOptions.name.enabled,
  ])

  const sortedModels = useMemo(() => {
    if (sortOptions.name.enabled) {
      return [...selectedModels].sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (sortOptions.name.direction === 'asc') {
          return nameA.localeCompare(nameB)
        } else {
          return nameB.localeCompare(nameA)
        }
      })
    }

    return selectedModels
  }, [selectedModels, sortOptions])

  return { models: sortedModels, assets: sortedAssets }
}

export default useOrderAssets

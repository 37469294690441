import React from 'react'
import cx from 'classnames'
import { IonText } from '@ionic/react'
import { JSX } from '@ionic/core/dist/types/components.d'

import './Typography.css'

// REVIEW are `button` adn `breadcrumb` variants really necessary?
export enum Variant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  body1 = 'body1',
  body2 = 'body2',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  button = 'button',
  caption = 'caption',
  overline = 'overline',
  breadcrumb = 'breadcrumb',
}

// HACK ionic does not expose complete interfaces for its components (they lack
// common react props like `children`, `className`, `style` or `ref`)
// REVIW can we improve this interface somehow? (e.g. by extending HTMLElement)
interface TypographyProps extends JSX.IonText {
  variant: Variant
  // HACK incluide common react properties missing in IonText interface
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

// NOTE the following definition (from @ionic/react/dist/types/components) does
// not work because we are not defining a $$typeof property, which is required
// by ForwardRefExoticComponent
//
// import { StyleReactProps } from '@ionic/react/dist/types/components/react-component-lib/interfaces'
//
// interface TypographyProps
//   extends React.ForwardRefExoticComponent<
//     JSX.IonText &
//       Omit<
//         React.HTMLAttributes<HTMLIonTextElement>,
//         'style'
//       > &
//       StyleReactProps &
//       React.RefAttributes<HTMLIonTextElement>
//   > {
//   variant: Variant
// }

const Typography: React.FC<TypographyProps> = (props) => {
  const { variant, className, ...rest } = props

  return (
    <IonText className={cx(`typography-${variant}`, className)} {...rest} />
  )
}

export default Typography

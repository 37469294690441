import { format, parseISO } from 'date-fns'

export default function formatDate(d: Date | number | string | undefined) {
  if (!d) return '-'
  const date = new Date(d)
  return date.toLocaleString()
}

export const parseDate = (date: string, displayFormat = 'dd/MM/yyyy') => {
  try {
    return format(
      parseISO(date),
      displayFormat.replace(/D/g, 'd').replace(/Y/g, 'y'),
    )
  } catch (e: any) {
    return date
  }
}

import { useQuery } from 'react-query'
import { SubmissionStatus } from 'core/Submission'
import { getSubmissionsByStatus, CacheKeys } from './common'

export default function useSubmissionByStatus(
  status: SubmissionStatus | SubmissionStatus[],
) {
  return useQuery(
    [CacheKeys.SubmissionByStatus, status],
    getSubmissionsByStatus(status),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      retry: false,
      forceFetchOnMount: true,
    },
  )
}

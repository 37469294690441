import React, { useCallback } from 'react'
import { checkmarkOutline } from 'ionicons/icons'
import { IonItem, IonIcon, IonLabel } from '@ionic/react'
import HintImage from './HintImage'

export interface SelectOptionProps {
  value: string
  label: string
  selected: boolean
  onSelect: (value: string) => void
  fileId?: string
}

const SelectOption: React.FC<SelectOptionProps> = ({
  label,
  value,
  selected,
  onSelect,
  fileId,
}) => {
  const handleClick = useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  return (
    <IonItem lines="full" detail={false} button onClick={handleClick}>
      <IonLabel
        className="ion-text-wrap"
        color={selected ? 'primary' : 'default'}
      >
        {label}
        {fileId && <HintImage fileId={fileId} />}
      </IonLabel>
      {selected ? (
        <IonIcon
          icon={checkmarkOutline}
          color="primary"
          size="small"
          slot="end"
        />
      ) : null}
    </IonItem>
  )
}

export default SelectOption

import { get, post } from './network/api'
import { UserCredentials } from '../core/User'
import { API_URL } from './network/constants'

interface LocalUserInfo {
  username: string
  jwt: string
}

export const loginWithCredentials = async ({
  username,
  password,
}: UserCredentials) => {
  return await post(`${API_URL}/authentication`, {
    strategy: 'local',
    login: username,
    password,
  }).then(({ accessToken }) => accessToken)
}

export const refreshToken = () => {
  const token = getJWT()
  return post(`${API_URL}/authentication`, {
    strategy: 'jwt',
    accessToken: token,
  }).then(({ accessToken }) => accessToken)
}

export async function getUserDetails(token: string) {
  return await get(`${API_URL}/me`, {
    Authorization: token,
  })
}

export const removeUserFromDB = async () => {
  localStorage.removeItem('user')
}

export const saveUserOnDB = async (username: string, jwt: string) => {
  try {
    localStorage.setItem('user', JSON.stringify({ username, jwt }))
  } catch (err) {
    console.error('Error setting user on localStorage')
    throw err
  }
}

export const getLoggedUser = (): LocalUserInfo | undefined => {
  const user = localStorage.getItem('user')
  try {
    if (user) {
      return JSON.parse(user)
    }
  } catch (err) {
    console.error('Error retrieving logged user from localStorage')
    throw err
  }
}

export const getJWT = () => {
  const user = getLoggedUser()
  return user?.jwt
}

import { useEffect, useState, useCallback } from 'react'

const Status = {
  ONLINE: true,
  OFFLINE: false,
}

const getOnlineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true

export default function useConnectionStatus() {
  const [connectionStatus, setConnectionStatus] =
    useState<boolean>(getOnlineStatus())

  const onlineHandler = useCallback(function () {
    setConnectionStatus(Status.ONLINE)
  }, [])
  const offlineHandler = useCallback(function () {
    setConnectionStatus(Status.OFFLINE)
  }, [])

  useEffect(() => {
    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [offlineHandler, onlineHandler])

  return connectionStatus
}

import React from 'react'
import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import { hourglassOutline, checkmarkCircleOutline } from 'ionicons/icons'

import Typography, { Variant } from 'ui/components/typography/Typography'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import useModel from 'hooks/useModel'

import { getLocalizedProperty } from 'core/common'
import formatDate from 'common/formatDate'

import './InspectionItem.css'

export enum ValidationStatus {
  Validated = 'validated',
  Pending = 'pending',
}

interface InspectionItemProps {
  type: string
  date: string
  status?: ValidationStatus
  onClick: () => void
}

// REVIEW there is already a `InspectionItem` component at `src/components`
const InspectionItem: React.FC<InspectionItemProps> = (props) => {
  const { type, date, status, onClick } = props
  const locale = useCurrentLocale()
  const t = useTranslation()

  const { data: model } = useModel(type)

  const isValidated = status === ValidationStatus.Validated

  // NOTE let TS know model is defined
  if (!model) return null

  return (
    <IonItem button lines="none" className="inspection-item" onClick={onClick}>
      <IonLabel className="inspection-item-label">
        <Typography variant={Variant.subtitle1}>
          {getLocalizedProperty(locale, 'label', model)}
        </Typography>
        <div className="inspection-item-details">
          <Typography variant={Variant.subtitle2}>
            {t('inspection.date')}
          </Typography>
          <Typography
            className="inspection-item-secondary-text"
            variant={Variant.body2}
          >
            {formatDate(date)}
          </Typography>
        </div>
        {model.sys.validationRequired ? (
          <div className="inspection-item-details">
            <Typography variant={Variant.subtitle2}>
              {t('inspection.status')}
            </Typography>
            <Typography
              className="inspection-item-secondary-text"
              variant={Variant.body2}
            >
              <IonIcon
                icon={isValidated ? checkmarkCircleOutline : hourglassOutline}
                color={isValidated ? 'success' : undefined}
              />
              {isValidated
                ? t('inspection.status.validated')
                : t('inspection.status.pending')}
            </Typography>
          </div>
        ) : null}
      </IonLabel>
    </IonItem>
  )
}

export default InspectionItem

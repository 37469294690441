import useAuthenticatedQuery from 'hooks/useAuthenticatedQuery'

import { Bounds } from 'core/Geolocation'
import { Filters } from 'core/ContentFieldFilter'

import { viewAssets } from 'services/assets'

const useAssetView = (
  modelId: string,
  query: {
    bounds?: Bounds
    filters?: Filters
  },
  options = {},
) => {
  const { bounds, filters } = query

  return useAuthenticatedQuery(
    ['assetView', { modelId, bounds, filters }],
    (
      _key,
      _vars: {
        modelId: string
        bounds: Bounds | undefined
        filters: Filters | undefined
      },
    ) => {
      return viewAssets(modelId, bounds, filters)
    },
    options,
  )
}

export default useAssetView

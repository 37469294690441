import React from 'react'
import SavedAssetCard from 'ui/components/cards/SavedAssetCard'
import { Submission, SubmissionType, SubmissionStatus } from 'core/Submission'
import useInventorySubmission from './hooks/useInventorySubmission'
import ItemSkeleton from 'ui/components/loaders/ItemSkeleton'
import useInspectionSubmission from './hooks/useInspectionSubmission'
import { ModelTypes } from 'core/ContentModel'
import formatDate from 'common/formatDate'
import normalizeString from 'common/normalizeString'

import useTranslation from 'hooks/useTranslation'
import useAssetLocalSubmissions from 'hooks/assets/useAssetLocalSubmissions'

interface ExportItemProps {
  submission: Submission
  isEditionMode?: boolean
  isSelected?: boolean
  onSelect: () => void
  updatedAt?: string
  searchQuery?: string
  hasUnsubmittedAsset?: boolean
  hasDraftAsset?: boolean
}

interface ExportItemPropsExtended extends ExportItemProps {
  isUploaded: boolean
}

export const ExportInventoryItem: React.FC<ExportItemPropsExtended> = (
  props,
) => {
  const {
    submission,
    isEditionMode,
    isSelected,
    onSelect,
    updatedAt = '',
    searchQuery,
    isUploaded,
  } = props

  const t = useTranslation()

  const { modelTitle, title, isLoading } = useInventorySubmission(submission)

  const relatedSubmissions = useAssetLocalSubmissions(
    submission.type === SubmissionType.AssetRevision
      ? submission.sourceId!
      : submission.id, // SubmissionType.NewAsset
  )

  if (isLoading) return <ItemSkeleton />

  // if this card does not match the query, hide it
  if (searchQuery && title && !normalizeString(title).includes(searchQuery))
    return null

  return (
    <SavedAssetCard
      title={title || ''}
      info={
        isUploaded && relatedSubmissions.length > 0
          ? t('export.exportedAsset.tooltip')
          : undefined
      }
      assetType={modelTitle}
      assetDetails={updatedAt}
      onSelect={onSelect}
      isEditionMode={isEditionMode}
      isSelected={isSelected}
      isUploaded={isUploaded}
      hasLocation={submission.location != null}
      assetId={submission.sourceId || submission.id}
      status={submission.status}
    />
  )
}

export const ExportInspectionItem: React.FC<ExportItemPropsExtended> = (
  props,
) => {
  const {
    submission,
    isEditionMode,
    isSelected,
    onSelect,
    updatedAt = '',
    searchQuery,
    isUploaded,
    hasUnsubmittedAsset,
    hasDraftAsset,
  } = props
  const { modelTitle, title, isLoading } = useInspectionSubmission(submission)

  if (isLoading) return <ItemSkeleton />

  // if this card does not match the query, hide it
  if (searchQuery && title && !normalizeString(title).includes(searchQuery))
    return null

  return (
    <SavedAssetCard
      title={title || ''}
      assetType={modelTitle}
      assetDetails={updatedAt}
      onSelect={onSelect}
      isEditionMode={isEditionMode}
      isSelected={isSelected}
      isUploaded={isUploaded}
      hasUnsubmittedAsset={hasUnsubmittedAsset}
      hasDraftAsset={hasDraftAsset}
      assetId={submission.sourceId || submission.id}
      status={submission.status}
    />
  )
}

export default function ExportItem(props: ExportItemProps) {
  const updatedAt = props.submission.lastUpdatedAt
    ? formatDate(props.submission.lastUpdatedAt)
    : '-'

  const isUploaded = props.submission.status === SubmissionStatus.uploaded

  switch (props.submission.modelType) {
    case ModelTypes.asset:
      return (
        <ExportInventoryItem
          {...props}
          updatedAt={updatedAt}
          isUploaded={isUploaded}
        />
      )
    case ModelTypes.inspection:
    case ModelTypes.intervention:
      return (
        <ExportInspectionItem
          {...props}
          updatedAt={updatedAt}
          isUploaded={isUploaded}
        />
      )
    default: {
      console.warn('Unknown submission model type', props.submission.modelType)
      return null
    }
  }
}

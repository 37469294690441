import React, { useState, useCallback } from 'react'
import {
  IonItemGroup,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
  IonIcon,
  IonSpinner,
  IonButton,
  IonToggle,
} from '@ionic/react'
import useTranslation from '../../../hooks/useTranslation'
import useConnectionStatus from 'hooks/useConnectionStatus'
import ConfirmationAlert from 'ui/components/alerts/ConfirmationAlert'
import AppLayout from 'ui/layouts/AppLayout'
import GpsInput from './GpsInput'
import LanguageSelector, { LanguageSelectorProps } from './LanguageSelector'
import { sync, chevronDown, personCircleOutline, pulse } from 'ionicons/icons'
import './Settings.css'
import formatDate from 'common/formatDate'
import AppLogo from 'ui/components/images/AppLogo'
import AppVersion from 'ui/components/appVersion/AppVersion'
import DeviceFeatures from './DeviceFeatures'
import PersistentStorageAlert from './PersistentStorageAlert'

interface SettingsProps {
  username: string | null
  languageOptions: LanguageSelectorProps['options']
  selectedLanguage: string
  selectedLanguageLabel: string
  lastConfiguration: number | null
  gpsTimeout: number
  autoDownloadImages: boolean
  zipWithImages: boolean
  isFetchingConfig: boolean
  onFetchConfig: () => void
  onLogout: () => void
  onSelectLanguage: (value: string) => void
  onAcceptTimeout: (value: number | undefined) => void
  onToggleAutoDownloadImages: (value: boolean) => void
  onToggleZipWithImages: (value: boolean) => void
}

const Settings: React.FC<SettingsProps> = (props) => {
  const {
    username,
    languageOptions,
    selectedLanguage,
    selectedLanguageLabel,
    lastConfiguration,
    gpsTimeout = 5000,
    autoDownloadImages,
    zipWithImages,
    isFetchingConfig,
    onFetchConfig,
    onLogout,
    onSelectLanguage,
    onAcceptTimeout,
    onToggleAutoDownloadImages,
    onToggleZipWithImages,
  } = props

  const t = useTranslation()

  const isOnline = useConnectionStatus()

  const [onShowGpsInput, setShowGpsInput] = useState(false)
  const [onShowLanguageSelector, setShowLanguageSelector] = useState(false)
  const [showCompatTest, setShowCompatTest] = useState(false)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false)

  const handleSelectLanguage = useCallback(
    (locale: string) => {
      setShowLanguageSelector(false)
      onSelectLanguage(locale)
    },
    [onSelectLanguage],
  )

  const handleCancelLanguageSelection = useCallback(() => {
    setShowLanguageSelector(false)
  }, [])
  const handleAddLanguageSelection = useCallback(() => {
    setShowLanguageSelector(false)
  }, [])
  const handleGPSAccept = useCallback(
    (newTimeout?: number) => {
      onAcceptTimeout(newTimeout)
      setShowGpsInput(false)
    },
    [onAcceptTimeout],
  )

  const location = window.location

  return (
    <AppLayout
      hasBackground
      hideBackLink
      pageTitle={t('menu.settings')}
      showOffline
    >
      <IonItemGroup>
        <div className="settings-user-block">
          <div className="settings-logo">
            <AppLogo />
          </div>
          <IonItem>
            <IonLabel
              style={{
                fontSize: '90%',
                textAlign: 'center',
                background: 'inherit',
              }}
            >
              {`${location.protocol}//${location.hostname}`}
            </IonLabel>
          </IonItem>
          <IonItem style={{ backgroundColor: 'inherit' }}>
            <IonIcon icon={personCircleOutline} color="primary" size="large" />
            <IonText className="settings-username">{username || ''}</IonText>
          </IonItem>
        </div>
      </IonItemGroup>
      <IonItemGroup className="highlighted-item-group">
        <IonItem
          button
          detail={false}
          onClick={() => setShowLanguageSelector(true)}
        >
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{t('settings.language')}</h2>
            </IonText>
          </IonLabel>
          <IonNote slot="end" color="medium" className="asset-nav-item-note">
            {selectedLanguageLabel}
          </IonNote>
          <IonIcon icon={chevronDown} color="medium" slot="end" />
        </IonItem>
        <IonItem button detail={false} onClick={() => setShowGpsInput(true)}>
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{t('settings.gps')}</h2>
            </IonText>
          </IonLabel>
          <IonNote slot="end" color="medium" className="asset-nav-item-note">
            {gpsTimeout / 1000} sg
          </IonNote>
          <IonIcon icon={chevronDown} color="medium" slot="end" />
        </IonItem>
        <IonItem
          button
          detail={false}
          onClick={() => onToggleAutoDownloadImages(!autoDownloadImages)}
        >
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{t('settings.autoDownloadImages')}</h2>
            </IonText>
          </IonLabel>
          <IonToggle checked={autoDownloadImages} />
        </IonItem>
        <IonItem
          button
          detail={false}
          onClick={() => onToggleZipWithImages(!zipWithImages)}
        >
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{t('settings.zipWithImages')}</h2>
            </IonText>
          </IonLabel>
          <IonToggle checked={zipWithImages} />
        </IonItem>
        <IonItem
          detail={false}
          button
          onClick={onFetchConfig}
          disabled={!isOnline || isFetchingConfig}
        >
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{t('settings.refresh.configuration')}</h2>
            </IonText>
            {lastConfiguration && (
              <p className="asset-nav-item-details">
                {t('settings.date.configuration', {
                  date: formatDate(lastConfiguration),
                })}
              </p>
            )}
          </IonLabel>
          {isFetchingConfig ? (
            <IonSpinner color="primary" />
          ) : (
            <IonIcon icon={sync} slot="end" color="primary" />
          )}
        </IonItem>
        <IonItem
          lines="none"
          detail
          routerLink="/settings/help"
          disabled={!isOnline}
        >
          {t('settings.help')}
        </IonItem>
      </IonItemGroup>
      <IonItemGroup className="ion-padding-horizontal settings-logout-button">
        <IonButton
          expand="block"
          onClick={() => setShowLogoutConfirmation(true)}
          fill="outline"
          color="danger"
        >
          <IonLabel color="danger" className="ion-text-center">
            {t('logout')}
          </IonLabel>
        </IonButton>
      </IonItemGroup>
      <IonItemGroup className="ion-padding-horizontal">
        <IonButton expand="block" onClick={() => setShowCompatTest(true)}>
          <IonIcon slot="start" icon={pulse} />
          <IonLabel>{t('settings.checkDeviceCompatibility')}</IonLabel>
        </IonButton>

        <AppVersion />
      </IonItemGroup>
      {onShowGpsInput ? (
        <GpsInput
          gpsTimeout={gpsTimeout / 1000}
          isOpen={onShowGpsInput}
          onDidDismiss={() => setShowGpsInput(false)}
          onAccept={handleGPSAccept}
        />
      ) : null}
      {onShowLanguageSelector ? (
        <LanguageSelector
          options={languageOptions}
          value={selectedLanguage}
          isOpen={onShowLanguageSelector}
          onDidDismiss={handleCancelLanguageSelection}
          onCancel={handleCancelLanguageSelection}
          onAccept={handleAddLanguageSelection}
          onSelect={handleSelectLanguage}
        />
      ) : null}
      {showCompatTest && (
        <DeviceFeatures onClose={() => setShowCompatTest(false)} />
      )}
      <PersistentStorageAlert />
      <ConfirmationAlert
        isOpen={showLogoutConfirmation}
        title={t('logout')}
        description={t('confirmation.logout')}
        acceptText={t('buttons.ok')}
        cancelText={t('buttons.cancelSelection')}
        onAccept={onLogout}
        onDidDismiss={() => setShowLogoutConfirmation(false)}
      />
    </AppLayout>
  )
}

export default Settings

import React, { useRef, useEffect } from 'react'
import {
  IonCard,
  IonLabel,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
  IonSpinner,
  IonPopover,
} from '@ionic/react'
import {
  calendarOutline,
  cloudDownloadOutline,
  downloadOutline,
  refreshOutline,
} from 'ionicons/icons'

import DownloadAssetMenu from 'ui/components/menus/DownloadAssetMenu'

import formatDate from 'common/formatDate'

import './AssetCard.css'

export interface AssetData {
  id: string
  title: string
  modifiedAt?: Date
}

export interface ExtendedAssetData extends AssetData {
  isSelected?: boolean
  isLoading?: boolean
  isDownloaded?: boolean
}

// NOTE `assetType` is always provided by the asset card list component, so we
// do not need to include it in `AssetData` interface
interface AssetCardProps extends ExtendedAssetData {
  assetType: string
  onDownloadAsset: (
    assetId: string,
    label: string,
    inspectionId?: string,
  ) => void
}

const AssetCard: React.FC<AssetCardProps> = ({
  id,
  title,
  assetType,
  modifiedAt,
  isSelected,
  isLoading,
  isDownloaded,
  onDownloadAsset,
}) => {
  const ref = useRef<HTMLIonCardElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      })
    }
  }, [isSelected])

  return (
    <IonCard ref={ref}>
      <IonItem
        className="asset-card-item"
        color={isSelected ? 'light' : undefined}
        lines="none"
      >
        <IonLabel className="ion-text-wrap">
          <IonText>
            <h2>{title}</h2>
          </IonText>
          <p>{assetType}</p>
          <p className="asset-card-item-details">
            <IonIcon
              icon={isDownloaded ? downloadOutline : calendarOutline}
              style={{ verticalAlign: 'text-top' }}
            />{' '}
            <span>{modifiedAt ? formatDate(modifiedAt) : null}</span>
          </p>
        </IonLabel>
        {isDownloaded ? (
          <IonButton
            fill="clear"
            slot="end"
            className="asset-card-download-button"
            disabled={isLoading}
            id={`${id}-download-trigger`}
          >
            {isLoading ? (
              <IonSpinner color="disabled" />
            ) : (
              <IonIcon
                slot="icon-only"
                color="primary"
                icon={refreshOutline}
                size="large"
              />
            )}
          </IonButton>
        ) : (
          <IonButton
            id={`${id}-download-trigger`}
            fill="clear"
            slot="end"
            className="asset-card-download-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <IonSpinner color="disabled" />
            ) : (
              <IonIcon
                slot="icon-only"
                color="primary"
                size="large"
                icon={cloudDownloadOutline}
              />
            )}
          </IonButton>
        )}
        <IonPopover
          className="asset-card-popover"
          trigger={`${id}-download-trigger`}
          triggerAction="click"
        >
          <DownloadAssetMenu
            id={id}
            onDownloadAsset={() => onDownloadAsset(id, title)}
            onDownloadAssetWithInspection={(inspectionId) =>
              onDownloadAsset(id, title, inspectionId)
            }
          />
        </IonPopover>
      </IonItem>
    </IonCard>
  )
}

export default AssetCard

import React, { useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import AppLayout from 'ui/layouts/AppLayout'
import MapWrapper, {
  ExtendedMarkerOptions,
} from 'ui/components/maps/GoogleMaps'
import LoadingOverlay from 'ui/components/loaders/LoadingOverlay'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'

import {
  ContentModel,
  extractTitle,
  getTitle,
  getColor,
} from 'core/ContentModel'
import { FullAsset } from 'core/Assets'
import { Submission } from 'core/Submission'

import Filter from './Filter'

import useModelFilter from './useModelFilter'
import useAssetsByModel from './useAssetsByModel'

import './AssetMap.css'

const getMarkerForRemoteAsset = (model: ContentModel, locale: string) => {
  // NOTE precalculate color for all assets of this model
  const color = getColor(model)

  return (asset: FullAsset): ExtendedMarkerOptions => {
    const [lng, lat] = asset.location.coordinates
    return {
      color,
      id: asset.id,
      position: { lat, lng },
      title: extractTitle(model, asset) ?? getTitle(locale, model),
    }
  }
}

const getMarkerForLocalAsset = (model: ContentModel, locale: string) => {
  // NOTE precalculate color for all assets of this model
  const color = getColor(model)

  return (asset: Submission): ExtendedMarkerOptions => {
    // NOTE this expects the asset to ALWAYS have a location (see usage below)
    const { latitude, longitude } = asset.location!

    return {
      color,
      id: asset.id,
      position: { lat: latitude, lng: longitude },
      title: extractTitle(model, asset) ?? getTitle(locale, model),
    }
  }
}

const AssetMap = () => {
  const { replace } = useHistory()

  const locale = useCurrentLocale()
  const t = useTranslation()

  const [loading, setLoading] = useState(true)

  const { selectedModels } = useModelFilter()

  const { remoteAssetsByModel, localAssetsByModel } = useAssetsByModel()

  const markers = useMemo(
    () =>
      selectedModels.reduce((acc, model) => {
        return (
          acc
            .concat(
              remoteAssetsByModel[model.id]?.map(
                getMarkerForRemoteAsset(model, locale),
              ),
            )
            .concat(
              localAssetsByModel[model.id]
                // NOTE make sure assets have location
                ?.filter((asset) => Boolean(asset.location))
                .map(getMarkerForLocalAsset(model, locale)),
            )
            // NOTE make sure we do not add `undefined` to the array if there are no
            // assets (remote or local) for the given model
            .filter(Boolean)
        )
      }, [] as ExtendedMarkerOptions[]),
    [locale, selectedModels, remoteAssetsByModel, localAssetsByModel],
  )

  const handleSelectAsset = useCallback(
    (assetId: string) => {
      replace('/take-data/in-progress', { assetId })
    },
    [replace],
  )

  return (
    <AppLayout
      pageTitle={t('takeData.map')}
      onBackClick={() => replace('/take-data/in-progress')}
      showOffline
    >
      {loading ? <LoadingOverlay text={t('takeData.map.loading')} /> : null}
      <MapWrapper
        onLoad={() => setLoading(false)}
        markers={markers}
        onInfoWindowClick={handleSelectAsset}
      />
      <div className="asset-map-filter">
        <Filter />
      </div>
    </AppLayout>
  )
}

export default AssetMap

import { useMemo } from 'react'
import { useCurrentLocale } from 'context/LocaleContext'
import useModel from 'hooks/useModel'
import { extractTitle } from 'core/ContentModel'
import { Submission } from 'core/Submission'
import { getLocalizedProperty } from 'core/common'
import useSubmissionSource from 'hooks/submission/useSubmissionSource'

export default function useInspectionSubmission(submission: Submission) {
  const locale = useCurrentLocale()

  const {
    data: inspectionModel,
    isLoading: isLoadingInspectionModel,
    isError: isErrorInspectionModel,
  } = useModel(submission.modelId)

  const {
    data: asset,
    isLoading: isLoadingAsset,
    isError: isErrorAsset,
  } = useSubmissionSource({
    id: submission.sourceId!,
    sourceType: submission.sourceType!,
  })

  const {
    data: assetModel,
    isLoading: isLoadingAssetModel,
    isError: isErrorAssetModel,
  } = useModel(submission.sourceModelId || asset?.assetType)

  const assetTitle = useMemo(
    () => extractTitle(assetModel, asset),
    [assetModel, asset],
  )

  const modelTitle = useMemo(
    () =>
      inspectionModel
        ? getLocalizedProperty(locale, 'label', inspectionModel)
        : '',
    [inspectionModel, locale],
  )

  return {
    model: inspectionModel,
    title: assetTitle,
    modelTitle,
    isLoading:
      isLoadingInspectionModel || isLoadingAsset || isLoadingAssetModel,
    isError: isErrorAsset || isErrorAssetModel || isErrorInspectionModel,
  }
}

import React from 'react'
import { IonItem, IonIcon, IonText, IonLabel } from '@ionic/react'
import { telescopeOutline } from 'ionicons/icons'
import './EmptyItem.css'

interface EmptyItemProps {
  text?: string
  secondaryText?: string
  icon?: string
}

export const EmptyItem: React.FC<EmptyItemProps> = ({
  text = 'Sin datos',
  secondaryText = '',
  icon = telescopeOutline,
}) => {
  return (
    <IonItem lines="none" className="empty-item">
      <IonLabel className="ion-text-wrap label">
        <IonIcon size="large" className="icon" icon={icon} />
        <IonText className="typography-subtitle1 text">{text}</IonText>
        {secondaryText && (
          <IonText className="typography-subtitle2 secondary-text">
            {secondaryText}
          </IonText>
        )}
      </IonLabel>
    </IonItem>
  )
}

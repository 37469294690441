import { GeoLocation } from 'core/Geolocation'
import {
  Submission,
  SubmissionFile,
  SubmissionSourceType,
  SubmissionType,
} from 'core/Submission'
import { ChildSubmission, ChildSubmissions, SubmissionData } from './reducer'

export enum ActionTypes {
  RESET = 'reset',
  ADD_CHILD = 'add_child',
  REMOVE_CHILD = 'remove_child',
  DUPLICATE_CHILD = 'duplicate_child',
  GO_TO_CHILD = 'go_to_child',
  GO_TO_LINK = 'go_to_link',
  GO_BACK = 'go_back',
  GO_TO_ROOT = 'go_to_root',
  UPDATE_FORM_DATA = 'update_form_data',
  SUBMISSION_SOURCE_SET = 'submission_source_set',
  SET_INITIAL_DATA = 'set_initial_data',
  ADD_FILE = 'add_file',
  REMOVE_FILE = 'remove_file',
  REPLACE_FILE = 'replace_file',
  SET_LOCATION = 'set_location',
  SET_GROUP = 'set_group',
  INIT = 'init',
  MARK_UNTOUCHED = 'mark_untouched',
  REPLACE_LOCATION = 'replace_location',
  DISCARD_NEW_LOCATION = 'discard_new_location',
  SET_ERROR = 'set_error',
  CLEAN_ERROR = 'clean_error',
}

type AddChildAction = {
  type: ActionTypes.ADD_CHILD
  payload: {
    linkModelId: string
    linkName: string
    id?: string
  }
}
export function addChild(
  linkName: string,
  linkModelId: string,
): AddChildAction {
  return {
    type: ActionTypes.ADD_CHILD,
    payload: { linkName, linkModelId },
  }
}

type RemoveChildAction = {
  type: ActionTypes.REMOVE_CHILD
  payload: {
    childId: string
  }
}
export function removeChild(childId: string): RemoveChildAction {
  return {
    type: ActionTypes.REMOVE_CHILD,
    payload: { childId },
  }
}

type DuplicateChildAction = {
  type: ActionTypes.DUPLICATE_CHILD
  payload: {
    newId: string
    ancestors: string[]
    parentField: string
    newChildren: ChildSubmission[]
  }
}
export function duplicateChild(
  newId: string,
  ancestors: string[],
  parentField: string,
  newChildren: ChildSubmission[],
): DuplicateChildAction {
  return {
    type: ActionTypes.DUPLICATE_CHILD,
    payload: { newId, ancestors, parentField, newChildren },
  }
}

type GoToChildAction = {
  type: ActionTypes.GO_TO_CHILD
  payload: {
    modelId: string
    childId: string
    showIndex: boolean
    index?: number | null
  }
}
export function goToChild(
  modelId: string,
  childId: string,
  showIndex = false as boolean,
  index?: number | null,
): GoToChildAction {
  return {
    type: ActionTypes.GO_TO_CHILD,
    payload: { modelId, childId, showIndex, index },
  }
}

type GoToLinkAction = {
  type: ActionTypes.GO_TO_LINK
  payload: {
    linkName: string
  }
}
export function goToLink(linkName: string): GoToLinkAction {
  return {
    type: ActionTypes.GO_TO_LINK,
    payload: { linkName },
  }
}

type GoBackAction = {
  type: ActionTypes.GO_BACK
}
export function goBack(): GoBackAction {
  return { type: ActionTypes.GO_BACK }
}

type GoToRootAction = {
  type: ActionTypes.GO_TO_ROOT
}
export function goToRoot(): GoToRootAction {
  return { type: ActionTypes.GO_TO_ROOT }
}

type UpdateFormDataAction = {
  type: ActionTypes.UPDATE_FORM_DATA
  payload: {
    fieldName: string
    value: any
  }
}
export function setFieldData(
  fieldName: string,
  value: any,
): UpdateFormDataAction {
  return {
    type: ActionTypes.UPDATE_FORM_DATA,
    payload: { fieldName, value },
  }
}

type SetInitialDataAction = {
  type: ActionTypes.SET_INITIAL_DATA
  payload: {
    id: string
    type: SubmissionType
    sourceId?: string
    sourceModelId?: string
    sourceType?: SubmissionSourceType
    inventoryId?: string
    formData: SubmissionData
    formChildren: ChildSubmissions
    location?: GeoLocation
    files?: SubmissionFile[]
  }
}
export function setInitialData(submission: Submission): SetInitialDataAction {
  return {
    type: ActionTypes.SET_INITIAL_DATA,
    payload: {
      id: submission.id,
      type: submission.type,
      sourceId: submission.sourceId,
      sourceModelId: submission.sourceModelId,
      sourceType: submission.sourceType,
      inventoryId: submission.inventoryId,
      formData: submission.data,
      formChildren: submission.children,
      location: submission.location,
      files: submission.files,
    },
  }
}

type AddFileAction = {
  type: ActionTypes.ADD_FILE
  payload: {
    fieldName: string
    file: File
  }
}
export function addFile(fieldName: string, file: File): AddFileAction {
  return {
    type: ActionTypes.ADD_FILE,
    payload: {
      fieldName,
      file,
    },
  }
}

type RemoveFileAction = {
  type: ActionTypes.REMOVE_FILE
  payload: {
    fieldName: string
    fileId: string
  }
}
export function removeFile(
  fieldName: string,
  fileId: string,
): RemoveFileAction {
  return {
    type: ActionTypes.REMOVE_FILE,
    payload: {
      fieldName,
      fileId,
    },
  }
}

type ReplaceFileAction = {
  type: ActionTypes.REPLACE_FILE
  payload: {
    fileId: string
    fieldName: string
    file: File
  }
}
export const replaceFile = (
  fileId: string,
  fieldName: string,
  file: File,
): ReplaceFileAction => ({
  type: ActionTypes.REPLACE_FILE,
  payload: {
    fileId,
    file,
    fieldName,
  },
})

type SetLocationAction = {
  type: ActionTypes.SET_LOCATION
  payload: {
    location: GeoLocation
  }
}
export const setLocation = (location: GeoLocation): SetLocationAction => ({
  type: ActionTypes.SET_LOCATION,
  payload: { location },
})

type SetGroupAction = {
  type: ActionTypes.SET_GROUP
  payload: {
    groupName: string
  }
}
export const zoomToGroup = (groupName: string): SetGroupAction => ({
  type: ActionTypes.SET_GROUP,
  payload: { groupName },
})

type InitAction = {
  type: ActionTypes.INIT
  payload: {
    submissionId: string
    modelId: string
  }
}
export const init = (submissionId: string, modelId: string): InitAction => ({
  type: ActionTypes.INIT,
  payload: { submissionId, modelId },
})

type SetSubmissionSourceAction = {
  type: ActionTypes.SUBMISSION_SOURCE_SET
  payload: {
    fieldName: string
    sourceId: string
    sourceModelId: string
  }
}
export const setSubmissionSource = (
  fieldName: string,
  sourceId: string,
  sourceModelId: string,
): SetSubmissionSourceAction => ({
  type: ActionTypes.SUBMISSION_SOURCE_SET,
  payload: {
    fieldName,
    sourceId,
    sourceModelId,
  },
})

type MarkAsUntouchedAction = {
  type: ActionTypes.MARK_UNTOUCHED
}
export const markAsUntouched = (): MarkAsUntouchedAction => ({
  type: ActionTypes.MARK_UNTOUCHED,
})
type SetErrorAction = {
  type: ActionTypes.SET_ERROR
  payload: {
    error: string
  }
}
export const setError = (error: string): SetErrorAction => ({
  type: ActionTypes.SET_ERROR,
  payload: { error },
})

type CleanErrorAction = {
  type: ActionTypes.CLEAN_ERROR
}
export const cleanError = (): CleanErrorAction => ({
  type: ActionTypes.CLEAN_ERROR,
})

type ReplaceLocationAction = {
  type: ActionTypes.REPLACE_LOCATION
}
export const replaceLocation = (): ReplaceLocationAction => ({
  type: ActionTypes.REPLACE_LOCATION,
})

type DiscardNewLocation = {
  type: ActionTypes.DISCARD_NEW_LOCATION
}
export const discardNewLocation = (): DiscardNewLocation => ({
  type: ActionTypes.DISCARD_NEW_LOCATION,
})
type ResetAction = {
  type: ActionTypes.RESET
}
export const reset = (): ResetAction => ({
  type: ActionTypes.RESET,
})

export type FormAction =
  | AddChildAction
  | RemoveChildAction
  | DuplicateChildAction
  | GoToChildAction
  | GoToLinkAction
  | GoToRootAction
  | GoBackAction
  | SetInitialDataAction
  | AddFileAction
  | RemoveFileAction
  | ReplaceFileAction
  | SetLocationAction
  | SetGroupAction
  | InitAction
  | UpdateFormDataAction
  | SetSubmissionSourceAction
  | MarkAsUntouchedAction
  | ReplaceLocationAction
  | DiscardNewLocation
  | ResetAction
  | SetErrorAction
  | CleanErrorAction

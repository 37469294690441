import React, { ReactNode, useState, useContext, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import useLocalConfiguration from '../hooks/useLocalConfiguration'
import esAR from '../locales/es-AR.json'
import esES from '../locales/es-ES.json'
import esMX from '../locales/es-MX.json'
import esCL from '../locales/es-CL.json'
import enGB from '../locales/en-GB.json'
import frFR from '../locales/fr-FR.json'
import glES from '../locales/gl-ES.json'
import itIT from '../locales/it-IT.json'
import cat from '../locales/cat.json'
import caVALENCIA from '../locales/ca-VALENCIA.json'
import ptBR from '../locales/pt-BR.json'

const localeFiles = {
  'es-ES': esES,
  'es-AR': esAR,
  'es-MX': esMX,
  'es-CL': esCL,
  'en-GB': enGB,
  'fr-FR': frFR,
  'gl-ES': glES,
  'it-IT': itIT,
  'pt-BR': ptBR,
  'pt-PT': ptBR,
  // synonym because webapp is using "cat" right now
  cat: cat,
  //'ca-ES': cat
  'ca-VALENCIA': caVALENCIA,
}
type Locales =
  | 'es-ES'
  | 'es-AR'
  | 'es-MX'
  | 'en-GB'
  | 'fr-FR'
  | 'gl-ES'
  | 'it-IT'
  | 'cat'
  | 'ca-VALENCIA'
  | 'pt-BR'
export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || 'es-ES'

interface ContextLocale {
  locale: Locales
  options: { id: string; value: string; label: string }[]
  changeLocale: (locale: any) => void
}

const options = Object.keys(localeFiles).map((locale, i) => ({
  id: i.toString(),
  value: locale,
  label: locale,
}))

const Context = React.createContext<ContextLocale>({
  locale: DEFAULT_LOCALE as Locales,
  options,
  changeLocale: () => {},
})

export const IntlProviderWrapper = ({ children }: { children: ReactNode }) => {
  const { config, setLocale: setConfiguration } = useLocalConfiguration()

  const [locale, setLocale] = useState<Locales>(
    (config.locale as Locales) || DEFAULT_LOCALE,
  )

  const changeLocale = (locale: any) => {
    setLocale(locale)
    setConfiguration(locale)
  }

  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.lang = locale
    }
  }, [locale])

  return (
    <Context.Provider
      value={{
        locale,
        options,
        changeLocale,
      }}
    >
      <IntlProvider locale={locale} messages={localeFiles[locale]}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export const useI18n = () => useContext(Context)

export const useCurrentLocale = () => useI18n().locale

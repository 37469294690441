import React, { useCallback } from 'react'
import { IonInput, IonTextarea } from '@ionic/react'
import FormField from '../forms/FormField'
import { FieldProps } from './common'

export interface TextInputProps extends FieldProps {
  isMultiline?: boolean
  value?: string
  onChange: (name: string, value: string) => void
}

const TextInput: React.FC<TextInputProps> = ({
  readOnly,
  label,
  name,
  hint,
  errorText,
  hasError,
  isRequired,
  isMultiline,
  onChange,
  value,
  tabIndex,
  fileId,
}) => {
  const handleChange = useCallback(
    (e: any) => {
      if (e.target.value != null && e.target.value !== value) {
        // there is strange "bug" in that
        // text inputs will fire onChange
        // more than required, even when
        // the component is not visible anymore
        onChange(name, e.target.value)
      }
    },
    [onChange, name, value],
  )
  return (
    <FormField
      label={label}
      hint={hint}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      fileId={fileId}
      readOnly={readOnly}
      classnames="input-text"
    >
      {isMultiline ? (
        <IonTextarea
          readonly={readOnly}
          name={name}
          value={value}
          rows={3}
          autoGrow={true}
          onIonChange={handleChange}
          //onIonInput={handleChange}
          tabIndex={tabIndex}
          clearOnEdit={false}
          aria-label={label}
          maxlength={5000}
          counter={true}
          //debounce={1500}
        />
      ) : (
        <IonInput
          readonly={readOnly}
          type="text"
          value={value}
          name={name}
          onIonChange={handleChange}
          tabIndex={tabIndex}
        />
      )}
    </FormField>
  )
}

export default React.memo(TextInput)

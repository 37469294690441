import { getLocalizedProperty, Translation } from './common'
import { ContentField, SketchField } from './ContentField'

export enum ModelTypes {
  asset = 'asset',
  inspection = 'inspection',
  intervention = 'intervention',
}

type Color = {
  type: 'static' | 'cases'
  primaryColor: 'string'
}

// REVIEW create different interfaces for assets and inspections
export interface ContentModel {
  id: string
  name: string
  label: string
  pluralLabel?: string
  locales: Translation[]
  fields: ContentField[]
  modelType: ModelTypes
  sys: {
    isRoot: boolean
    createFromMap: boolean
    showInMap: boolean
    hasGeometry: boolean
    published: boolean
    queryable: boolean
    editable: boolean
    usedByModels?: string[]
    treeStructure: boolean
    validationRequired?: boolean // NOTE inspection only
  }
  // REVIEW is `colorsConfig` specific to certain models (e.g. assets)
  // REVIEW can we be positive there will always be at least one color (with
  // `static` type)?
  colorsConfig?: Color[]
  // NOTE inspection only
  ability?: {
    canDuplicate: boolean
  }
  meanings?: { title: string }
  // NOTE intervention only?
  config?: {
    applicableModels: Array<string>
  }
}

export const getFieldByName = (name: string, model: ContentModel) => {
  return model.fields.find((field) => field.name === name)
}

export const getTitleField = (model?: ContentModel) => {
  const field = (model?.fields || []).find((f) => f.meta.useAsTitle === true)
  return field?.name
}

export const extractTitle = (
  model?: ContentModel,
  submission: { data: { [fieldName: string]: any } } = {
    data: {},
  },
) => {
  const titleField = getTitleField(model)
  if (!titleField) return undefined
  return submission.data[titleField] as string
}

export const getTitle = (locale: string, model: ContentModel) => {
  return getLocalizedProperty(locale, 'label', model)
}

export const getPluralTitle = (locale: string, model: ContentModel) => {
  return (
    getLocalizedProperty(locale, 'pluralLabel', model) ||
    getTitle(locale, model)
  )
}

export const getFieldsWithImage = (model: ContentModel) => {
  return model.fields.filter((f) => f.fileId != null)
}

export const getSketchsBackgrounds = (model: ContentModel) => {
  return model.fields.filter(
    (f) =>
      Boolean(
        f.type === 'sketch' && f.backgroundFileId !== undefined,
      ) as boolean,
  ) as SketchField[]
}

export const getColor = (model: ContentModel) => {
  return model.colorsConfig?.find((color) => color.type === 'static')
    ?.primaryColor
}

export const canBeCreated = (model: ContentModel) => {
  // we can create a new asset of either root models
  // or models that can be created from map in the web app
  // perhaps this should be clarified with a new `createFromInspector` property in the backend
  // in the future
  return Boolean(model.sys?.isRoot) || Boolean(model.sys?.createFromMap)
}

import React from 'react'
import cx from 'classnames'
import { IonContent } from '@ionic/react'
import './AppContent.css'

export interface AppContentProps {
  hasBackground?: boolean
  children: any
}

const AppContent: React.FC<AppContentProps> = ({ hasBackground, children }) => {
  const classes = cx('app-content', {
    'app-content-with-background': hasBackground,
  })
  return <IonContent className={classes}>{children}</IonContent>
}

export default AppContent

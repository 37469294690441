import React from 'react'
import { IonPopover } from '@ionic/react'

export interface FormProps {
  children?: any
  cssClass?: string
  isOpen: boolean
  event?: Event
  showBackdrop?: boolean
  backdropDismiss?: boolean
  onDidDismiss?: () => void
}

const Popover: React.FC<FormProps> = (props) => {
  const {
    children,
    cssClass,
    isOpen,
    event,
    showBackdrop = false,
    backdropDismiss = true,
    onDidDismiss,
  } = props
  return (
    <IonPopover
      isOpen={isOpen}
      event={event}
      showBackdrop={showBackdrop}
      backdropDismiss={backdropDismiss}
      onDidDismiss={onDidDismiss}
      {...{ cssClass }}
    >
      {children}
    </IonPopover>
  )
}

export default Popover

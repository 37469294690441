import React, { useState, useEffect, ReactNode, useCallback } from 'react'

import { removeUserFromDB, saveUserOnDB, getLoggedUser } from '../services/user'

interface IUserContext {
  username: string | null
  jwt: string | null
  fetchUser: () => void
  clearUser: () => void
  isLoaded: boolean
  saveUser: (username: string | null, jwt: string) => Promise<void>
}

// TODO: Change Function type with a type with the correct signature for the functions
const Context = React.createContext<IUserContext>({
  username: null,
  jwt: null,
  fetchUser: async () => {},
  clearUser: () => {},
  isLoaded: false,
  saveUser: () => Promise.resolve(),
})

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [username, setUsername] = useState<string | null>(null)
  const [jwt, setJWT] = useState<string | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const fetchUser = useCallback(async () => {
    const loggedUser = await getLoggedUser()
    if (loggedUser) {
      setUsername(loggedUser.username)
      setJWT(loggedUser.jwt)
    }

    setIsLoaded(true)
  }, [])

  const clearUser = useCallback(async () => {
    setJWT(null)
    setUsername(null)

    removeUserFromDB()
  }, [])

  const saveUser = useCallback(
    async (newUsername: string | null, jwt: string) => {
      await removeUserFromDB()
      await saveUserOnDB(newUsername || (username as string), jwt)
      if (newUsername) {
        setUsername(newUsername)
      }
      setJWT(jwt)
    },
    [username],
  )

  useEffect(() => {
    fetchUser().catch(console.error)
  }, [fetchUser])

  return (
    <Context.Provider
      value={{
        jwt,
        username,
        fetchUser,
        isLoaded,
        saveUser,
        clearUser,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context

import React from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { checkbox, closeOutline } from 'ionicons/icons'
import { requestPersistentStorage } from 'services/permissions'
import useTranslation from 'hooks/useTranslation'
import useDeviceCompatibility from 'hooks/useDeviceCompatibility'

type DeviceFeaturesProps = {
  onClose: () => void
}

const TestResult: React.FC<{
  label: string
  result: boolean
  onClick?: React.MouseEventHandler
}> = ({ label, result, onClick }) => (
  <IonItem onClick={onClick}>
    <IonLabel>{label}</IonLabel>
    <IonIcon
      icon={result ? checkbox : closeOutline}
      style={{
        color: result ? 'var(--ion-color-medium)' : 'var(--ion-color-danger)',
      }}
    />
  </IonItem>
)

const DeviceFeatures: React.FC<DeviceFeaturesProps> = (props) => {
  const t = useTranslation()
  const { features, isFetching, error, refetch, isCompatible, isIOS } =
    useDeviceCompatibility()

  return (
    <IonModal isOpen>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('settings.deviceCompatibility')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.onClose}>
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isFetching && !features && <IonSpinner />}
        {!isFetching && !!error && (
          <IonItem>
            <IonText>Test failed</IonText>
          </IonItem>
        )}
        {features && (
          <>
            <IonItemGroup style={{ marginBottom: '1em' }}>
              <IonItem>
                {isCompatible ? (
                  <IonText
                    style={{
                      color: 'var(--ion-color-success-shade)',
                    }}
                  >
                    <h4 style={{ fontWeight: '400' }}>
                      {t('notifications.compatibleDeviceMessage')}
                    </h4>
                  </IonText>
                ) : (
                  <IonText color="danger">
                    <h4 style={{ fontWeight: '400' }}>
                      {t('notifications.incompatibleDeviceMessage')}
                    </h4>
                  </IonText>
                )}
              </IonItem>
              <IonItemDivider>
                <IonText
                  style={{
                    fontSize: isIOS ? '100%' : '120%',
                  }}
                >
                  Required features
                </IonText>
              </IonItemDivider>
              <TestResult label="Geolocation" result={features.geolocation} />
              <TestResult
                label="Offline usage (ServiceWorker)"
                result={features.serviceWorker}
              />
              <TestResult
                label="Offline storage (IDB)"
                result={features.indexedDB}
              />
              <TestResult
                label="Offline features (Cache)"
                result={features.cacheAPI}
              />
              <TestResult
                label="Image / file handling"
                result={features.createObjectURL}
              />
              <TestResult
                label="Persistent storage"
                result={features.diskSpace.persistent}
                onClick={() =>
                  requestPersistentStorage().then((isPersisted) => refetch())
                }
              />
            </IonItemGroup>
            <IonItemGroup>
              <IonItemDivider>
                <IonText
                  style={{
                    fontSize: isIOS ? '100%' : '120%',
                  }}
                >
                  Storage
                </IonText>
              </IonItemDivider>
              <TestResult
                label="Disk space estimation"
                result={features.diskSpace.storageEstimation}
              />
              {features.diskSpace.storageEstimation && (
                <IonProgressBar
                  value={
                    (features.diskSpace.used * 100) /
                    features.diskSpace.available
                  }
                />
              )}
              <IonItem>
                <IonLabel>Available space </IonLabel>
                <IonText>{features.diskSpace.availableText}</IonText>
              </IonItem>
              <IonItem>
                <IonLabel>Used space </IonLabel>
                <IonText>{features.diskSpace.usedText}</IonText>
              </IonItem>
            </IonItemGroup>
          </>
        )}
      </IonContent>
    </IonModal>
  )
}

export default DeviceFeatures

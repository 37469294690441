import React, { useCallback } from 'react'

import ChooseAssetImportUI from '../../ui/views/import/ChooseAssetImport'
import { useAssetModels } from 'hooks/useModelList'
import { useHistory } from 'react-router'
import useTranslation from 'hooks/useTranslation'

export default function ChooseAssetImport() {
  const models = useAssetModels()
  const { push } = useHistory()
  const t = useTranslation()

  const handleImportModel = useCallback(
    (modelId: string) => {
      push('/import/assets/' + modelId)
    },
    [push],
  )

  return (
    <ChooseAssetImportUI
      title={t('import.assets')}
      assetModels={models}
      onImportModel={handleImportModel}
    />
  )
}

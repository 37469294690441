import React from 'react'
import {
  IonFooter,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
} from '@ionic/react'
import {
  chevronBack,
  chevronForward,
  saveOutline,
  trashOutline,
  addCircleOutline,
  exitOutline,
  navigateSharp,
} from 'ionicons/icons'
import './AppFooter.css'
import { identity } from 'ramda'

export interface AppFooterProps {
  hasAddLinkMenu?: boolean
  hasGroupNavigation?: boolean
  hasSaveMenu?: boolean
  hasDeleteAction?: boolean
  isFirstNavigationStep?: boolean
  isLastNavigationStep?: boolean
  isSaving?: boolean
  onExit?: () => void
  onNavigateBack?: () => void
  onNavigateForward?: () => void
  onShowAddLinkMenu?: (e: React.MouseEvent) => void
  onShowSaveMenu?: () => void
  onShowDeleteAlert?: () => void
  showGPS?: boolean
  hasLocation?: boolean
  isFetchingLocation?: boolean
  onGPSClick?: () => void
}

const AppFooter: React.FC<AppFooterProps> = ({
  hasAddLinkMenu,
  hasGroupNavigation,
  hasSaveMenu,
  hasDeleteAction,
  isFirstNavigationStep,
  isLastNavigationStep,
  isSaving,
  onExit,
  onNavigateBack,
  onNavigateForward,
  onShowAddLinkMenu,
  onShowSaveMenu,
  onShowDeleteAlert,
  showGPS = false,
  hasLocation = false,
  isFetchingLocation = false,
  onGPSClick = identity,
}) => {
  return (
    <>
      <IonFooter className="app-footer">
        <IonToolbar>
          <IonButtons slot="start">
            {hasGroupNavigation ? (
              <IonButton
                className="ion-margin-end"
                disabled={isFirstNavigationStep}
                onClick={onNavigateBack}
              >
                <IonIcon slot="icon-only" icon={chevronBack} />
              </IonButton>
            ) : null}

            {onExit && (
              <IonButton onClick={onExit}>
                <IonIcon
                  icon={exitOutline}
                  slot="icon-only"
                  className="exit-button"
                />
              </IonButton>
            )}
            {showGPS && (
              <IonButton onClick={onGPSClick}>
                <IonIcon
                  icon={navigateSharp}
                  color={
                    isFetchingLocation
                      ? 'warning'
                      : hasLocation
                        ? 'primary'
                        : 'danger'
                  }
                />
                {isFetchingLocation && <IonSpinner name="dots" />}
              </IonButton>
            )}
          </IonButtons>
          <IonButtons slot="primary">
            {hasDeleteAction ? (
              <IonButton
                expand="block"
                fill="clear"
                color="danger"
                className="ion-margin-end"
                onClick={onShowDeleteAlert}
              >
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            ) : null}
            {hasSaveMenu ? (
              <IonButton
                expand="block"
                fill="clear"
                color="primary"
                className="ion-margin-start"
                onClick={onShowSaveMenu}
              >
                <IonIcon slot="icon-only" icon={saveOutline} />
              </IonButton>
            ) : null}
            {hasAddLinkMenu ? (
              <IonButton
                expand="block"
                fill="clear"
                color="primary"
                onClick={onShowAddLinkMenu}
              >
                <IonIcon slot="icon-only" icon={addCircleOutline} />
              </IonButton>
            ) : null}
          </IonButtons>
          <IonButtons slot="end">
            {hasGroupNavigation ? (
              <IonButton
                className="ion-margin-start"
                disabled={isLastNavigationStep}
                onClick={onNavigateForward}
              >
                <IonIcon slot="icon-only" icon={chevronForward} />
              </IonButton>
            ) : null}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default AppFooter

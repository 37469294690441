import { useMemo, useCallback } from 'react'

import { GroupConfig, Group } from 'core/Layout'
import { useDispatchContext } from 'context/FormContext'
import { zoomToGroup } from 'context/FormContext/actions'

export default function useLayoutNavigation(
  groupName: string,
  groups: GroupConfig[] | Group[] = [],
  selectedLink?: string,
) {
  const dispatch = useDispatchContext()

  const navigateToGroup = useCallback(
    (newGroupName: string) => {
      dispatch(zoomToGroup(newGroupName))
    },
    [dispatch],
  )

  const getIndexByName = useCallback(
    (name: string) =>
      groups.findIndex((g: GroupConfig | Group) => g.name === name),
    [groups],
  )

  const getNameByIndex = useCallback(
    (index: number) => groups[index].name,
    [groups],
  )

  const isFirstGroup = useMemo(
    () => getIndexByName(groupName) === 0,
    [groupName, getIndexByName],
  )

  const isLastGroup = useMemo(
    () => getIndexByName(groupName) === groups.length - 1,
    [groupName, getIndexByName, groups],
  )

  const navigateToNextGroup = useCallback(
    (currentGroupName: string) => {
      const currentIndex = getIndexByName(currentGroupName)
      const groupName = getNameByIndex(currentIndex + 1)

      navigateToGroup(groupName)
    },
    [getIndexByName, getNameByIndex, navigateToGroup],
  )

  const navigateToPrevGroup = useCallback(
    (currentGroupName: string) => {
      const currentIndex = getIndexByName(currentGroupName)
      const groupName = getNameByIndex(currentIndex - 1)

      navigateToGroup(groupName)
    },
    [getIndexByName, getNameByIndex, navigateToGroup],
  )

  const footerNavigationConfig = useMemo(() => {
    if (!groupName || selectedLink) return { hasGroupNavigation: false }

    return {
      hasGroupNavigation: !!groups.length,
      isFirstNavigationStep: isFirstGroup,
      isLastNavigationStep: isLastGroup,
      onNavigateBack: !isFirstGroup
        ? () => navigateToPrevGroup(groupName)
        : undefined,
      onNavigateForward: !isLastGroup
        ? () => navigateToNextGroup(groupName)
        : undefined,
    }
  }, [
    groupName,
    selectedLink,
    groups.length,
    isFirstGroup,
    isLastGroup,
    navigateToNextGroup,
    navigateToPrevGroup,
  ])

  return {
    navigateToGroup,
    footerNavigationConfig,
  }
}

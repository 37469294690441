import { isEmpty, mergeLeft, sortBy } from 'ramda'
import throwRequired from 'common/throwRequired'
import {
  Submission,
  SubmissionFile,
  SubmissionStatus,
  submissionTypeToOrder,
} from 'core/Submission'
import {
  IExportSubmissionResponse,
  IExportSubmissionFileResponse,
  ISubmissionStatusReponse,
} from 'services/export'
import { NetworkError } from 'services/network/constants'

interface IExportDependencies {
  sendSubmission: (submission: Submission) => Promise<IExportSubmissionResponse>
  sendSubmissionFile: (
    submissionId: Submission['id'],
    submissionFile: SubmissionFile,
  ) => Promise<IExportSubmissionFileResponse>
  updateSubmissionStatus: (submission: Submission) => Promise<Submission['id']>
}

export interface IExportError {
  submissionId: string
  submissionFileIds?: string[]
  error?: Error
}

interface ISendSubmissionFilesParams {
  onFileProgress: Function
  sendSubmissionFile: IExportDependencies['sendSubmissionFile']
  submissionId: Submission['id']
  submissionFiles: SubmissionFile[]
}

export function sortSubmissionsByType(submissions: Submission[]): Submission[] {
  return sortBy((s: Submission) => {
    return submissionTypeToOrder(s.type)
  }, submissions)
}

export async function sendSubmissionFiles({
  onFileProgress,
  sendSubmissionFile,
  submissionId,
  submissionFiles,
}: ISendSubmissionFilesParams) {
  let currentFile: number = 1
  let statusAfterSendFiles = ISubmissionStatusReponse.pending
  for (let submissionFile of submissionFiles) {
    try {
      //In this moment we want to send all files and not break the loop
      const response = await sendSubmissionFile(submissionId, submissionFile)
      statusAfterSendFiles = response.submissionStatus
      submissionFile.status = 'ok'
      onFileProgress({
        totalFiles: submissionFiles.length,
        currentFile: currentFile++,
      })
    } catch (error: any) {
      console.error(
        `Error exporting file ${submissionFile.id}. `,
        error.message,
      )
      submissionFile.status = 'error'
      onFileProgress({
        totalFiles: submissionFiles.length,
        currentFile: currentFile++,
      })
      continue
    }
  }
  return {
    updatedSubmissionFiles: submissionFiles,
    statusAfterSendFiles,
  }
}

//For now, but maybe we can use it to send more information
export function makeSubmissionExportErrors(
  exportErrors: IExportError[],
  submissionId: Submission['id'],
  error: Error,
) {
  const targetErrorIndex = exportErrors.findIndex(
    (e) => e.submissionId === submissionId,
  )
  if (targetErrorIndex >= 0) {
    exportErrors[targetErrorIndex] = mergeLeft(exportErrors[targetErrorIndex], {
      submissionId,
    })
    return exportErrors
  }
  return exportErrors.concat({ submissionId, error })
}
export function makeSubmissionFilesExportErrors(
  exportErrors: IExportError[],
  submissionId: Submission['id'],
  files: SubmissionFile[],
) {
  const submissionFileIds = files.reduce((acc: string[] = [], file) => {
    if (file.status === 'error') acc.push(file.id)
    return acc
  }, [])
  if (isEmpty(submissionFileIds)) return exportErrors
  return exportErrors.concat({
    submissionId,
    submissionFileIds,
  })
}

export default function exportSubmissions({
  sendSubmission = throwRequired('IExportDependencies', 'sendSubmission'),
  sendSubmissionFile = throwRequired(
    'IExportDependencies',
    'sendSubmissionFile',
  ),
  updateSubmissionStatus = throwRequired(
    'IExportDependencies',
    'updateSubmissionStatus',
  ),
}: IExportDependencies) {
  let exportCancelled = false
  const cancel = () => (exportCancelled = true)

  const start = async (onProgress: Function, submissions: Submission[]) => {
    let exportErrors: IExportError[] = []
    let mainProgress = {
      totalSubmissions: submissions.length,
      currentSubmission: 1,
    }
    const onFileProgress = (filesProgress: object) => {
      onProgress({ ...mainProgress, ...filesProgress })
    }
    onProgress({
      ...mainProgress,
      currentSubmission: 1,
      totalFiles: submissions[0].files.length,
      currentFile: submissions[0].files.length && 1,
    })
    for (const submission of submissions) {
      if (exportCancelled) break

      try {
        const { files = [] } = submission

        const { submissionStatus: statusAfterSendSubmission } =
          await sendSubmission(submission).catch((err: any) => {
            console.log(
              'sendsubmission error',
              err,
              err.code,
              err.name,
              err.message,
            )
            if (err.status === 406) {
              return {
                ...submission,
                submissionStatus: ISubmissionStatusReponse.accepted,
              }
            }
            throw err
          })

        const { updatedSubmissionFiles, statusAfterSendFiles } =
          await sendSubmissionFiles({
            onFileProgress,
            sendSubmissionFile,
            submissionId: submission.id,
            submissionFiles: files,
          })

        exportErrors = makeSubmissionFilesExportErrors(
          exportErrors,
          submission.id,
          updatedSubmissionFiles,
        )

        if (
          statusAfterSendSubmission === ISubmissionStatusReponse.accepted ||
          statusAfterSendFiles === ISubmissionStatusReponse.accepted
        ) {
          await updateSubmissionStatus({
            ...submission,
            files: updatedSubmissionFiles,
            status: SubmissionStatus.uploaded,
          })
        }
        onProgress({
          ...mainProgress,
          currentSubmission: mainProgress.currentSubmission++,
        })
      } catch (error: any) {
        if (
          error.message.trim() === 'QuotaExceededError' ||
          error.code === 401
        ) {
          console.log('Disk full or auth error, stopping')
          throw error
        }

        if (error instanceof NetworkError) {
          throw error
        }

        console.error(`Error exporting submission ${submission.id}. `, error)
        /* if (error instanceof UnathorizedError) {
          console.log('LOGIN REQUIRED!')
          throw error
        } */

        exportErrors = makeSubmissionExportErrors(
          exportErrors,
          submission.id,
          error,
        )
        onProgress({
          ...mainProgress,
          currentSubmission: mainProgress.currentSubmission++,
        })

        break
      }
    }
    exportCancelled = false
    return exportErrors
  }
  return { cancel, start }
}

import React from 'react'
import { getLocalizedProperty } from 'core/common'
import { useCurrentLocale } from 'context/LocaleContext'
import useListItems from 'hooks/useListItems'
import { ContentField, FieldType } from 'core/ContentField'
import { propOr } from 'ramda'
import { LinkChild } from 'core/LinkChildren'
import { IonCol } from '@ionic/react'
import useTranslation from 'hooks/useTranslation'

interface TranslatedTitleFieldProps {
  fieldModel: ContentField
  child: LinkChild
}
const TranslatedTitleField: React.FC<TranslatedTitleFieldProps> = ({
  fieldModel,
  child,
}) => {
  const t = useTranslation()
  const containsListName: string = propOr('', 'listName', fieldModel)

  const fieldValue = child.data[fieldModel.name]
  const { data: items = [] } = useListItems(containsListName)
  const currentLocale = useCurrentLocale()

  let label: string
  const selectedItem = items.find((item) => item.value === fieldValue)
  switch (fieldModel.type) {
    case FieldType.Select: {
      label = selectedItem
        ? getLocalizedProperty(currentLocale, 'label', selectedItem)
        : ''
      break
    }
    case FieldType.Datetime: {
      const date = new Date(fieldValue).toLocaleDateString()
      label = fieldValue ?? null ? date : ''
      break
    }
    case FieldType.Boolean: {
      label = getLocalizedProperty(
        currentLocale,
        fieldValue ?? false ? 'trueLabel' : 'falseLabel',
        fieldModel,
      )
      if (!label) label = fieldValue ? t('true') : t('false')
      break
    }
    default: {
      label = fieldValue
      break
    }
  }

  return label ? <IonCol>{`(${label})`}</IonCol> : null
}

export default TranslatedTitleField

import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

// Force Storybook to refresh when translations file changes
// eslint-disable-next-line
import esES from '../locales/es-ES.json'
// eslint-disable-next-line
import esMX from '../locales/es-MX.json'
// eslint-disable-next-line
import enGB from '../locales/en-GB.json'
// eslint-disable-next-line
import frFR from '../locales/fr-FR.json'
// eslint-disable-next-line
import cat from '../locales/cat.json'
// eslint-disable-next-line
import ca from '../locales/ca-VALENCIA.json'

export type TranslateFunction = (key: string, params?: any) => string

const defaultFormattingParams = {
  b: (chunks: React.ReactNode[]): JSX.Element[] =>
    chunks.map((chunk: React.ReactNode, i: number) => (
      <strong key={i}>{chunk}</strong>
    )),
  br: <br key={Math.random()} />,
}

const useTranslation = () => {
  const intl = useIntl()
  const t = useCallback(
    (key: string, params: any = {}) =>
      intl.formatMessage(
        { id: key, defaultMessage: key },
        { ...defaultFormattingParams, ...params },
      ),
    [intl],
  )
  return t
}

export default useTranslation

import React from 'react'
import {
  IonItemSliding,
  IonItem,
  IonLabel,
  IonText,
  IonIcon,
  IonItemOptions,
  IonItemOption,
} from '@ionic/react'
import { addCircleOutline, trashOutline } from 'ionicons/icons'

import StatusTag from 'ui/components/tags/StatusTag'
import Typography, { Variant } from 'ui/components/typography/Typography'

import { SubmissionStatus } from 'core/Submission'

export interface ItemProps {
  title: string
  details: React.ReactNode
  status: SubmissionStatus | 'downloaded'
  onClick: () => void
  onDelete: () => void
}

// NOTE since this is always used as 2nd level item, color is forced to `light`
const Item: React.FC<ItemProps> = (props) => {
  const { title, details, status, onClick, onDelete } = props

  return (
    <IonItemSliding>
      <IonItem lines="full" color="light">
        <IonLabel className="ion-text-wrap">
          <IonText>
            <h2>{title}</h2>
          </IonText>
          <Typography color="medium" variant={Variant.caption}>
            {details}
          </Typography>
          <br />
          <StatusTag status={status} />
        </IonLabel>
        <IonIcon
          slot="end"
          icon={addCircleOutline}
          // NOTE override color, which changes to have more contrast when item
          // has `color='light'`
          style={{
            color: 'rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)',
          }}
          onClick={onClick}
        />
      </IonItem>
      <IonItemOptions
        side="end"
        style={{
          backgroundColor: `var(--ion-color-light)`,
        }}
      >
        <IonItemOption color="danger" onClick={onDelete}>
          <IonIcon size="large" icon={trashOutline} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  )
}

export default Item

import { Submission } from 'core/Submission'

interface GenerateZipParams {
  submissions: Submission[]
  includeImages: boolean
}

export default async function generateZip(params: GenerateZipParams) {
  const { submissions = [], includeImages } = params
  const zip = require('jszip')()

  for (const submission of submissions) {
    const folder = zip.folder(submission.id)
    folder.file(submission.id + '.json', JSON.stringify(submission))
    includeImages && addFiles(folder, submission.files)
  }
  return await zip.generateAsync({ type: 'blob' })
}

function addFiles(folder: any, files: Submission['files']) {
  for (const submissionFile of files) {
    if (!submissionFile || !submissionFile.file) continue
    folder.file(
      submissionFile.id + '.' + submissionFile.contentType.split('/')[1],
      submissionFile.file,
      { base64: true },
    )
  }
}

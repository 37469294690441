import React, { useCallback } from 'react'
import { FieldProps } from './common'
import { useInspectionAssetRef } from 'hooks/useInspectionAssetRef'
import { useDispatchContext } from 'context/FormContext'
import { setSubmissionSource } from 'context/FormContext/actions'
import { CascadeSelectContainer } from './CascadeSelect'
import { pathOr } from 'ramda'

interface InspectionAssetRefProps extends FieldProps {}

/**
 * @description This component should be used only in Inspection forms
 * It's used to choose a specific "child" of the inspected asset, so its
 * value is an *asset id*. In order to choose, a list of breadcrumbs of
 * each asset is presented.
 * Only child assets of a model affected by the inspection model should be displayed.
 *
 * To generate breadcrumbs, we need all different asset models present in children.
 *
 * Input: inspected asset children, current inspection model
 * 1. Fetch asset child models
 * 2. From each model we need the link fields and its "title" field
 * 3. Find all child assets that can be inspected by the inspection model
 * 4. For each asset, remove the first ancenstors (the root asset id)
 * 5. Every other ancestor is a part of the thumbnail
 * 6. Transform every ancestor into a
 *
 */

const InspectionAssetReference: React.FC<InspectionAssetRefProps> = (props) => {
  const { items, ancestorsById } = useInspectionAssetRef()
  const dispatch = useDispatchContext()

  const handleChange = useCallback(
    (fieldName: string, sourceId: string) => {
      const opt = items.find((o) => o.value === sourceId)
      dispatch(setSubmissionSource(fieldName, sourceId, opt?.modelId || ''))
    },
    [dispatch, items],
  )

  return (
    <CascadeSelectContainer
      items={items}
      value={props.value}
      fieldProps={{
        readOnly: props.readOnly,
        name: props.name,
        label: props.label,
      }}
      getNodeLabel={(id) => pathOr('', [id, 'label'], ancestorsById)}
      onChange={(i) => handleChange(props.name, i.value)}
    />
    /* <Select
      onChange={handleChange}
      label={props.label}
      name={props.name}
      isMultiple={false}
      value={props.value}
      options={items}
    ></Select> */
  )
}

export default InspectionAssetReference

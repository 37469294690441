import React from 'react'
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonBadge,
  IonItemDivider,
  isPlatform,
} from '@ionic/react'
import {
  shareSocial,
  folderOpen,
  chevronDown,
  briefcase,
  chevronForward,
} from 'ionicons/icons'
import './TreeNavigationItem.css'

export enum TreeItemTypes {
  childLink = 'childLink',
  root = 'root',
  parentLink = 'parentLink',
  parent = 'parent',
  form = 'form',
}

export interface TreeNavigationItemProps {
  title: string
  type: TreeItemTypes
  childrenNumber?: string
  onClick: () => void
}
const getIcon = (type: TreeItemTypes) => {
  switch (type) {
    case TreeItemTypes.form:
      return chevronForward
    case TreeItemTypes.root:
      return briefcase
    case TreeItemTypes.parent:
      return chevronDown
    case TreeItemTypes.childLink:
      return shareSocial
    case TreeItemTypes.parentLink:
      return folderOpen
  }
}

const TreeNavigationItem: React.FC<TreeNavigationItemProps> = ({
  title,
  type,
  childrenNumber,
  onClick,
}) => {
  const isiOS = isPlatform('ios')
  if (type === TreeItemTypes.form)
    return (
      <IonItemDivider color="primary">
        <IonIcon
          className="tree-nav-form-icon"
          icon={getIcon(type)}
          slot="start"
          size="small"
        />
        <IonLabel style={{ fontSize: isiOS ? '100%' : '120%' }}>
          {title}
        </IonLabel>
      </IonItemDivider>
    )
  return (
    <IonItem button detail={false} onClick={onClick}>
      <IonIcon
        className={
          type === 'childLink' ? 'tree-nav-link-icon' : 'tree-nav-form-icon'
        }
        icon={getIcon(type)}
        slot="start"
        size="small"
      />
      <IonLabel
        className={
          type === 'childLink'
            ? 'ion-text-wrap tree-root-nav-text'
            : 'ion-text-wrap'
        }
      >
        {title}
      </IonLabel>
      {childrenNumber ? (
        <IonBadge slot="end" color="primary">
          {childrenNumber}
        </IonBadge>
      ) : null}
    </IonItem>
  )
}

export default TreeNavigationItem

import { useLiveQuery } from 'dexie-react-hooks'
import { useQuery } from 'react-query'

import {
  getAssetModels,
  getInspectionModelsForAssetModelId,
} from 'services/models'

import useToast from './useToast'

export const useAssetModels = () => {
  return useLiveQuery(getAssetModels, [], [])
}

// REVIEW turn into live query? move to somewhere else?
export function useInspectionModels(assetModelId?: string) {
  const Toast = useToast()
  return useQuery(
    ['inspectionModels', assetModelId],
    () => getInspectionModelsForAssetModelId(assetModelId!),
    {
      enabled: Boolean(assetModelId),
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast.error('Ocurrió un error al obtener las inspecciones')
      },
    },
  )
}

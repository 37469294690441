import { ContentField, FieldType } from 'core/ContentField'
import { Filters, RangeFilter } from 'core/ContentFieldFilter'

import { FilterValues, RangeFilterValue, BooleanFilterValue } from './index.d'

const prepareQuery = (
  fields: Array<ContentField>,
  data: FilterValues,
): Filters => {
  const query = fields.reduce((acc: Filters, field) => {
    let fieldData
    switch (field.type) {
      case FieldType.Boolean:
        fieldData = data[field.name] as BooleanFilterValue<boolean>
        if (
          fieldData.true === true ||
          fieldData.false === true ||
          fieldData.null === true
        ) {
          const filter = []

          if (fieldData.true === true) filter.push(true)
          if (fieldData.false === true) filter.push(false)
          if (fieldData.null === true) filter.push(null)

          acc[field.name] = { type: field.type, filter }
        }
        break
      case FieldType.Text:
        fieldData = data[field.name] as string
        if (fieldData) {
          acc[field.name] = {
            type: field.type,
            filter: fieldData,
          }
        }
        break
      case FieldType.Numeric:
        fieldData = data[field.name] as RangeFilterValue<string>
        if (fieldData.gte || fieldData.lte) {
          const filter = {} as RangeFilter<number>

          if (fieldData.gte) filter.$gte = Number(fieldData.gte)
          if (fieldData.lte) filter.$lte = Number(fieldData.lte)

          acc[field.name] = { type: field.type, filter }
        }
        break
      case FieldType.Select:
        fieldData = data[field.name] as string[]
        if (fieldData?.length > 0) {
          acc[field.name] = {
            type: field.type,
            filter: fieldData,
          }
        }
        break
      case FieldType.Datetime:
        fieldData = data[field.name] as RangeFilterValue<string>
        if (fieldData.gte || fieldData.lte) {
          // TODO set time to 00:00:00 or 23:59:59
          const filter = {} as RangeFilter<string>

          if (fieldData.gte) filter.$gte = fieldData.gte as string
          if (fieldData.lte) filter.$lte = fieldData.lte as string

          acc[field.name] = { type: field.type, filter }
        }
        break
      default:
        break
    }
    return acc
  }, {})

  return query
}

export default prepareQuery

import React, { useEffect, useRef } from 'react'
import { fetchPrecipitationMap, LayerTiles } from 'services/weatherData'

const PrecipitationLayer: React.FC<{
  map: google.maps.Map | null
  active: boolean
}> = ({ map, active }) => {
  const tileLayerRef = useRef<google.maps.ImageMapType | null>(null)

  useEffect(() => {
    if (map && active) {
      const tileLayer = new google.maps.ImageMapType({
        getTileUrl: (coord, z) => {
          return fetchPrecipitationMap({
            x: coord.x,
            y: coord.y,
            z,
          })
        },
        tileSize: new google.maps.Size(256, 256),
        opacity: 1,
        name: LayerTiles.Precipitation,
      })
      tileLayerRef.current = tileLayer
      map.overlayMapTypes.push(tileLayer)
    } else if (map && tileLayerRef.current) {
      const index = map?.overlayMapTypes
        .getArray()
        .indexOf(tileLayerRef.current)
      if (index !== -1) {
        map.overlayMapTypes.removeAt(index)
      }
    }
    return () => {
      if (map && tileLayerRef.current) {
        const index = map?.overlayMapTypes
          .getArray()
          .indexOf(tileLayerRef.current)
        if (index !== -1) {
          map.overlayMapTypes.removeAt(index)
        }
      }
      /*         if (map) {
          map.overlayMapTypes.clear()
        } */
    }
  }, [map, active])

  return null
}

export default PrecipitationLayer

import React, { useEffect, useRef } from 'react'
import { fetchTemperatureMap, LayerTiles } from 'services/weatherData'

const TemperatureLayer: React.FC<{
  map: google.maps.Map | null
  active: boolean
}> = ({ map, active }) => {
  const imageCache = useRef(new Map<string, HTMLImageElement>()).current
  const tileLayerRef = useRef<google.maps.ImageMapType | null>(null)

  useEffect(() => {
    if (map && active) {
      const tileLayer = new google.maps.ImageMapType({
        getTileUrl: (coord, z) => {
          const tileKey = `${coord.x}_${coord.y}_${z}`
          if (imageCache.has(tileKey)) {
            return imageCache.get(tileKey)!.src
          }
          return fetchTemperatureMap({
            x: coord.x,
            y: coord.y,
            z,
          })
        },
        tileSize: new google.maps.Size(256, 256),
        opacity: 1,
        name: LayerTiles.Temperature,
      })
      tileLayerRef.current = tileLayer
      map.overlayMapTypes.push(tileLayer)
    } else if (map && tileLayerRef.current) {
      const index = map?.overlayMapTypes
        .getArray()
        .indexOf(tileLayerRef.current)
      if (index !== -1) {
        map.overlayMapTypes.removeAt(index)
      }
    }
    return () => {
      if (tileLayerRef.current) {
        const index = map?.overlayMapTypes
          .getArray()
          .indexOf(tileLayerRef.current)
        if (index !== undefined && index > -1) {
          map?.overlayMapTypes.removeAt(index)
        }
        tileLayerRef.current = null
      }
      /*         if (map) {
          map.overlayMapTypes.clear()
        } */
      imageCache.clear()
    }
  }, [map, imageCache, active])

  return null
}

export default TemperatureLayer

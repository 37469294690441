import { useCallback, useState } from 'react'
import { GEOLOCATION_OPTIONS } from 'configuration'
import { saveConfig, loadConfig } from 'services/configuration'

export default function useLocalConfiguration() {
  const [config, setConfig] = useState(loadConfig())

  const updateAndSaveConfig = useCallback(
    (param: string, value: any) => {
      const newConfig = {
        ...config,
        [param]: value,
      }
      saveConfig(newConfig)
      setConfig(newConfig)
    },
    [config],
  )

  const setLocale = useCallback(
    (locale: string) => {
      updateAndSaveConfig('locale', locale)
    },
    [updateAndSaveConfig],
  )

  const setRefresh = useCallback(
    (refresh: number) => {
      updateAndSaveConfig('lastRefresh', refresh)
    },
    [updateAndSaveConfig],
  )

  const setGpsTimeout = useCallback(
    (timeout: number | undefined) => {
      updateAndSaveConfig('gpsTimeout', timeout || GEOLOCATION_OPTIONS.timeout)
    },
    [updateAndSaveConfig],
  )

  const setAutoDownloadImages = useCallback(
    (autoDownloadImages: boolean) => {
      updateAndSaveConfig('autoDownloadImages', autoDownloadImages)
    },
    [updateAndSaveConfig],
  )
  const setZipWithImages = useCallback(
    (zipWithImages: boolean) => {
      updateAndSaveConfig('zipWithImages', zipWithImages)
    },
    [updateAndSaveConfig],
  )

  return {
    config,
    setLocale,
    setRefresh,
    setGpsTimeout,
    setAutoDownloadImages,
    setZipWithImages,
  }
}

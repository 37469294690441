import React, { MouseEvent as ReactMouseEvent } from 'react'
import { IonAlert } from '@ionic/react'
import './ConfirmationAlert.css'

export interface FormProps {
  title?: string
  subtitle?: string
  description?: string
  acceptText: string
  cancelText?: string
  isOpen: boolean
  isDestructive?: boolean
  onDidDismiss?: () => void
  onAccept?: () => void
  onCancel?: () => void
  onClick?: (ev: ReactMouseEvent<HTMLIonAlertElement>) => void
}

const ConfirmationAlert: React.FC<FormProps> = ({
  title,
  subtitle,
  description,
  acceptText,
  cancelText,
  isDestructive,
  isOpen,
  onDidDismiss,
  onAccept,
  onCancel,
  onClick,
}) => {
  const primaryButton = {
    text: `${acceptText}`,
    handler: onAccept,
    cssClass: 'alert-button-primary',
  }
  const secondaryButton = {
    text: `${cancelText}`,
    role: 'cancel',
    cssClass: 'alert-button-secondary',
    handler: onCancel,
  }
  const destructiveButton = {
    text: `${acceptText}`,
    cssClass: 'alert-button-destructive',
    handler: onAccept,
  }

  return (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      header={title}
      subHeader={subtitle}
      message={description}
      onClick={onClick}
      // REVIEW Consider exploring better approaches to hiding the cancel button
      buttons={
        isDestructive
          ? [secondaryButton, destructiveButton]
          : cancelText
            ? [secondaryButton, primaryButton]
            : [primaryButton]
      }
    />
  )
}

export default ConfirmationAlert

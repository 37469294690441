import { useRelevants } from 'context/ExpressionContext'
import { getCurrentGroup, getSelectedLink } from 'context/FormContext/selectors'
import useForm from 'hooks/useForm'
import useLayoutNavigation from 'hooks/useLayoutNavigation'
import { filter } from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'
import AddLinkFieldMenu from 'ui/components/menus/AddLinkFieldMenu'
import SaveMenu from 'ui/components/menus/SaveMenu'
import AppFooter, { AppFooterProps } from 'ui/layouts/footers/AppFooter'

interface EditorFooterProps extends AppFooterProps {
  onSave: () => void
  onSaveAndFinish: () => void
  onExit: () => void
  onAddLink: (linkName: string, linkModelId: string) => void
  readOnly?: boolean
}

type ShowLinksState =
  | {
      open: true
      event: Event
    }
  | { open: false }

const EditorFooter: React.FC<EditorFooterProps> = (props) => {
  const [showSaveMenu, setShowSaveMenu] = useState(false)
  const [showLinksMenu, setShowLinksMenu] = useState<ShowLinksState>({
    open: false,
  })
  const { state, groups = [], linkFields = [] } = useForm()

  const { shouldDisplayGroup, shouldDisplayField } = useRelevants()

  const visibleGroups = useMemo(
    () => filter((g) => shouldDisplayGroup(g.name), groups),
    [groups, shouldDisplayGroup],
  )

  const visibleLinks = useMemo(
    () => linkFields.filter((l) => shouldDisplayField(l.name)),
    [linkFields, shouldDisplayField],
  )

  const { footerNavigationConfig } = useLayoutNavigation(
    getCurrentGroup(state),
    visibleGroups,
    getSelectedLink(state),
  )

  const handleSaveClick = useCallback(() => {
    setShowSaveMenu(true)
  }, [])

  const handleSaveCancel = useCallback(() => {
    setShowSaveMenu(false)
  }, [])

  const handleShowLinks = useCallback((e: React.MouseEvent) => {
    setShowLinksMenu({
      open: true,
      event: e.nativeEvent,
    })
  }, [])

  const handleCancelLinks = useCallback(() => {
    setShowLinksMenu({
      open: false,
    })
  }, [])

  return (
    <>
      <AppFooter
        {...footerNavigationConfig}
        onShowSaveMenu={handleSaveClick}
        hasAddLinkMenu={!props.readOnly && visibleLinks.length > 0}
        onShowAddLinkMenu={handleShowLinks}
        onExit={props.onExit}
        showGPS={props.showGPS}
        onGPSClick={props.onGPSClick}
        hasLocation={props.hasLocation}
        isFetchingLocation={props.isFetchingLocation}
        hasSaveMenu={!props.readOnly}
      />
      {
        <SaveMenu
          isOpen={showSaveMenu}
          onDidDismiss={handleSaveCancel}
          onSave={props.onSave}
          onSaveAndFinish={props.onSaveAndFinish}
        />
      }
      {showLinksMenu.open && (
        <AddLinkFieldMenu
          isOpen={showLinksMenu.open}
          event={showLinksMenu.event}
          linkFields={visibleLinks}
          addLink={props.onAddLink}
          onDidDismiss={handleCancelLinks}
        />
      )}
    </>
  )
}

export default EditorFooter

import React from 'react'
import { ContentField } from 'core/ContentField'
import EditField from 'ui/components/fields'

interface LinearLayoutProps {
  fields: ContentField[]
  readOnly?: boolean
}
const LinearLayoutEditor: React.FC<LinearLayoutProps> = (props) => {
  const { fields = [], readOnly } = props

  return (
    <>
      {fields.map((field) => (
        <EditField key={field.name} fieldModel={field} readOnly={readOnly} />
      ))}
    </>
  )
}
export default React.memo(LinearLayoutEditor)

import React, { useCallback } from 'react'
import { IonList, IonText } from '@ionic/react'
import Popover from 'ui/components/modals/Popover'
import AssetNav from './AssetNav'
import AddLinkFieldMenuItem from './AddLinkFieldMenuItem'
import 'ui/components/modals/Popover.css'
import useTranslation from 'hooks/useTranslation'

export interface AddLinkFieldMenuProps {
  isOpen: boolean
  linkFields: {
    label: string
    name: string
    fieldModelId: string
  }[]
  addLink: (linkName: string, fieldModelId: string) => void
  event?: Event
  onDidDismiss: () => void
}

const AddLinkFieldMenu: React.FC<AddLinkFieldMenuProps> = (props) => {
  const { isOpen, linkFields = [], onDidDismiss, addLink, event } = props

  const t = useTranslation()

  const handleLinkClick = useCallback(
    (linkName: string, fieldModelId: string) => {
      addLink(linkName, fieldModelId)
      onDidDismiss()
    },
    [addLink, onDidDismiss],
  )

  return (
    <Popover isOpen={isOpen} event={event} onDidDismiss={onDidDismiss}>
      <IonText>
        <p className="popover-title">{t('buttons.addChild')}</p>
      </IonText>
      <IonList>
        <AssetNav>
          {linkFields.map((linkField) => (
            <AddLinkFieldMenuItem
              key={linkField.name}
              title={linkField.label}
              onClick={() =>
                handleLinkClick(linkField.name, linkField.fieldModelId)
              }
            />
          ))}
        </AssetNav>
      </IonList>
    </Popover>
  )
}

export default AddLinkFieldMenu

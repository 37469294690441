import React, { useState, useMemo, useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { filterOutline } from 'ionicons/icons'

import AppLayout from 'ui/layouts/AppLayout'
import Typography, { Variant } from 'ui/components/typography/Typography'
import AssetCardList from 'ui/components/lists/AssetCardList'
import FilterField from 'ui/components/fields/filters/FilterField'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import useModel from 'hooks/useModel'
import { useIdFields, useAssetView } from 'hooks/assets'

import { getPluralTitle, getTitleField } from 'core/ContentModel'
import { Filters } from 'core/ContentFieldFilter'
import { getLocalizedProperty } from 'core/common'

import Footer from './Footer'

import './ImportAssetByAttributes.css'

import { FORM_ID } from './constants'

import prepareQuery from './prepareQuery'
import getDefaultValuesForFields from './getDefaultValuesForFields'

import { FilterValues } from './index.d'

// REVIEW disable ripple effect on filter button?
// SEE https://stackoverflow.com/a/58473717/4916701
// REVIEW make filter button round? for now I leave it squared for simmetry with
// other buttons (e.g. in `AssetCard`)
const ImportAssetByAttributes: React.FC = () => {
  const t = useTranslation()
  const locale = useCurrentLocale()

  // NOTE setting `defaultValues` here does not help in resetting the form
  // because we need to provide `null` values (rather than `undefined`), so we
  // would need to create an object like in `getDefaultValuesForfields`
  // NOTE setting `shouldUnregister` simply discards form state after submission
  // (once the actual form is unmounted) and it cannot be recovered afterwards
  const form = useForm()
  const { reset } = form

  // model
  const { params } = useRouteMatch<{ modelId: string }>()
  const { modelId } = params

  const { data: model } = useModel(modelId)
  const titleField = getTitleField(model)
  const modelTitle = model ? getPluralTitle(locale, model) : ''

  // id fields
  const { data: fields } = useIdFields(modelId)

  // display form or list logic
  const [isOpen, setIsOpen] = useState<boolean>(true)

  // assets
  const [query, setQuery] = useState<Filters>()

  // NOTE do not limit the search by boundaries
  const { data, isLoading } = useAssetView(
    modelId,
    { filters: query },
    {
      enabled: Boolean(query),
    },
  )

  const assets = useMemo(() => {
    return (
      data?.map((asset) => ({
        id: asset.id,
        title: titleField ? asset.data[titleField] : '-',
        modifiedAt: new Date(asset.updatedAt),
      })) ?? []
    )
  }, [data, titleField])

  // form
  const handleSubmit: SubmitHandler<FilterValues> = (data) => {
    // NOTE make TS aware of fields being ready
    if (!fields) return

    const query = prepareQuery(fields, data)

    setQuery(query)

    setIsOpen(false)
  }

  // NOTE react-hook-form's reset method does not work for controlled inputs
  // unless you have provided with default values for them. Since our fields are
  // dynamic, we cannot set default values by the time we call the `useForm`
  // hook (well, we could do it with an async function but that feels kind of
  // clumsy). Setting `defaultValue` in each `Controller` does not work either
  // (by design)
  // NOTE strangely enough, reset works for the initial form, but not after it
  // has been submitted. This is likely due to the fact that components are
  // being unmounted and remounted, so they previous values are being treated as
  // their defaults (actually reset will restore the value used for submission)
  // SEE https://react-hook-form.com/docs/useform/reset (rules)
  // SEE https://github.com/orgs/react-hook-form/discussions/7589
  const handleReset = useCallback(() => {
    // NOTE make TS aware of fields being ready
    if (!fields) return

    const defaultValues = getDefaultValuesForFields(fields)
    reset(defaultValues)
  }, [reset, fields])

  return (
    <AppLayout
      pageTitle={t('import.type', {
        label: modelTitle,
      })}
      footer={isOpen ? <Footer onReset={handleReset} /> : null}
    >
      <div className="import-by-attributes-heading">
        <Typography variant={Variant.caption}>
          {t('import.advancedSearch.attributes.heading')}
        </Typography>
      </div>
      {!isOpen ? (
        <IonButton
          fill="clear"
          className="import-by-attributes-filter-button"
          onClick={() => setIsOpen(true)}
        >
          <IonIcon
            slot="icon-only"
            color="primary"
            size="large"
            className="import-by-attributes-filter-icon"
            icon={filterOutline}
          />
        </IonButton>
      ) : null}
      {isOpen ? (
        <FormProvider {...form}>
          <form
            id={FORM_ID}
            className="import-by-attributes-form"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <IonAccordionGroup>
              {fields?.map((field) => (
                <IonAccordion key={field._id}>
                  <IonItem slot="header" color="light">
                    <IonLabel>
                      <Typography variant={Variant.body2}>
                        {getLocalizedProperty(locale, 'label', field)}
                      </Typography>
                    </IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    <FilterField fieldModel={field} />
                  </div>
                </IonAccordion>
              ))}
            </IonAccordionGroup>
          </form>
        </FormProvider>
      ) : (
        <AssetCardList
          modelId={modelId}
          assets={assets}
          isLoading={isLoading}
        />
      )}
    </AppLayout>
  )
}

export default ImportAssetByAttributes

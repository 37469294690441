import React from 'react'
import { IonButton, IonLabel, IonIcon } from '@ionic/react'
import './CtaButton.css'

export interface CtaButtonProps {
  mainText: string
  secondaryText?: string
  icon?: string
  fill?: string
  routerLink?: string
  disabled?: boolean
  onClick?: () => void
}

const CtaButton: React.FC<CtaButtonProps> = ({
  mainText,
  secondaryText,
  icon,
  fill,
  routerLink,
  disabled,
  onClick,
}) => {
  return (
    <IonButton
      expand="block"
      size="large"
      color="primary"
      fill={fill === 'outline' ? 'outline' : 'solid'}
      className={
        icon
          ? 'ion-margin-vertical ion-text-capitalize cta-button cta-button-with-icon'
          : 'ion-margin-vertical ion-text-capitalize'
      }
      onClick={onClick}
      routerLink={routerLink}
      disabled={disabled}
    >
      {secondaryText || icon ? (
        <IonLabel
          className={
            icon
              ? 'ion-padding-vertical ion-text-capitalize cta-button-label cta-label-with-icon'
              : 'ion-padding-vertical ion-text-capitalize cta-button-label'
          }
        >
          <p className="cta-button-text">{mainText}</p>
          <p className="cta-button-secondary-text">{secondaryText}</p>
        </IonLabel>
      ) : (
        <>{mainText}</>
      )}
      {icon ? (
        <IonIcon icon={icon} slot="end" className="cta-button-icon" />
      ) : null}
    </IonButton>
  )
}

export default CtaButton

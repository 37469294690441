import React, { useState, useCallback } from 'react'
import { IonItemGroup, IonItemDivider } from '@ionic/react'
import { groupBy, prop } from 'ramda'

import AssetItem from 'ui/components/items/AssetItem'
import SubmissionItemList from 'ui/components/lists/SubmissionItemList'
import ItemSkeleton from 'ui/components/loaders/ItemSkeleton'
import Typography, { Variant } from 'ui/components/typography/Typography'
import ConfirmationAlert from 'ui/components/alerts/ConfirmationAlert'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import useModel from 'hooks/useModel'
import { useSubmission } from 'hooks/submission'
import useAssetLocalSubmissions from 'hooks/assets/useAssetLocalSubmissions'
import { useInspectionModels } from 'hooks/useModelList'
import useInterventionModels from 'hooks/useInterventionModels'
import useInspectionsByAsset from 'hooks/useInspectionsByAsset'

import { extractTitle, getTitle } from 'core/ContentModel'
import { FullAsset } from 'core/Assets'
import { SubmissionType } from 'core/Submission'
import formatDate from 'common/formatDate'

import getAssetRemoveAlertDescription from './getAssetRemoveAlertDescription'

interface RemoteAssetItemProps {
  asset: FullAsset
  onClick: () => void
  onRemove: (assetId: string) => void
}

const RemoteAssetItem: React.FC<RemoteAssetItemProps> = (props) => {
  const { asset, onClick, onRemove } = props

  const t = useTranslation()
  const locale = useCurrentLocale()

  // model
  const { data: model, isLoading: isLoadingModel } = useModel(asset.assetType)

  // revision
  const { data: revision, isLoading: isLoadingRevision } = useSubmission(
    asset.revisionSubmissionId!,
    {
      enabled: Boolean(asset.revisionSubmissionId),
    },
  )

  // remote inspections
  const relatedRemoteInspections = useInspectionsByAsset(asset.id)

  // submissions
  const relatedSubmissions = useAssetLocalSubmissions(asset.id)

  // inspection models
  const { data: inspectionModels = [], isLoading: isLoadingInspectionModels } =
    useInspectionModels(asset.assetType)

  // intervention models
  const {
    data: interventionModels = [],
    isLoading: isLoadingInterventionModels,
  } = useInterventionModels(asset.assetType)

  // removal
  const [showRemoveConfirmation, setRemoveConfirmation] = useState(false)

  // TODO ask for confirmation if a revision exists, too
  const handleRemove = useCallback(
    (assetId: string) => {
      if (relatedSubmissions.length > 0) {
        setRemoveConfirmation(true)
      } else {
        onRemove(assetId)
      }
    },
    [relatedSubmissions, onRemove],
  )

  if (
    isLoadingModel ||
    isLoadingInspectionModels ||
    isLoadingInterventionModels ||
    isLoadingRevision
  ) {
    return <ItemSkeleton />
  }

  // NOTE we may have an asset with no model, due to changes in permissions or
  // by using a shared phone
  // NOTE we are already preemptively returning if model is loading (see above)
  if (!model) return null

  // misc
  const assetTitle = asset.label ?? extractTitle(model, asset)
  const title = assetTitle ?? getTitle(locale, model)

  const submissionsByType = groupBy(prop('type'), relatedSubmissions)

  const removeAlertDescription = getAssetRemoveAlertDescription(
    submissionsByType,
    t,
  )

  return (
    <>
      <ConfirmationAlert
        isOpen={showRemoveConfirmation}
        title={t('confirmation.deleteElement')}
        description={removeAlertDescription}
        acceptText={t('buttons.confirmSelection')}
        cancelText={t('buttons.cancelSelection')}
        onAccept={() => onRemove(asset.id)}
        onDidDismiss={() => setRemoveConfirmation(false)}
      />
      <AssetItem
        id={asset.id}
        title={title}
        details={formatDate(asset.downloadedAt)}
        status={revision ? revision.status : 'downloaded'}
        onClick={onClick}
        onDelete={() => handleRemove(asset.id)}
      >
        {submissionsByType[SubmissionType.NewInspection]?.length > 0 ||
        relatedRemoteInspections.length > 0 ? (
          <IonItemGroup>
            <IonItemDivider color="light">
              <Typography
                variant={Variant.overline}
                style={{ fontWeight: 'bold' }}
              >
                {t('inspection')}
              </Typography>
            </IonItemDivider>
            <SubmissionItemList
              assetTitle={assetTitle}
              models={inspectionModels}
              submissions={submissionsByType[SubmissionType.NewInspection]}
              inspections={relatedRemoteInspections}
            />
          </IonItemGroup>
        ) : null}
        {submissionsByType[SubmissionType.NewIntervention]?.length > 0 ? (
          <IonItemGroup>
            <IonItemDivider color="light">
              <Typography
                variant={Variant.overline}
                style={{ fontWeight: 'bold' }}
              >
                {t('intervention')}
              </Typography>
            </IonItemDivider>
            <SubmissionItemList
              assetTitle={assetTitle}
              models={interventionModels}
              submissions={submissionsByType[SubmissionType.NewIntervention]}
            />
          </IonItemGroup>
        ) : null}
      </AssetItem>
    </>
  )
}

export default RemoteAssetItem

import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { assoc, filter, isNil, map, indexBy, pipe, has, isEmpty } from 'ramda'
import { GroupConfig, LayoutConfig } from 'core/Layout'
import useLayout from 'hooks/useLayout'
import useModel from 'hooks/useModel'
import { ContentField } from 'core/ContentField'
import { fromXLSForm, makeScope } from 'core/expressions'
import { useInspectedAsset } from 'context/InspectedAssetContext'
import { ChildSubmissions } from '../FormContext/reducer'

type RelevantInfo = {
  name: string
  relevant: string
  expression: (data: object) => any
}
type GroupRelevantCache = {
  [groupName: string]: RelevantInfo
}

type FieldRelevantCache = {
  [fieldName: string]: RelevantInfo
}

type ExpressionCache = {
  expressions: {
    groups: GroupRelevantCache
    fields: FieldRelevantCache
  }
  expressionScope: object
}

const ExpressionContext = createContext<ExpressionCache>({
  expressions: {
    groups: {},
    fields: {},
  },
  expressionScope: {},
})

const makeRelevantGroupCache: (groups: GroupConfig[]) => GroupRelevantCache =
  pipe(
    filter<GroupConfig>(
      (g) => !isNil(g.relevant) && !isEmpty(g.relevant?.trim()),
    ),
    map((g: GroupConfig) => {
      return {
        name: g.name,
        relevant: g.relevant,
        expression: fromXLSForm(g.relevant),
      } as RelevantInfo
    }),
    indexBy<RelevantInfo>((x) => x.name),
  )

type ExpressionProviderProps = React.PropsWithChildren<{
  modelId?: string
  rootModelId?: string
  rootData: { [fieldName: string]: any }
  data: { [fieldName: string]: any }
  formChildren: ChildSubmissions
  ancestors: string[]
}>

const getGroups = (layout: LayoutConfig): GroupRelevantCache => {
  if (layout.type !== 'group') return {}
  return makeRelevantGroupCache(layout.groups || [])
}

const getFields = (fields: ContentField[] = []): FieldRelevantCache => {
  return fields
    .filter((f) => !isNil(f.relevant) && !isEmpty(f.relevant?.trim()))
    .reduce(
      (acc, f) =>
        assoc(
          f.name,
          {
            name: f.name,
            relevant: f.relevant,
            expression: fromXLSForm(f.relevant),
          },
          acc,
        ),
      {},
    )
}

export const ExpressionProvider: React.FC<ExpressionProviderProps> = (
  props,
) => {
  const { asset } = useInspectedAsset()
  const { data: model, error: modelError } = useModel(props.modelId)
  const { data: assetRootModel } = useModel(asset?.assetType)
  const { data: rootModel } = useModel(props.rootModelId)
  const { data: layout, error: layoutError } = useLayout(props.modelId)

  const expressionScope = useMemo(
    () =>
      makeScope(
        props.data,
        props.rootData,
        props.ancestors,
        props.formChildren,
        asset,
        rootModel,
        assetRootModel,
        model,
      ),
    [
      props.data,
      props.rootData,
      props.ancestors,
      props.formChildren,
      asset,
      rootModel,
      assetRootModel,
      model,
    ],
  )

  const expressions = useMemo(() => {
    if (!layout || !model)
      return {
        groups: {},
        fields: {},
      }
    const groups = getGroups(layout)
    const fields = getFields(model.fields)

    return {
      groups,
      fields,
    }
  }, [layout, model])

  if (modelError) {
    console.warn('ExpressionProvider error loading model', modelError)
  }
  if (layoutError) {
    console.warn('ExpressionProvider error loading layout')
  }

  return (
    <ExpressionContext.Provider value={{ expressions, expressionScope }}>
      {props.children}
    </ExpressionContext.Provider>
  )
}

export const useDisplayField = (fieldName: string) => {
  const { expressions, expressionScope } = useContext(ExpressionContext)
  if (!has(fieldName, expressions.fields)) return true
  return Boolean(expressions.fields[fieldName].expression(expressionScope))
}

export const useRelevants = () => {
  const { expressions, expressionScope } = useContext(ExpressionContext)

  const shouldDisplayField = useCallback(
    (fieldName: string) => {
      if (!has(fieldName, expressions.fields)) return true
      try {
        return Boolean(
          expressions.fields[fieldName].expression(expressionScope),
        )
      } catch (err) {
        console.warn(
          'shouldDisplayField - Error in relevant expression for field: ' +
            fieldName,
          err,
        )
        return true
      }
    },
    [expressions, expressionScope],
  )

  const shouldDisplayGroup = useCallback(
    (groupName: string) => {
      if (!has(groupName, expressions.groups)) return true
      try {
        return Boolean(
          expressions.groups[groupName].expression(expressionScope),
        )
      } catch (err) {
        console.warn(
          'shouldDisplayGroup - Error in relevant expression for group: ' +
            groupName,
          err,
        )
        return true
      }
    },
    [expressions, expressionScope],
  )

  return { shouldDisplayField, shouldDisplayGroup }
}

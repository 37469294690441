import React from 'react'
import {
  IonItemSliding,
  IonItem,
  IonLabel,
  IonText,
  IonNote,
  IonItemOptions,
  IonItemOption,
  IonIcon,
} from '@ionic/react'
import { trashOutline } from 'ionicons/icons'

import './AssetNavItem.css'

export interface AssetNavItemProps {
  title: string
  assetType?: string
  assetDetails?: React.ReactNode
  childrenNumber?: string
  onClick: () => void
  onDelete?: () => void
  tag?: React.ReactElement<any, any>
}

const AssetNavItem: React.FC<AssetNavItemProps> = (props) => {
  const {
    title,
    assetType,
    assetDetails,
    childrenNumber,
    onClick,
    onDelete,
    tag,
  } = props

  return (
    <IonItemSliding>
      <IonItem button detail onClick={onClick}>
        <IonLabel className="ion-text-wrap">
          <IonText>
            <h2>{title}</h2>
          </IonText>
          <p>
            {assetType}
            {tag}
          </p>
          <p className="asset-nav-item-details">{assetDetails}</p>
        </IonLabel>
        {childrenNumber ? (
          <IonNote
            slot="end"
            color="medium"
            className="ion-padding-horizontal asset-nav-item-note"
          >
            {childrenNumber}
          </IonNote>
        ) : null}
      </IonItem>
      <IonItemOptions side="end">
        {onDelete && (
          <IonItemOption color="danger" onClick={onDelete}>
            <IonIcon icon={trashOutline} size="large" />
          </IonItemOption>
        )}
      </IonItemOptions>
    </IonItemSliding>
  )
}

export default AssetNavItem

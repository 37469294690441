import { get } from './network/api'
import { API_URL } from './network/constants'

import { Inspections } from './db'

import { Inspection, InspectionSummary } from 'core/Inspections'

interface GetInspectionsResponse {
  assetId: string
  entries: Record<string, InspectionSummary[]>
}

export const fetchInspectionsForAsset = (
  assetId: string,
): Promise<InspectionSummary[]> => {
  return get(`${API_URL}/inspections?assetId=${assetId}&onlyGetLast=true`).then(
    (response: GetInspectionsResponse) => {
      return Object.values(response.entries).reduce(
        (acc: InspectionSummary[], current: InspectionSummary[]) => {
          return acc.concat(current)
        },
        [],
      )
    },
  )
}

export const fetchInspection = async (
  inspectionId: string,
): Promise<Inspection> => {
  const inspection = await get(`${API_URL}/inspections/${inspectionId}`)

  await Inspections.put(inspection)

  return inspection
}

export const getRemoteInspectionsByAsset = async (
  assetId: string,
): Promise<Inspection[]> => {
  return Inspections.where('assetId').equals(assetId).toArray()
}

export const removeInspection = (inspectionId: string) => {
  return Inspections.delete(inspectionId)
}

export const removeInspectionsByAssetId = (assetId: string) => {
  return Inspections.where({ assetId }).delete()
}

import React, { useCallback } from 'react'
import { IonList } from '@ionic/react'
import { useRouteMatch, useHistory } from 'react-router'

import AppLayout from 'ui/layouts/AppLayout'
import AssetNav from 'ui/components/menus/AssetNav'
import AssetNavItem from 'ui/components/menus/AssetNavItem'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import useModel from 'hooks/useModel'
import useInterventionModels from 'hooks/useInterventionModels'
import useSubmissionSource from 'hooks/submission/useSubmissionSource'

import { extractTitle, getTitle } from 'core/ContentModel'
import { SubmissionSourceType } from 'core/Submission'

type Params = {
  assetId: string
}

type LocationState = {
  sourceType: SubmissionSourceType
}

/**
 * REVIEW both `ChooseInspection` and `ChooseIntervention` pages are pretty much
 * the same, except they:
 * - use different hooks for submission models (inspections or interventions)
 * - redirect to different routes
 * IDEA merge them into a `ChooseSubmission` page
 */
const ChooseInspection: React.FC = () => {
  const { params } = useRouteMatch<Params>()
  const { assetId } = params

  const { replace, location } = useHistory<LocationState>()
  const sourceType = location.state?.sourceType ?? SubmissionSourceType.Local

  const t = useTranslation()
  const locale = useCurrentLocale()

  // asset (submission source)
  const { data: asset, isLoading: isLoadingAsset } = useSubmissionSource({
    id: assetId,
    sourceType,
  })

  // model
  const { data: model } = useModel(asset?.assetType)

  // submission (intervention) models
  const { data: interventionModels = [], isLoading: isLoadingModels } =
    useInterventionModels(asset?.assetType)

  const handleChooseInterventionModel = useCallback(
    (modelId: string) => {
      const route =
        sourceType === SubmissionSourceType.Remote
          ? 'new-intervention'
          : 'new-local-intervention'
      replace(`/${route}/${assetId}/${modelId}`)
    },
    [replace, assetId, sourceType],
  )

  // misc
  // REVIEW this is undefined/null for new (local) assets
  const assetTitle = extractTitle(model, asset)

  const modelTitle = `${assetTitle} (${model ? getTitle(locale, model) : ''})`

  return (
    <AppLayout
      pageTitle={t('interventions.chooseInterventionType')}
      pageSubtitle={modelTitle}
    >
      <IonList>
        <AssetNav isLoading={isLoadingAsset || isLoadingModels}>
          {interventionModels.map((intervention) => (
            <AssetNavItem
              key={intervention.id}
              title={getTitle(locale, intervention)}
              onClick={() => handleChooseInterventionModel(intervention.id)}
            />
          ))}
        </AssetNav>
      </IonList>
    </AppLayout>
  )
}

export default ChooseInspection

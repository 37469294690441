import { Translation } from './common'
import { ContentModel } from './ContentModel'
import { ContentField } from './ContentField'
import { isNil } from 'ramda'

interface BaseLayout {
  assetTypeId: string
  name: string
}

/* enum LayoutTypes {
  linear = 'linear',
  group = 'group'
}
 */
enum AvailableLayouts {
  assetDetails = 'asset-details',
  iD = 'ID',
  editLayout = 'edit-layout',
}

export interface LinearLayoutConfig extends BaseLayout {
  id: string
  type: 'linear'
  fields: string[]
}

export interface LinearLayout extends BaseLayout {
  type: 'linear'
  /**
   * Ordered list of model field ids
   */
  fields: ContentField[]
}

export interface GroupConfig {
  name: string
  label: string
  locales: Translation[]
  relevant?: string
  fields: string[]
}

export interface Group {
  name: string
  label: string
  locales: Translation[]
  relevant?: string
  fields: ContentField[]
}

export interface GroupLayoutConfig extends BaseLayout {
  id: string
  type: 'group'
  groups: GroupConfig[]
}

export interface GroupLayout extends BaseLayout {
  type: 'group'
  groups: Group[]
}

export type Layout = LinearLayout | GroupLayout
export type LayoutConfig = LinearLayoutConfig | GroupLayoutConfig

/**
 * Converts a LayoutConfig into a proper Layout with field ids
 * replaced with actual field configuration
 * @param model
 * @param layout
 */

export const makeLayoutWithFields = (
  model: ContentModel,
  layout: LayoutConfig,
): Layout | null => {
  const fieldIdToFullField = (fieldId: string) =>
    model.fields.find((field) => field._id === fieldId) || null

  const mergeFields = (fieldIds: string[] = []): ContentField[] =>
    fieldIds.map(fieldIdToFullField).filter((f) => !isNil(f)) as ContentField[]

  switch (layout.type) {
    case 'linear': {
      return {
        ...layout,
        fields: mergeFields(layout.fields as string[]),
      }
    }
    case 'group': {
      return {
        ...layout,
        groups: layout.groups.map((g) => ({
          ...g,
          fields: mergeFields(g.fields as string[]),
        })),
      }
    }
    default:
      return null
  }
}

export const isIDLayout = (layout: LayoutConfig) =>
  layout.name === AvailableLayouts.iD

export const isEditLayout = (layout: LayoutConfig) =>
  [AvailableLayouts.editLayout, AvailableLayouts.assetDetails].includes(
    layout.name as AvailableLayouts,
  )

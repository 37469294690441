import React from 'react'
import { Controller } from 'react-hook-form'
import { IonItemGroup, IonItem, IonCheckbox } from '@ionic/react'

import useTranslation from 'hooks/useTranslation'
import { useCurrentLocale } from 'context/LocaleContext'

import { BooleanField } from 'core/ContentField'
import { getLocalizedProperty } from 'core/common'

interface ControlledCheckboxProps {
  name: string
  label: string
}

const ControlledCheckbox: React.FC<ControlledCheckboxProps> = (props) => {
  const { name, label } = props
  return (
    <Controller
      name={name}
      defaultValue={false}
      render={({ field }) => (
        <IonCheckbox
          justify="space-between"
          checked={field.value}
          onIonChange={(ev) => field.onChange(ev.detail.checked)}
          onIonBlur={field.onBlur}
        >
          {label}
        </IonCheckbox>
      )}
    />
  )
}

interface BooleanFilterFieldProps {
  fieldModel: BooleanField
}

const BooleanFilterField: React.FC<BooleanFilterFieldProps> = (props) => {
  const { fieldModel } = props
  const { name } = fieldModel

  const locale = useCurrentLocale()
  const t = useTranslation()

  const trueLabel = fieldModel.trueLabel
    ? getLocalizedProperty(locale, 'trueLabel', fieldModel)
    : t('true')

  const falseLabel = fieldModel.falseLabel
    ? getLocalizedProperty(locale, 'falseLabel', fieldModel)
    : t('false')

  return (
    <IonItemGroup>
      <IonItem>
        <ControlledCheckbox name={`${name}.true`} label={trueLabel} />
      </IonItem>
      <IonItem>
        <ControlledCheckbox name={`${name}.false`} label={falseLabel} />
      </IonItem>
      <IonItem>
        <ControlledCheckbox name={`${name}.null`} label={t('withoutValue')} />
      </IonItem>
    </IonItemGroup>
  )
}

export default BooleanFilterField

import { indexBy, prop } from 'ramda'
import { useQuery } from 'react-query'
import { getModelsWhereIdIn } from 'services/models'

export default function useModelsById(modelIds: string[] = []) {
  return useQuery(
    ['modelsById', modelIds],
    async function () {
      return getModelsWhereIdIn(modelIds).then(indexBy(prop('id')))
      //.catch(always({}))
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}

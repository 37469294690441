import React, { useRef, useState, useCallback } from 'react'
import {
  IonAccordionGroup,
  IonAccordion,
  IonList,
  IonItemSliding,
  IonItem,
  IonLabel,
  IonText,
  IonPopover,
  IonContent,
  IonItemOptions,
  IonItemOption,
  IonIcon,
} from '@ionic/react'
import {
  chevronDown,
  addCircleOutline,
  informationCircle,
  trashOutline,
} from 'ionicons/icons'

import StatusTag from 'ui/components/tags/StatusTag'
import Typography, { Variant } from 'ui/components/typography/Typography'

import useTranslation from 'hooks/useTranslation'

import { SubmissionStatus } from 'core/Submission'

import './AssetItem.css'

export interface AssetItemProps {
  id: string
  title: string
  details: React.ReactNode
  status: SubmissionStatus | 'downloaded'
  children?: React.ReactNode
  onClick: () => void
  onDelete: () => void
}

enum Actions {
  ShowInfo = 'info',
  Options = 'options',
  Delete = 'delete',
}

// NOTE this cannot reuse `Item` component because of the hack in place to
// prevent the accordion from toggling when the user clicks in other actions
// (e.g. delete). we could pass the main actions as a prop (e.g. children) but
// passing down the delete option too feels like too much
// REVIEW in case this gets fixed or improved in the future, we could pass an
// optional `color` and the actions to `Item`
const AssetItem: React.FC<AssetItemProps> = (props) => {
  const { id, title, details, status, children, onClick, onDelete } = props

  const t = useTranslation()

  // NOTE keep track of the accordion's state
  const [expanded, setExpanded] = useState(false)

  const popoverRef = useRef<HTMLIonPopoverElement>(null)
  const [isInfoShown, setIsInfoShown] = useState(false)

  // NOTE `React.Children.count` will return misleading results with children
  // that do not actually render (i.e. `undefined`, `null`, or boolean values)
  const childrenCount = React.Children.count(React.Children.toArray(children))

  // NOTE calling `ev.preventDefault` within the click handlers of inner actions
  // (i.e. options, delete) does not prevent the accordion from being toggled
  // (expanding or collapsing)
  // SEE https://github.com/ionic-team/ionic-framework/issues/28635
  // HACK listen to clicks during capture phase to decide whether to call an
  // inner action handler or default to toggling the accordion state
  // NOTE setting `readonly` prop in accordion or accordion group will prevent
  // any pointer events inside of it, so we cannot rely on it
  // HACK reuse this handler to prevent the accordion from toggling if no
  // children are provided
  // NOTE for the same reason, we cannot use an uncontrolled popover to display
  // the info about already exported assets
  // HACK instead, we reuse this handler once again to programatically display
  // the popover
  // SEE https://ionicframework.com/docs/api/popover#isopen-property
  const handleClickCapture = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const target = ev.target as HTMLElement

      if (childrenCount <= 0 || target.dataset.action) {
        ev.stopPropagation()

        switch (target.dataset.action) {
          case Actions.ShowInfo:
            if (!popoverRef.current) return
            // NOTE pass the event to the popover so that it knows where to
            // place itself
            popoverRef.current.event = ev
            setIsInfoShown(true)
            return
          case Actions.Options:
            onClick()
            return
          case Actions.Delete:
            onDelete()
            return
        }
      }
    },
    [childrenCount, onClick, onDelete],
  )

  return (
    <div onClickCapture={handleClickCapture}>
      <IonAccordionGroup
        onIonChange={(ev) => setExpanded(Boolean(ev.detail.value))}
      >
        <IonAccordion>
          <IonItemSliding
            slot="header"
            id={id}
            data-expanded={expanded ? true : undefined}
          >
            <IonItem className="asset-item" lines="full">
              <IonLabel className="ion-text-wrap">
                <IonText>
                  <h2>{title}</h2>
                </IonText>
                <Typography color="medium" variant={Variant.caption}>
                  {details}
                </Typography>
                <br />
                <StatusTag status={status} />
              </IonLabel>
              {/* 
              NOTE accordion' toggle icon is not displayed when an element 
              different than `Item` is used for the header slot, so we need to 
              add it manually 
              */}
              {childrenCount > 0 ? (
                <IonIcon
                  icon={chevronDown}
                  className="ion-accordion-toggle-icon"
                />
              ) : null}
              {status === SubmissionStatus.uploaded ? (
                <>
                  <IonIcon
                    slot="end"
                    icon={informationCircle}
                    data-action={Actions.ShowInfo}
                  />
                  <IonPopover
                    ref={popoverRef}
                    className="asset-item-popover"
                    isOpen={isInfoShown}
                    onDidDismiss={() => setIsInfoShown(false)}
                  >
                    <IonContent className="ion-padding">
                      {t('takeData.exportedAsset.tooltip')}
                    </IonContent>
                  </IonPopover>
                </>
              ) : (
                <IonIcon
                  slot="end"
                  icon={addCircleOutline}
                  onClick={onClick}
                  data-action={Actions.Options}
                />
              )}
            </IonItem>
            <IonItemOptions side="end" className="asset-item-options">
              <IonItemOption
                color="danger"
                onClick={onDelete}
                data-action={Actions.Delete}
              >
                <IonIcon
                  size="large"
                  icon={trashOutline}
                  data-action={Actions.Delete}
                />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
          {childrenCount > 0 ? (
            <IonList style={{ padding: 0 }} slot="content">
              {children}
            </IonList>
          ) : null}
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  )
}

export default AssetItem

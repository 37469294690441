import { useQuery } from 'react-query'
import { getModelById } from '../services/models'
import useToast from './useToast'

export default function useModel(modelId?: string) {
  const Toast = useToast()

  return useQuery(
    ['model', modelId],
    async () => {
      if (!modelId) return undefined
      return getModelById(modelId)
    },
    {
      enabled: !!modelId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60000,
      onError: () => {
        Toast.error('Ocurrió un error al obtener el modelo')
      },
    },
  )
}

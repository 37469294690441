import React, { useState, useCallback, useMemo } from 'react'
import { IonList } from '@ionic/react'
import { indexBy, prop } from 'ramda'

import ListSkeleton from 'ui/components/loaders/ListSkeleton'
import { EmptyItem } from 'ui/components/loaders/EmptyItem'
import AssetCard, {
  AssetData,
  ExtendedAssetData,
} from 'ui/components/cards/AssetCard'
import Typography, { Variant } from 'ui/components/typography/Typography'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslations from 'hooks/useTranslation'
import useModel from 'hooks/useModel'
import { useSaveAsset, useAssetsByModelId } from 'hooks/assets'
import useSaveInspection from 'hooks/useSaveInspection'

import { getTitle } from 'core/ContentModel'

interface AssetCardListProps {
  modelId: string
  assets?: AssetData[]
  selectedAsset?: string
  isLoading?: boolean
  hasSearchText?: boolean
}

const AssetCardList: React.FC<AssetCardListProps> = (props) => {
  const { modelId, assets, selectedAsset, isLoading, hasSearchText } = props
  const t = useTranslations()
  const locale = useCurrentLocale()

  const { data: model } = useModel(modelId)
  const type = model ? getTitle(locale, model) : ''

  const [loading, setLoading] = useState<Record<string, boolean>>({})

  const setAssetLoading = useCallback((assetId: string, value: boolean) => {
    setLoading((loading) => ({
      ...loading,
      [assetId]: value,
    }))
  }, [])

  const [saveAsset] = useSaveAsset()
  const [saveInspection] = useSaveInspection()

  const { data: remoteAssets, refetch: refetchDownloadedAssets } =
    useAssetsByModelId(modelId)

  const handleDownloadAsset = useCallback(
    async (assetId: string, label: string, inspectionId?: string) => {
      // NOTE already fetching
      if (loading[assetId]) return

      setAssetLoading(assetId, true)

      if (inspectionId) {
        await saveInspection(inspectionId)
      }

      await saveAsset(
        { assetId, label },
        { onSettled: () => setAssetLoading(assetId, false) },
      )

      refetchDownloadedAssets()
    },
    [
      loading,
      setAssetLoading,
      saveAsset,
      saveInspection,
      refetchDownloadedAssets,
    ],
  )

  const finalAssets: ExtendedAssetData[] | undefined = useMemo(() => {
    if (!remoteAssets) return assets

    const remoteAssetsById = indexBy(prop('id'), remoteAssets)

    return assets?.map((asset) => {
      if (remoteAssetsById[asset.id]) {
        const copy = remoteAssetsById[asset.id]

        return Object.assign({}, asset, {
          updatedAt: copy.downloadedAt,
          isDownloaded: true,
        })
      }
      return asset
    })
  }, [assets, remoteAssets])

  if (isLoading) return <ListSkeleton />

  return (
    <IonList>
      <Typography variant={Variant.caption} style={{ paddingLeft: '16px' }}>
        {t('import.results', {
          label: type,
          count:
            finalAssets && finalAssets.length > 0 ? finalAssets.length : '',
        })}
      </Typography>
      {finalAssets?.length === 0 ? (
        <EmptyItem
          text={
            hasSearchText
              ? t('import.search.notFound.title')
              : t('import.search.empty.title')
          }
          secondaryText={
            hasSearchText
              ? t('import.search.notFound.subtitle')
              : t('import.search.empty.subtitle')
          }
        />
      ) : (
        finalAssets?.map((asset) => (
          <AssetCard
            key={asset.id}
            id={asset.id}
            title={asset.title}
            assetType={type}
            modifiedAt={asset.modifiedAt}
            isSelected={asset.id === selectedAsset}
            isDownloaded={asset.isDownloaded}
            isLoading={loading[asset.id]}
            onDownloadAsset={handleDownloadAsset}
          />
        ))
      )}
    </IonList>
  )
}

export default AssetCardList

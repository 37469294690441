import React from 'react'

import ChooseIntervention from 'ui/views/ChooseIntervention'

// REVIEW is this intermediate component really necessary?
const ChooseInterventionPage = () => {
  return <ChooseIntervention />
}

export default ChooseInterventionPage

import React, { useState } from 'react'
import { IonPage, IonButton, IonHeader, IonLoading } from '@ionic/react'
import AppContent from 'ui/layouts/AppContent'
import LoginForm from 'ui/components/forms/LoginForm'
import './Login.css'
import OfflineMessage from 'ui/layouts/headers/OfflineMessage'
import { UserCredentials } from 'core/User'
import AppLogo from 'ui/components/images/AppLogo'
import AppVersion from 'ui/components/appVersion/AppVersion'
import { AppTheme } from 'core/Theme'

interface LoginProps {
  login: (credentials: UserCredentials) => Promise<void>
  isLoginLoading: boolean
  isOffline?: boolean
  loginError: string
  signInText?: string
  usernameLabel?: string
  passwordLabel?: string
}

const Login: React.FC<LoginProps> = ({
  login,
  isLoginLoading,
  isOffline = false,
  loginError,
  signInText = 'Iniciar sesión',
  usernameLabel,
  passwordLabel,
}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleUsername = (e: CustomEvent) => {
    setUsername(e.detail.value)
  }

  const handlePassword = (e: CustomEvent) => {
    setPassword(e.detail.value)
  }

  const handleSubmit = async () => {
    await login({ username, password })
  }

  return (
    <IonPage>
      {isOffline ? (
        <IonHeader>
          <OfflineMessage />
        </IonHeader>
      ) : null}
      <AppContent>
        <IonLoading isOpen={isLoginLoading} />
        <div className="login-wrapper ion-padding-horizontal">
          <div className="logo ion-margin-vertical">
            <AppLogo />
          </div>
          <h1 className="home-title">{AppTheme.appTitle}</h1>
          <LoginForm
            error={loginError}
            onUsernameChange={handleUsername}
            onPasswordChange={handlePassword}
            onSubmit={handleSubmit}
            usernameLabel={usernameLabel}
            passwordLabel={passwordLabel}
          />
          <IonButton
            expand="block"
            size="default"
            disabled={isLoginLoading}
            onClick={handleSubmit}
            className="button"
          >
            {signInText}
          </IonButton>

          <AppVersion />
        </div>
      </AppContent>
    </IonPage>
  )
}

export default Login

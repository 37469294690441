import React from 'react'

import ChooseInspection from 'ui/views/ChooseInspection'

// REVIEW is this intermediate component really necessary?
const ChooseInspectionPage = () => {
  return <ChooseInspection />
}

export default ChooseInspectionPage

import React from 'react'
import { IonPage } from '@ionic/react'
import Home from 'ui/views/Home'
import useConnectionStatus from 'hooks/useConnectionStatus'
import { useRecentSubmissions } from 'hooks/submission'
import NavFooter from 'ui/components/menus/NavFooter'

const HomePage: React.FC = () => {
  const isOnline = useConnectionStatus()

  const { data: submissions } = useRecentSubmissions()

  return (
    <IonPage>
      <Home isOffline={!isOnline} submissions={submissions || []} />
      <NavFooter />
    </IonPage>
  )
}

export default HomePage

import React from 'react'
import { useLocation } from 'react-router'
import cx from 'classnames'

import {
  IonFooter,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
} from '@ionic/react'

import {
  home,
  cloudDownloadOutline,
  readerOutline,
  cloudUploadOutline,
  cog,
} from 'ionicons/icons'

import useTranslation from 'hooks/useTranslation'

import './NavFooter.css'

interface NavFooterProps {}

const NavFooter: React.FC<NavFooterProps> = () => {
  const t = useTranslation()
  const location = useLocation()

  const isActive = (path: string) => location.pathname.includes(path)

  const navButtons = [
    { path: '/home', icon: home, label: t('menu.home') },
    {
      path: '/import/assets',
      icon: cloudDownloadOutline,
      label: t('menu.import'),
    },
    { path: '/take-data', icon: readerOutline, label: t('menu.data') },
    { path: '/export', icon: cloudUploadOutline, label: t('menu.export') },
    { path: '/settings', icon: cog, label: t('menu.settings') },
  ]

  return (
    <IonFooter className="navFooter">
      <IonToolbar className="navFooter-toolbar">
        <IonButtons className="navFooter-buttons">
          {navButtons.map(({ path, icon, label }) => (
            <IonButton
              key={path}
              className={cx('navFooter-button', { isActive: isActive(path) })}
              fill="clear"
              routerLink={path}
              type="button"
            >
              <div className="navFooter-button-inner">
                <IonIcon className="navFooter-icon" icon={icon} />
                <IonLabel className="navFooter-label">{label}</IonLabel>
              </div>
            </IonButton>
          ))}
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  )
}

export default NavFooter

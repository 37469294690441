import React, { useCallback, useEffect } from 'react'
import { IonText, IonToggle } from '@ionic/react'
import FormField from '../forms/FormField'
import { FieldProps } from './common'
import useTranslation from 'hooks/useTranslation'

export interface BooleanFieldProps extends FieldProps {
  value?: boolean
  defaultValue?: boolean
}

const BooleanField: React.FC<BooleanFieldProps> = ({
  readOnly,
  label,
  name,
  hint,
  errorText,
  hasError,
  isRequired,
  onChange,
  value,
  tabIndex,
  fileId,
  defaultValue,
}) => {
  const t = useTranslation()
  const handleChange = useCallback(
    (e: CustomEvent) => {
      if (e.detail && e.detail.checked != null) {
        onChange(name, e.detail.checked)
      }
    },
    [onChange, name],
  )

  useEffect(() => {
    if (value == null && defaultValue != null) {
      onChange(name, defaultValue)
    }
  }, [defaultValue, handleChange, name, onChange, value])

  return (
    <FormField
      readOnly={readOnly}
      label={label}
      hint={hint}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      tabIndex={tabIndex}
      fileId={fileId}
      isLabelInline
      labelPosition="fixed"
      classnames="boolean-field"
    >
      <IonToggle
        slot="end"
        name={name}
        checked={value}
        onIonChange={handleChange}
      />
      {value == null ? (
        <IonText color="medium">{t('withoutValue')}</IonText>
      ) : null}
    </FormField>
  )
}

export default BooleanField

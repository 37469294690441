import { SubmissionType, Submission } from 'core/Submission'

import { TranslateFunction } from 'hooks/useTranslation'

interface SubmissionsByType {
  [index: string]: Submission[]
}

const getAssetRemoveAlertDescription = (
  submissionsByType: SubmissionsByType,
  t: TranslateFunction,
) => {
  const inspectionsCount =
    submissionsByType[SubmissionType.NewInspection]?.length ?? 0
  const interventionsCount =
    submissionsByType[SubmissionType.NewIntervention]?.length ?? 0

  const inspectionsPart =
    inspectionsCount > 0
      ? t('confirmation.inspectionsPart', { inspectionsCount })
      : ''

  const interventionsPart =
    interventionsCount > 0
      ? t('confirmation.interventionsPart', {
          interventionsCount,
          inspectionsCount,
        })
      : ''

  const conjunction =
    inspectionsCount > 0 && interventionsCount > 0
      ? t('confirmation.conjunction')
      : ''

  const removeAlertDescription = t(
    'confirmation.deleteInventoryWithInspections',
    {
      totalCount: inspectionsCount + interventionsCount,
      inspectionsPart,
      conjunction,
      interventionsPart,
    },
  )

  return removeAlertDescription
}

export default getAssetRemoveAlertDescription

import { ModelTypes } from './ContentModel'
import { LinkChildren } from './LinkChildren'
import { GeoLocation } from './Geolocation'
import generateId from 'common/generateId'
import throwRequired from 'common/throwRequired'
import { AssetType } from 'core/Assets'

// NOTE `new` and `duplicated` statuses make reference to how the submission was
// created (i.e. by duplicating a remote asset or inspection, or by creating one
// from scratch)
export enum SubmissionStatus {
  // NOTE `SubmissionStatus.new` is not actually used anywhere in the app,
  // because when we create new submissions we are displaying a form to the user
  // and the submission is not kept until saved by the user, at which point its
  // status will be `draft`
  new = 'new',
  duplicated = 'duplicated',
  draft = 'draft',
  finished = 'finished',
  uploaded = 'uploaded',
}

export enum SubmissionType {
  /**
   * A new asset created locally from scratch
   */
  NewAsset = 'newAsset',
  /**
   * A local revision of a remote asset
   */
  AssetRevision = 'assetRevision',
  /**
   * A new inspection based on an asset
   * (which may have a locaal revision)
   */
  NewInspection = 'newInspection',
  /**
   * A new inspection based on an existing,
   * downloaded inspection. NOT USED
   */
  InspectionRevision = 'inspectionRevision',
  /**
   * A new intervention based on an asset
   * (which may have a locaal revision)
   */
  NewIntervention = 'newIntervention',
}

export enum SubmissionSourceType {
  Remote = 'remote',
  Local = 'local',
}

/**
 * A generic object that holds form data
 * either for new assets or for asset inspections
 * or for any future use
 */
export interface Submission {
  id: string
  type: SubmissionType
  /**
   * content (asset/inspection/...) model id
   */
  modelId: string
  /**
   * When this submission is based on something existing
   * like an asset or previous inspection, its id will be stored here
   */
  sourceId?: string
  sourceModelId?: string
  inventoryId?: string
  /**
   * 'local' source type indicates a submission on the device.
   * 'remote' source type indicates a downloaded asset/inspection
   */
  sourceType?: SubmissionSourceType
  /**
   * Keep the location obtained via GPS
   */
  location?: GeoLocation
  /**
   * "draft" submissions can still be modified and deleted
   * "finished" submission can only be uploaded or deleted
   * "uploaded" submissions can only be deleted
   */
  status: SubmissionStatus
  /**
   * Form data values. For a linear/group model without links
   * this will have the key/values for filled in fields.
   * PENDING: how to store nested "forms" generated by Link fields
   */
  data: {
    [fieldName: string]: any
  }
  modelType: ModelTypes
  /**
   * A list of reference to offline-stored files to be
   * submitted with this submission
   */
  files: SubmissionFile[]

  children: LinkChildren

  /**
   * user id
   */
  createdBy?: string
  createdAt?: Date
  lastUpdatedAt?: Date
  meanings?: any

  category?: AssetType

  label?: string
}

export interface SubmissionFile {
  /**
   * Local IDB id
   */
  id: string
  /**
   * Original file name
   */
  name: string
  /**
   * Content-type from chosen File
   */
  contentType: string
  /**
   * Size in bytes from chosen File
   */
  size: number
  /**
   * Upload status for each specific file
   */
  status: 'pending' | 'error' | 'ok'
  /**
   *
   */
  file: File
  /**
   * Path to location inside children or data submission
   */
  dataPath: string[]
}

export function createSubmissionFile(
  attributes: Partial<SubmissionFile>,
): SubmissionFile {
  const {
    id = generateId(),
    name = throwRequired('SubmissionFile', 'name'),
    contentType = throwRequired('SubmissionFile', 'contentType'),
    size = throwRequired('SubmissionFile', 'size'),
    status = 'pending',
    file = throwRequired('SubmissionFile', 'file'),
    dataPath = throwRequired('SubmissionFile', 'dataPath'),
  } = attributes

  return {
    id,
    name,
    contentType,
    size,
    status,
    file,
    dataPath,
  }
}

export function submissionTypeToOrder(type: SubmissionType) {
  switch (type) {
    case SubmissionType.NewAsset:
      return 0
    case SubmissionType.AssetRevision:
      return 1
    case SubmissionType.NewInspection:
      return 2
    case SubmissionType.NewIntervention:
      return 3
    default:
      return 4
  }
}

import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  IonHeader,
  IonList,
  IonItemDivider,
  IonLabel,
  IonRouterLink,
} from '@ionic/react'

import useTranslations from '../../../hooks/useTranslation'
import { AppTheme } from 'core/Theme'
import { Submission } from 'core/Submission'
import AppContent from 'ui/layouts/AppContent'
import OfflineMessage from 'ui/layouts/headers/OfflineMessage'
import RoundButton from 'ui/components/buttons/RoundButton'
import { EmptyItem } from '../../components/loaders/EmptyItem'
import {
  cloudDownloadOutline,
  cloudUploadOutline,
  readerOutline,
} from 'ionicons/icons'

import './Home.css'
import AppLogo from 'ui/components/images/AppLogo'
import HomeItem from 'components/HomeItem'

import useHomeInfo, { HomeInfoModeTypes } from './useHomeInfo'

import InstallAppAlert from './InstallAppAlert'

interface HomeProps {
  isOffline?: boolean
  submissions: Submission[]
}

const Home: React.FC<HomeProps> = ({ isOffline = false, submissions }) => {
  const { push } = useHistory()
  const t = useTranslations()

  const { mode: infoMode, content: infoContent } = useHomeInfo()

  const handleClickAsset = useCallback(
    (assetId: string) => {
      push('/take-data/in-progress', { assetId })
    },
    [push],
  )

  return (
    <>
      {isOffline ? (
        <IonHeader>
          <OfflineMessage />
        </IonHeader>
      ) : null}
      <AppContent>
        <div className="home-wrapper">
          <div className="home-fixed-content">
            <div className="logo ion-margin-vertical ion-padding-horizontal">
              <AppLogo />
            </div>
            <h1 className="home-title">{AppTheme.appTitle}</h1>

            <div className="home-action-buttons">
              <RoundButton
                disabled={isOffline}
                mainText={t('menu.import')}
                fill="solid"
                icon={cloudDownloadOutline}
                routerLink="/import/assets"
              />
              <RoundButton
                mainText={t('menu.data')}
                fill="solid"
                icon={readerOutline}
                routerLink="/take-data"
              />
              <RoundButton
                disabled={isOffline}
                mainText={t('menu.export')}
                fill="solid"
                icon={cloudUploadOutline}
                routerLink="/export"
              />
            </div>
          </div>

          <div className="home-list">
            <IonList lines="full" style={{ padding: 0 }}>
              <IonItemDivider
                className="home-list-divider"
                color="light"
                sticky
              >
                <IonLabel>{t(infoContent.dividerTitle)}</IonLabel>
              </IonItemDivider>

              <div className="home-empty">
                {infoMode === HomeInfoModeTypes.data &&
                submissions.length > 0 ? (
                  submissions.map((submission) => {
                    return (
                      <HomeItem
                        key={submission.id}
                        submission={submission}
                        onSelect={() =>
                          handleClickAsset(submission.sourceId || submission.id)
                        }
                      />
                    )
                  })
                ) : (
                  <EmptyItem
                    icon={infoContent.icon}
                    text={t(infoContent.title)}
                    secondaryText={t(infoContent.description, {
                      link: (chunk: string) => (
                        <IonRouterLink
                          key={chunk}
                          routerLink={infoContent.linkTo}
                        >
                          {chunk}
                        </IonRouterLink>
                      ),
                    })}
                  />
                )}
              </div>
            </IonList>
          </div>
        </div>
        <InstallAppAlert />
      </AppContent>
    </>
  )
}

export default Home

import React, { useRef } from 'react'
import { IonButtons, IonButton, IonToolbar } from '@ionic/react'
import Popover from 'ui/components/modals/Popover'
import NumericInput from 'ui/components/fields/NumericInput'
import useTranslation from 'hooks/useTranslation'

export interface GpsInputProps {
  isOpen: boolean
  gpsTimeout?: number
  onDidDismiss?: () => void
  onAccept: (value: number | undefined) => void
}

const GpsInput: React.FC<GpsInputProps> = ({
  isOpen,
  gpsTimeout = 5,
  onDidDismiss,
  onAccept,
}) => {
  const timeout = useRef<number>(gpsTimeout)
  const t = useTranslation()
  return (
    <Popover isOpen={isOpen} showBackdrop>
      <NumericInput
        label={t('settings.gps')}
        hint={t('settings.gpsUnit')}
        name="gps_time"
        value={timeout.current}
        hasError={!timeout}
        onChange={(name, value) => {
          timeout.current = value || gpsTimeout
        }}
      />
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton expand="block" fill="clear" onClick={onDidDismiss}>
            {t('buttons.cancelSelection')}
          </IonButton>
          <IonButton
            expand="block"
            fill="solid"
            color="primary"
            className="ion-margin-top"
            onClick={() => onAccept(timeout.current * 1000)}
          >
            {t('buttons.confirmSelection')}
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </Popover>
  )
}

export default GpsInput

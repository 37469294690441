import React from 'react'
import { IonButton, IonIcon, isPlatform } from '@ionic/react'
import { shareSocial } from 'ionicons/icons'
import cx from 'classnames'
import './TreeNavigationButton.css'

interface TreeNavigationButtonProps {
  onClick: (e: React.MouseEvent) => void
}
const TreeNavigationButton: React.FC<TreeNavigationButtonProps> = (props) => {
  const { onClick } = props
  const buttonClasses = cx({
    'ion-no-padding': true,
    'tree-navigation-trigger': true,
    'tree-navigation-trigger-ios': isPlatform('ios'),
  })

  return (
    <IonButton shape="round" className={buttonClasses} onClick={onClick}>
      <IonIcon
        slot="icon-only"
        size="small"
        className="tree-navigation-trigger-icon"
        icon={shareSocial}
      />
    </IonButton>
  )
}

export default TreeNavigationButton

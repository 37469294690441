import { Submission, SubmissionFile } from './Submission'
import generateId from 'common/generateId'
import {
  pipe,
  lensPath,
  last,
  update,
  over,
  Lens,
  view,
  assocPath,
  assoc,
  dissocPath,
  omit,
} from 'ramda'

export function duplicateSubmission(
  original: Submission,
  newIdMapper = generateNewIds,
): Submission {
  // create new id for every children keeping it in the replace map
  // newIdMapper is injected for easier testing
  const newIds = newIdMapper([original.id, ...Object.keys(original.children)])

  return pipe(
    processsParentLinkFields(newIds),
    processAncestors(newIds),
    removeFiles,
    replaceIds(newIds),
  )(Object.assign({}, original))
}

function generateNewIds(originalIds: string[]): Map<string, string> {
  return new Map(originalIds.map((id) => [id, generateId()]))
}

const processsParentLinkFields =
  (idMap: Map<string, string>) =>
  (submission: Submission): Submission => {
    let processed = { ...submission }
    for (let childId of Object.keys(submission.children)) {
      const newId = idMap.get(childId)
      const childSubmission = submission.children[childId]
      const isFirstLevelChild = childSubmission.ancestors.length === 1
      let linkValueLens: Lens
      let linkParentLens: Lens
      if (isFirstLevelChild) {
        linkValueLens = lensPath(['data', childSubmission.parentField])
        linkParentLens = lensPath(['data'])
      } else {
        const parentId = last(childSubmission.ancestors) as string
        linkValueLens = lensPath([
          'children',
          parentId,
          'data',
          childSubmission.parentField,
        ])
        linkParentLens = lensPath(['children', parentId, 'data'])
      }
      const childrenIds = view<Submission, string[]>(linkValueLens, processed)
      const hasParentField = childrenIds !== undefined
      if (hasParentField) {
        const originalIdIndex = childrenIds.indexOf(childId)
        processed = over(
          linkValueLens,
          update(originalIdIndex, newId),
          processed,
        )
      } else {
        processed = over(
          linkParentLens,
          omit([childSubmission.parentField]),
          processed,
        )
      }
    }
    return processed
  }

const processAncestors =
  (idMap: Map<string, string>) =>
  (submission: Submission): Submission => {
    return Object.keys(submission.children).reduce(
      (s: Submission, childId: string) => {
        const modifiedAncestors = s.children[childId].ancestors.map(
          (ancestorId) => idMap.get(ancestorId) as string,
        )
        return assocPath(
          ['children', childId, 'ancestors'],
          modifiedAncestors,
          s,
        )
      },
      { ...submission },
    )
  }

const replaceIds =
  (idMap: Map<string, string>) =>
  (submission: Submission): Submission => {
    submission.id = idMap.get(submission.id) as string
    for (let childId of Object.keys(submission.children)) {
      const newId = idMap.get(childId)!
      submission.children[newId] = Object.assign(submission.children[childId], {
        id: idMap.get(childId),
      })
      delete submission.children[childId]
    }
    return submission
  }

function removeFiles(submission: Submission): Submission {
  const files = [...submission.files]
  return files.reduce(
    (s: Submission, f: SubmissionFile) => {
      return dissocPath<Submission>(f.dataPath, s)
    },
    assoc('files', [], submission),
  )
}

import React from 'react'

import ImportAssetByAttributes from 'ui/views/import/ImportAssetByAttributes'

// REVIEW is this intermediate component really necessary? I have created it
// just for simmetry with other parts of the application
const ImportAssetByAttributesPage = () => {
  return <ImportAssetByAttributes />
}

export default ImportAssetByAttributesPage

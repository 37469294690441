import { APIError, UnathorizedError, NetworkError } from './constants'
import { getJWT } from 'services/user'

const commonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const makeCommonHeaders = (extraHeaders = {}) => {
  const jwt = getJWT()
  if (jwt) {
    return {
      ...commonHeaders,
      ...extraHeaders,
      Authorization: jwt,
    }
  }
  return {
    ...commonHeaders,
    ...extraHeaders,
  }
}

const makeFormDataHeaders = (extraHeaders = {}) => {
  const jwt = getJWT()
  if (jwt) {
    return {
      ...extraHeaders,
      Authorization: jwt,
    }
  }
  return {
    ...extraHeaders,
  }
}

export function get(url: string, headers = {}) {
  return fetch(url, {
    method: 'GET',
    headers: makeCommonHeaders(headers),
  }).then(responseHandler, fetchErrorHandler)
}

export function post(url: string, data?: any, headers = {}) {
  return fetch(url, {
    method: 'POST',
    headers: makeCommonHeaders(headers),
    body: data != null ? JSON.stringify(data) : undefined,
  }).then(responseHandler, fetchErrorHandler)
}

export function postFormData(url: string, data: FormData, headers = {}) {
  return fetch(url, {
    method: 'POST',
    headers: makeFormDataHeaders(),
    body: data,
  }).then(responseHandler, fetchErrorHandler)
}

export function fetchRemoteFile(url: string, signal?: AbortSignal) {
  return fetch(url, {
    method: 'GET',
    headers: makeFormDataHeaders(),
    signal,
  }).then((resp) => {
    if (resp.ok) return resp.blob()
  }, fetchErrorHandler)
  //.catch(fetchErrorHandler)
}

const responseHandler = (response: Response) => {
  if (response.status === 401) {
    throw new UnathorizedError('Forbidden')
  }
  if (!response.ok) {
    throw new APIError(response.status, response.statusText)
  }
  return response.json()
}

const fetchErrorHandler = (err: Error) => {
  throw new NetworkError(err.message)
}

import React, { useCallback, useMemo, useReducer, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { FieldProps } from './common'
import { caretDownSharp, checkmarkOutline, chevronDown } from 'ionicons/icons'
import {
  reducer,
  makeInitialState,
  CascadeSelectItem,
} from './helpers/cascadeSelectHelpers'
import { identity, indexBy, prop } from 'ramda'
import FormField from '../forms/FormField'
import useTranslation from 'hooks/useTranslation'

interface CascadeSelectProps extends FieldProps {
  ancestors: CascadeSelectItem[]
  children: CascadeSelectItem[]
  //onChange: (itemValue: string, modelId?: string) => void
  onItemSelect: (item: CascadeSelectItem) => any
  onAncestorClick: (itemValue: string) => void
  onChildClick: (itemValue: string) => void
  /**
   * Called whenever the modal is opened / closed
   */
  onModalChange?: (open: boolean) => void
  value?: string
  valueLabel?: string
}

const CascadeSelect: React.FC<CascadeSelectProps> = function (props) {
  const {
    readOnly,
    ancestors = [],
    children = [],
    onAncestorClick,
    onChildClick,
    onItemSelect,
    onModalChange = identity,
    value,
    valueLabel,
    fileId,
  } = props
  const t = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = useCallback(() => {
    onModalChange(!isOpen)
    setIsOpen(!isOpen)
  }, [isOpen, onModalChange])

  const handleLeafClick = useCallback(
    (item: CascadeSelectItem) => {
      onItemSelect(item)
      toggleModal()
    },
    [onItemSelect, toggleModal],
  )

  if (!isOpen) {
    return (
      <FormField
        readOnly={readOnly}
        label={props.label}
        hint={props.hint}
        isClickable
        onClick={toggleModal}
        fileId={fileId}
      >
        {valueLabel ? (
          <IonText>{valueLabel}</IonText>
        ) : (
          <IonText className="select-placeholder">
            {readOnly ? t('withoutValue') : t('select.placeholder')}
          </IonText>
        )}
        {!readOnly ? (
          <IonIcon icon={caretDownSharp} slot="end" className="select-icon" />
        ) : null}
      </FormField>
    )
  }
  return (
    <IonModal isOpen>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.label}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {ancestors.map((i) => (
            <IonItem
              key={i.value}
              color="light"
              lines="full"
              detail
              detailIcon={chevronDown}
              button
              onClick={() => onAncestorClick(i.value)}
            >
              <IonLabel>{i.label}</IonLabel>
            </IonItem>
          ))}
          {children.map((i) => {
            const isSelected = i.value === value
            return (
              <IonItem
                key={i.value}
                lines="full"
                button
                detail={!i.isLeaf}
                onClick={() =>
                  i.isLeaf ? handleLeafClick(i) : onChildClick(i.value)
                }
              >
                <IonLabel color={isSelected ? 'primary' : 'default'}>
                  {i.label}
                </IonLabel>
                {isSelected && (
                  <IonIcon
                    icon={checkmarkOutline}
                    color="primary"
                    size="small"
                    slot="end"
                  />
                )}
              </IonItem>
            )
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={toggleModal}>
              {t('buttons.removeSelection')}
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={toggleModal}>{t('buttons.cancel')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}
type ContainerProps = {
  items: CascadeSelectItem[]
  getNodeLabel?: (value: string) => string
  value?: string
  onChange: (item: CascadeSelectItem) => any
  fieldProps: Omit<FieldProps, 'onChange'>
}
export const CascadeSelectContainer: React.FC<ContainerProps> = (props) => {
  const {
    items = [],
    value,
    getNodeLabel = identity,
    onChange,
    fieldProps,
  } = props
  const [state, dispatch] = useReducer(
    reducer,
    { items: items, value: value },
    makeInitialState,
  )

  const leafByIds = useMemo(() => {
    return indexBy(prop('value'), items)
  }, [items])

  const handleAncestorClick = useCallback(
    (id: string) =>
      dispatch({
        type: 'AncestorClicked',
        value: id,
      }),
    [],
  )

  const handleChildClick = useCallback(
    (id: string) =>
      dispatch({
        type: 'ChildClicked',
        value: id,
      }),
    [],
  )

  const makeItemFromAncestorId: (id: string) => CascadeSelectItem = useCallback(
    (id) => {
      return {
        ancestors: [],
        label: getNodeLabel(id),
        value: id,
        modelId: 'foo',
        isLeaf: false,
      }
    },
    [getNodeLabel],
  )

  const makeItemFromChildIds = useCallback(
    (id: string, leafAncestorIds: Array<string | undefined>) => {
      const items = []
      const addNodeChild = (auxId: string) => {
        items.push({
          ancestors: [],
          label: getNodeLabel(auxId),
          value: auxId,
          modelId: auxId,
          isLeaf: false,
        })
      }

      if (leafByIds[id]) {
        items.push({
          value: id,
          modelId: leafByIds[id].modelId,
          ancestors: leafByIds[id].ancestors,
          label: leafByIds[id].label,
          isLeaf: true,
        })
        if (leafAncestorIds.includes(id)) {
          addNodeChild(id)
        }
      } else {
        addNodeChild(id)
      }
      return items
    },
    [getNodeLabel, leafByIds],
  )

  const valueLabel = useMemo(() => {
    if (!value || !leafByIds[value]) return undefined
    return leafByIds[value].ancestors
      ?.map(getNodeLabel)
      .concat(leafByIds[value].label)
      .join(' / ')
  }, [leafByIds, getNodeLabel, value])

  const handleItemSelected = useCallback(
    (item: CascadeSelectItem) => {
      dispatch({ type: 'LeaftSelected', value: item.value })
      onChange(item)
    },
    [onChange],
  )
  const leafAncestorIds = Object.values(leafByIds)
    .map((x) => x.ancestors)
    .flat()
  return (
    <CascadeSelect
      {...fieldProps}
      ancestors={state.ancestorIds.map(makeItemFromAncestorId)}
      children={state.children
        .map((x) => makeItemFromChildIds(x, leafAncestorIds))
        .flat()}
      onItemSelect={handleItemSelected}
      onAncestorClick={handleAncestorClick}
      onChildClick={handleChildClick}
      onChange={identity}
      //onChange={handleCascadeChange}
      value={value}
      valueLabel={valueLabel}
    />
  )
}

export default CascadeSelect

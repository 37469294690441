import * as API from './network/api'
import * as R from 'ramda'
import { Submission, SubmissionFile } from 'core/Submission'
import { API_URL } from './network/constants'

export enum ISubmissionStatusReponse {
  pending = 'pending',
  accepted = 'accepted',
  process_success = 'process_success',
  process_failed = 'process_failed',
}
export interface IExportSubmissionResponse {
  submissionStatus: ISubmissionStatusReponse
  success: boolean
}
//TODO: tener en cuenta para actualizar el status
export interface IExportSubmissionFileResponse {
  submissionStatus: ISubmissionStatusReponse
  pendingFileIds: String[]
}

const submissionAPI = `${API_URL}/submissions`

const nameL = R.lensProp('name')
const getFileName = R.pipe(R.view(nameL), R.split('.'), R.head)
const setFileName = (file: SubmissionFile | any, defaultName: string) =>
  R.pipe(
    R.view(nameL),
    R.split('.'),
    R.tail,
    R.prepend(defaultName),
    R.join('.'),
    (name) => R.set(nameL, name, file),
  )(file)

const makeFileURL = (submissionId: string) =>
  `${API_URL}/submissions/${submissionId}/files`

function makeExportableSubmission(submission: Submission) {
  return R.over(
    R.lensProp('files'),
    R.map(
      R.when(
        (f) => getFileName(f) === 'image',
        (f: SubmissionFile) => setFileName(f, f?.id as string),
      ),
    ),
    submission,
  )
}

export async function sendSubmission(submission: Submission) {
  return await API.post(
    submissionAPI,
    R.omit(['status'], makeExportableSubmission(submission)),
  )
}

function makeFormData(submissionFile: SubmissionFile) {
  const formData = new FormData()
  formData.append('id', submissionFile.id)
  let fileName = submissionFile.name || submissionFile.file.name

  const [name, ...rest] = fileName.split('.')
  if (name === 'image') fileName = [submissionFile.id, ...rest].join('.')

  formData.append('name', fileName)
  formData.append('file', submissionFile.file)
  return formData
}

export async function sendSubmissionFile(
  submissionId: Submission['id'],
  submissionFile: SubmissionFile,
) {
  const formData = makeFormData(submissionFile)
  const filesURL = makeFileURL(submissionId)
  return await API.postFormData(filesURL, formData)
}

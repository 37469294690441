import React, { useState, useMemo, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  isPlatform,
} from '@ionic/react'
import { mapOutline, listOutline } from 'ionicons/icons'

import AppLayout from 'ui/layouts/AppLayout'

import GoogleMaps, {
  ExtendedMarkerOptions,
} from 'ui/components/maps/GoogleMaps'
import AssetCardList from 'ui/components/lists/AssetCardList'
import LoadingOverlay from 'ui/components/loaders/LoadingOverlay'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslations from 'hooks/useTranslation'
import useModel from 'hooks/useModel'
import { useAssetView } from 'hooks/assets'
import { useDebounce } from 'hooks/useDebounce'

import { Bounds } from 'core/Geolocation'
import { getPluralTitle, getTitleField, getColor } from 'core/ContentModel'

import './ImportAssetByLocation.css'

enum Tabs {
  Map = 'map',
  List = 'list',
}

// REVIEW sometimes tabs are hard to tap, and the map is receiving the events
// instead
const ImportAssetByLocation: React.FC = () => {
  const t = useTranslations()
  const locale = useCurrentLocale()

  // model
  const { params } = useRouteMatch<{ modelId: string }>()
  const { modelId } = params

  const { data: model } = useModel(modelId)
  const titleField = getTitleField(model)
  const modelTitle = model ? getPluralTitle(locale, model) : ''
  const color = model ? getColor(model) : undefined

  // tabs
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Map)

  const handleTabChange = (e: CustomEvent) => setSelectedTab(e.detail.value)

  // bounds
  // IDEA 💡 add `useDebouncedState` hook?
  const [bounds, setBounds] = useState<Bounds>()
  const debouncedBounds = useDebounce<Bounds | undefined>(bounds, 600)

  // assets
  const { data, isIdle, isLoading } = useAssetView(
    modelId,
    { bounds: debouncedBounds },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true, // NOTE avoid returning undefined on bounds change
      enabled: Boolean(debouncedBounds),
    },
  )

  const assets = useMemo(() => {
    return (
      data?.map((asset) => ({
        id: asset.id,
        title: titleField ? asset.data[titleField] : '-',
        modifiedAt: new Date(asset.updatedAt),
      })) ?? []
    )
  }, [data, titleField])

  // NOTE query will be idle until we have some bounds (i.e. while the map is
  // loading), then it will also me loading
  const isIdleOrLoading = isIdle || isLoading

  // REVIEW this is being called too often, every time the request is performed
  // again (which is every time the user zooms in or out, or moves through the
  // map)
  // IDEA 💡 can we memoize the results of `useQuery`?
  const markers: ExtendedMarkerOptions[] = useMemo(() => {
    return (
      // REVIEW reuse `getMarkerForRemoteAsset` in `AssetMap`
      data?.map((asset) => {
        const [lng, lat] = asset.location.coordinates
        return {
          color,
          id: asset.id,
          position: { lat, lng },
          title: asset.data[titleField as string] ?? '-',
        }
      }) ?? []
    )
  }, [color, data, titleField])

  // selected asset
  const [selectedAsset, setSelectedAsset] = useState<string>()

  const handleSelectAsset = useCallback((assetId: string) => {
    setSelectedAsset(assetId)
    setSelectedTab(Tabs.List)

    // NOTE remove highlight after 3s
    const timeout = setTimeout(() => {
      setSelectedAsset(undefined)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [])

  // misc
  const isIOS = isPlatform('ios')
  const tabsHeight = isIOS ? 24 : 48

  const height = `calc(100% - ${tabsHeight}px)`

  const overayStyle = { height, top: tabsHeight }

  return (
    <AppLayout
      pageTitle={t('import.type', {
        label: modelTitle,
      })}
      isHeaderFixed={false}
    >
      <div className="import-by-location-tabs">
        <IonSegment value={selectedTab} onIonChange={handleTabChange}>
          <IonSegmentButton layout="icon-start" value={Tabs.Map}>
            <IonIcon icon={mapOutline} />
            <IonLabel>{t('import.advancedSearch.location.map')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
            layout="icon-start"
            value={Tabs.List}
            disabled={isIdleOrLoading}
          >
            <IonIcon icon={listOutline} />
            <IonLabel>{t('import.advancedSearch.location.list')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
      {isIdleOrLoading ? (
        <LoadingOverlay text={t('import.searching')} style={overayStyle} />
      ) : null}
      <div
        className="import-by-location-view"
        style={{ height }}
        data-selected={selectedTab === Tabs.Map}
      >
        <GoogleMaps
          markers={markers}
          onBoundsUpdated={setBounds}
          onInfoWindowClick={handleSelectAsset}
        />
      </div>
      <div
        className="import-by-location-view"
        data-selected={selectedTab === Tabs.List}
      >
        <AssetCardList
          modelId={modelId}
          assets={assets}
          selectedAsset={selectedAsset}
        />
      </div>
    </AppLayout>
  )
}

export default ImportAssetByLocation

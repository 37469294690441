import { Submissions } from './db'
import { Submission, SubmissionStatus, SubmissionType } from 'core/Submission'
import generateId from 'common/generateId'
import { pluck, pathSatisfies } from 'ramda'
import { FullAsset } from 'core/Assets'
import { createAssetRevisionSubmission } from 'core/mappers'
import { addAssetRevision, removeAssetRevision } from './assets'

export async function saveSubmission(submission: Submission) {
  if (!submission.id || typeof submission.id === 'number') {
    submission.id = generateId()
  }

  if (!submission.createdAt) {
    submission.createdAt = new Date()
  }

  // remove dangling files that may point to a child
  // that does not exist!
  const nonDanglingFiles = submission.files.filter((f) => {
    return pathSatisfies((x) => x != null, f.dataPath, submission)
  })

  submission.files = nonDanglingFiles
  // TODO: if submission is an asset revision, we need to
  // mark the asset as MODIFIED but not allow more editing
  // until downloaded from server

  return Submissions.put(
    {
      ...submission,
      lastUpdatedAt: new Date(),
    },
    submission.id,
  ).then((submissionId) => {
    return submissionId
  })
  /* .catch(err => {
      console.error(
        'Error saving submissions into local DB',
        err
      )
      throw err
    }) */
}

export const removeSubmission = (id: string | string[]) => {
  const idsToRemove = Array.isArray(id) ? id : [id]

  return Submissions.bulkDelete(idsToRemove).catch((err) => {
    console.error('Error removing submission from IDB for id ' + id)
    throw err
  })
}

export const removeSingleSubmission = async (id: string) => {
  const submission = await getSubmission(id)
  if (
    submission?.type === SubmissionType.AssetRevision &&
    submission.sourceId
  ) {
    await removeAssetRevision(submission.sourceId)
  }
}

export const removePendingSubmissionsBySourceId = async (sourceId: string) => {
  const submissions = await Submissions.where('status')
    .notEqual(SubmissionStatus.uploaded)
    .filter((s) => s.sourceId === sourceId)
    .toArray()
  return Submissions.bulkDelete(pluck('id', submissions))
}

export const getSubmission = (id: string) => {
  return Submissions.get(id)
}

export const getSubmissionsByType = async (type: SubmissionType) => {
  return Submissions.where('type').equals(type).toArray()
}

export async function getSubmissionByStatus(
  status: SubmissionStatus | SubmissionStatus[],
): Promise<Submission[]> {
  return Submissions.where('status')
    .anyOf(status)
    .toArray()
    .catch((err) => {
      console.error('Error fetching submissions from local DB', err)
      throw err
    })
}

export async function getSubmissions(): Promise<Submission[]> {
  return Submissions.toArray().catch((err) => {
    console.error('Error fetching submissions from local DB', err)
    throw err
  })
}

export function getPendingSubmissionsBySourceId(assetId: string) {
  return Submissions.where('sourceId')
    .equals(assetId)
    .filter((s) => s.status !== SubmissionStatus.uploaded)
    .toArray()
}

export async function makeAssetRevisionSubmission(asset: FullAsset) {
  const s = createAssetRevisionSubmission(asset)
  await saveSubmission(s)
  await addAssetRevision(asset.id, s.id)
  return s
}

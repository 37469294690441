import React, {
  ReactNode,
  Dispatch,
  useReducer,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import reducer, { FormState, INITIAL_STATE } from './reducer'
import { FormAction } from './actions'
import { ModelSummary } from 'core/ModelSummary'
import useModelSummaries from 'hooks/useModelSummaries'
import { propOr } from 'ramda'

const StateContext = React.createContext<FormState>(INITIAL_STATE)
const DispatchContext = React.createContext<Dispatch<FormAction>>(() => {})

export type ModelSummaryContextDef = {
  getModelSummary: (modelId: string) => ModelSummary
  hasModelSummary: boolean
}

const ModelSummaryContext = React.createContext<ModelSummaryContextDef>({
  getModelSummary: () => ({}) as ModelSummary,
  hasModelSummary: false,
})

export function FormContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const { data: modelSummaries } = useModelSummaries()
  const getModelSummary = useCallback(
    (modelId: string): ModelSummary => {
      return propOr({}, modelId, modelSummaries)
    },
    [modelSummaries],
  )
  const hasModelSummary = useMemo(
    () => Boolean(modelSummaries && Object.keys(modelSummaries).length),
    [modelSummaries],
  )

  /* process.env.NODE_ENV === 'development' &&
    console.log('FormState', state) */
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <ModelSummaryContext.Provider
          value={{
            getModelSummary,
            hasModelSummary,
          }}
        >
          {children}
        </ModelSummaryContext.Provider>
      </StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export const useFormState = () => useContext(StateContext)
export const useDispatchContext = () => useContext(DispatchContext)

export const useModelSummary = () => useContext(ModelSummaryContext)

export default FormContextProvider

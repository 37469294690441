import { queryCache } from 'react-query'
import { saveSubmission as saveSubmissionDB } from 'services/submissions'
import { Submission, SubmissionType } from 'core/Submission'
import useToast from 'hooks/useToast'
import useTranslation from 'hooks/useTranslation'
import { CacheKeys } from './common'

const save = async (submission: Submission) => {
  return saveSubmissionDB(submission).then(() => submission)
}

export default function useSaveSubmission() {
  const Toast = useToast()
  const t = useTranslation()

  const saveSubmission = async (submission: Submission) => {
    await save(submission)
      .then((submission) => {
        queryCache.invalidateQueries([CacheKeys.SubmissionByStatus])
        queryCache.invalidateQueries([CacheKeys.Submission, submission.id])
        if (submission.type === SubmissionType.AssetRevision) {
          queryCache.invalidateQueries(['asset', submission.sourceId!])
        }
        Toast.success(t('notifications.saveSuccess'))
        return submission
      })
      .catch((err) => {
        console.log('save error', err.message)
        if (err.message.trim() === 'QuotaExceededError') {
          Toast.error(
            t('notifications.saveError') + '\nNO SPACE AVAILABLE IN DEVICE',
          )
        } else {
          Toast.error(t('notifications.saveError'))
        }
        throw err
      })
  }

  return {
    saveSubmission,
    //isFetching: isFetchingSave
  }
}

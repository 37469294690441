import { create } from 'zustand'

import { useAssetModels } from 'hooks/useModelList'

import { ContentModel } from 'core/ContentModel'

interface ModelFilterStore {
  selectedModels: ContentModel[]
  setSelectedModels: (selectedModels: ContentModel[]) => void
}

const useModelFilterStore = create<ModelFilterStore>((set) => ({
  selectedModels: [],
  setSelectedModels: (selectedModels) => set({ selectedModels }),
}))

const useModelFilter = () => {
  const models = useAssetModels()

  const { selectedModels, setSelectedModels } = useModelFilterStore(
    (state) => state,
  )

  return {
    isFilterApplied:
      selectedModels.length > 0 && selectedModels.length < models.length,
    selectedModels: selectedModels.length > 0 ? selectedModels : models,
    setSelectedModels,
  }
}

export default useModelFilter

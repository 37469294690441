import React from 'react'
import { Controller } from 'react-hook-form'
import { IonItem, IonInput } from '@ionic/react'

import { TextField } from 'core/ContentField'

interface TextFilterFieldProps {
  fieldModel: TextField
}

const TextFilterField: React.FC<TextFilterFieldProps> = (props) => {
  const { fieldModel } = props
  const { name } = fieldModel

  return (
    <IonItem>
      <Controller
        name={name}
        render={({ field }) => (
          <IonInput
            type="text"
            clearInput
            value={field.value}
            onIonChange={field.onChange}
            onIonBlur={field.onBlur}
          />
        )}
      />
    </IonItem>
  )
}

export default TextFilterField

// Wraps presentational Select with items
// from local db
import React from 'react'
import Select, { SelectProps } from './Select'
import useListItems from 'hooks/useListItems'
import FormError from 'ui/components/forms/FormError'

interface SelectContainerProps extends SelectProps {
  listName: string
}

export default function SelectContainer(props: SelectContainerProps) {
  const { listName, ...selectProps } = props
  const { data: items, error } = useListItems(listName)
  if (error) return <FormError text="Error al cargar datos de lista" />
  return <Select {...selectProps} options={items} />
}

import { useMemo } from 'react'
import { groupBy, prop } from 'ramda'

import { useAssets } from 'hooks/assets'
import useLocalAssets from 'hooks/useLocalAssets'

const useAssetsByModel = () => {
  const remoteAssets = useAssets()

  const localAssets = useLocalAssets()

  const remoteAssetsByModel = useMemo(
    () => groupBy(prop('assetType'), remoteAssets),
    [remoteAssets],
  )

  const localAssetsByModel = useMemo(
    () => groupBy(prop('modelId'), localAssets),
    [localAssets],
  )

  return {
    remoteAssetsByModel,
    localAssetsByModel,
  }
}

export default useAssetsByModel

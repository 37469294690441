import useAuthenticatedQuery from 'hooks/useAuthenticatedQuery'

import { searchAssets } from 'services/assets'

type SearchInput = {
  modelId: string
  keyword: string
}

const search = async ({ modelId, keyword }: SearchInput) => {
  const result = await searchAssets(keyword, modelId)
  if (!result.success && result.error) {
    throw new Error(result.error)
  }
  return result.data.map((item) => ({
    id: item.id,
    title: item.title,
    lastUpdated: new Date(item.lastUpdated),
  }))
}

export default function useSearchAssets(
  modelId: string,
  keyword: string,
  options = {},
) {
  return useAuthenticatedQuery(
    ['searchAssets', { modelId, keyword }],
    (_key, variables: SearchInput) => {
      const { modelId, keyword } = variables
      return search({
        modelId,
        keyword,
      })
    },
    {
      enabled: keyword.trim().length > 2,
      ...options,
    },
  )
}

import React, { useRef, useState, useCallback } from 'react'
import {
  IonModal,
  IonHeader,
  IonContent,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonCheckbox,
  isPlatform,
} from '@ionic/react'
import { close, filter } from 'ionicons/icons'
import cx from 'classnames'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import { useAssetModels } from 'hooks/useModelList'

import { getTitle } from 'core/ContentModel'

import useModelFilter from './useModelFilter'

import './Filter.css'

const MODAL_TRIGGER_ID = 'filter-modal'
const FORM_ID = 'filter-modal-form'

const Filter: React.FC = () => {
  const { isFilterApplied, selectedModels, setSelectedModels } =
    useModelFilter()

  const locale = useCurrentLocale()
  const t = useTranslation()

  const models = useAssetModels()

  const modalRef = useRef<HTMLIonModalElement>(null)

  const handleClose = useCallback(() => {
    if (!modalRef.current) return

    modalRef.current.dismiss()
  }, [])

  // NOTE checkboxes do not react to `reset` events in the parent form element,
  // so we need to use controlled version
  const [selection, setSelection] = useState<Record<string, boolean>>(
    selectedModels.reduce(
      (acc, model) => {
        acc[model.id] = true
        return acc
      },
      {} as Record<string, boolean>,
    ),
  )

  const handleWillPresent = useCallback(() => {
    // NOTE if all models selected, leave all checkboxes unchecked
    if (selectedModels.length === models.length) {
      setSelection({})
    } else {
      setSelection(
        selectedModels.reduce(
          (acc, model) => {
            acc[model.id] = true
            return acc
          },
          {} as Record<string, boolean>,
        ),
      )
    }
  }, [models, selectedModels, setSelection])

  const handleChange = useCallback(
    (modelId: string, checked: boolean) => {
      setSelection({ ...selection, [modelId]: checked })
    },
    [selection],
  )

  const handleSubmit = useCallback(
    (ev: React.FormEvent) => {
      ev.preventDefault()

      const selectedModels = models.filter((model) => selection[model.id])
      setSelectedModels(selectedModels)

      handleClose()
    },
    [models, selection, setSelectedModels, handleClose],
  )

  const handleReset = useCallback(() => {
    setSelection({})
  }, [])

  // NOTE this component is used in different views and reusing the same id for
  // the trigger causes the modal to not show up after navigating from one page
  // to the other
  const ID = MODAL_TRIGGER_ID + Math.random()

  return (
    <>
      <IonButton id={ID} size="small" fill="outline" className="ion-no-margin">
        {t('filter')}
        <IonIcon slot="end" icon={filter} />
        {isFilterApplied ? <div className="takeData-applied-icon"></div> : null}
      </IonButton>
      <IonModal ref={modalRef} trigger={ID} onWillPresent={handleWillPresent}>
        <IonHeader
          className={cx('takeData-modal-header', {
            'takeData-modal-header-ios': isPlatform('ios'),
          })}
        >
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                slot="start"
                fill="clear"
                className="ion-margin-start"
                onClick={handleClose}
              >
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>{t('filter')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding-top ion-padding-bottom">
          <form id={FORM_ID} onSubmit={handleSubmit} onReset={handleReset}>
            <IonList lines="none">
              {models.map((model) => (
                <IonItem key={model.id} className="filter-modal-item">
                  <IonCheckbox
                    labelPlacement="end"
                    justify="start"
                    checked={selection[model.id]}
                    onIonChange={(ev) =>
                      handleChange(model.id, ev.detail.checked)
                    }
                  >
                    {getTitle(locale, model)}
                  </IonCheckbox>
                </IonItem>
              ))}
            </IonList>
          </form>
        </IonContent>
        <IonFooter>
          <IonToolbar className="filter-modal-footer-toolbar">
            <IonButton slot="start" fill="outline" form={FORM_ID} type="reset">
              {t('action.removeFilters')}
            </IonButton>
            <IonButton slot="end" form={FORM_ID} type="submit">
              {t('action.viewResults')}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}

export default Filter

import { useCurrentLocale } from 'context/LocaleContext'
import useModel from 'hooks/useModel'
import { useMemo } from 'react'
import { extractTitle } from 'core/ContentModel'
import { Submission } from 'core/Submission'
import { getLocalizedProperty } from 'core/common'

export default function useInventorySubmission(submission: Submission) {
  const currentLocale = useCurrentLocale()
  const { data: model, isLoading, isError } = useModel(submission.modelId)

  const modelTitle = useMemo(
    () => (model ? getLocalizedProperty(currentLocale, 'label', model) : ''),
    [model, currentLocale],
  )

  const title = useMemo(
    () => extractTitle(model, submission),
    [model, submission],
  )

  return {
    model,
    title,
    modelTitle,
    isLoading,
    isError,
  }
}

import React from 'react'
import { IonChip } from '@ionic/react'
import cx from 'classnames'

import './ExportTabChip.css'

export interface FormProps {
  value: string
  selectedValue: string
  label: string
  onClick: (value: string) => void
}

const ExportTabChip: React.FC<FormProps> = (props) => {
  const { value, selectedValue, label, onClick } = props

  return (
    <IonChip
      className={cx({
        'export-tab-chip-selected': value === selectedValue,
      })}
      onClick={() => onClick(value)}
      color="primary"
      outline
    >
      {label}
    </IonChip>
  )
}

export default ExportTabChip

import React from 'react'
import { IonList } from '@ionic/react'
import Popover from 'ui/components/modals/Popover'
import AssetNav from './AssetNav'
import TreeNavigationItem, { TreeItemTypes } from './TreeNavigationItem'
import 'ui/components/modals/Popover.css'

export interface TreeItem {
  name: string
  label: string
  type: TreeItemTypes
  childrenNumber?: string
  onClick: Function
}

export interface TreeNavigationProps {
  isOpen: boolean
  event?: Event
  treeItems: TreeItem[]
  onDidDismiss: () => void
}

const TreeNavigation: React.FC<TreeNavigationProps> = (props) => {
  const { isOpen, event, treeItems, onDidDismiss } = props
  return (
    <Popover event={event} isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <IonList>
        <AssetNav>
          {treeItems?.map((treeItem) => (
            <TreeNavigationItem
              type={treeItem.type}
              key={treeItem.name}
              title={treeItem.label}
              childrenNumber={treeItem.childrenNumber}
              onClick={() => {
                treeItem.onClick()
                onDidDismiss()
              }}
            />
          ))}
        </AssetNav>
      </IonList>
    </Popover>
  )
}

export default TreeNavigation

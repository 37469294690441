import React from 'react'

import Help from 'ui/views/Help'

// REVIEW is this intermediate component really necessary?
const HelpPage = () => {
  return <Help />
}

export default HelpPage

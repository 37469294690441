import { useContext, useState, useCallback } from 'react'
import UserContext from 'context/UserContext'
import useToast from 'hooks/useToast'
import { UnathorizedError } from '../services/network/constants'
import { fetchAppConfig, saveConfigOnDb } from 'services/config'
import useTranslation from './useTranslation'
import { useQueryCache } from 'react-query'
import { always } from 'ramda'

interface IUseConfig {
  fetchAppConfig: (jwt?: string) => Promise<any>
  isFetchingConfig: boolean
}

export default function useConfig(): IUseConfig {
  const { jwt, clearUser } = useContext(UserContext)
  const [isFetching, setIsFetching] = useState(false)
  const Toast = useToast()
  const t = useTranslation()
  const queryCache = useQueryCache()

  const retrieveAppConfig = useCallback(
    async (overrideJwt?: string) => {
      try {
        setIsFetching(true)
        const configFromAPI = await fetchAppConfig(
          overrideJwt || jwt || undefined,
        )
        await saveConfigOnDb(configFromAPI)
        queryCache.invalidateQueries(always(true))
        setIsFetching(false)
        Toast.success(t('notifications.refreshConfigSuccess'))
      } catch (err: any) {
        setIsFetching(false)
        if (err instanceof UnathorizedError || err.message === 'Forbidden') {
          Toast.error(t('notifications.authError'))
          clearUser()
        } else {
          Toast.error(t('notifications.refreshConfigError'))
        }
      }
    },
    [Toast, clearUser, jwt, t, queryCache],
  )

  return {
    fetchAppConfig: retrieveAppConfig,
    isFetchingConfig: isFetching,
  }
}

import { last, pathOr, propOr } from 'ramda'
import { ChildSubmission, FormState, SubmissionData } from './reducer'

export const getSubmissionRootId = (state: FormState) => state.rootId

export const getHistory = (state: FormState) => state.history

export const getCurrentHistory = (state: FormState) => last(getHistory(state))

export const getSubmissionFiles = (state: FormState) => state.files

export const getCurrentGroup = (state: FormState): string =>
  propOr('', 'groupName', getCurrentHistory(state))

export const getSelectedLink = (state: FormState): string =>
  propOr('', 'selectedLink', getCurrentHistory(state))

export const getLocation = (state: FormState) => state.location

export const getRootData = (state: FormState) => state.rootData

export const getChildData = (
  childId: string,
  state: FormState,
): SubmissionData => pathOr({}, ['formChildren', childId, 'data'], state)

export const getCurrentFormData = (state: FormState): SubmissionData => {
  const currentHistory = getCurrentHistory(state)
  if (currentHistory?.childId)
    return getChildData(currentHistory.childId, state)
  return state.rootData
}

export const getCurrentFieldValue = (fieldName: string, state: FormState) => {
  const currentHistory = getCurrentHistory(state)
  if (currentHistory?.childId)
    return propOr(null, fieldName, getChildData(currentHistory.childId, state))
  return propOr(null, fieldName, state.rootData)
}

const getChild = (childId: string, state: FormState): ChildSubmission =>
  pathOr({} as ChildSubmission, ['formChildren', childId], state)

export const getCurrentSourceId = (state: FormState): string | undefined => {
  const currentHistory = getCurrentHistory(state)
  if (currentHistory?.childId)
    return getChild(currentHistory.childId, state).sourceId
  return state.sourceId
}

import { getLocalizedProperty } from 'core/common'
import { ContentModel } from 'core/ContentModel'
import { ModelSummary } from 'core/ModelSummary'
import { dropLast, pipe, map, equals, take } from 'ramda'
import { TreeItem } from 'ui/components/menus/TreeNavigation'
import { TreeItemTypes } from 'ui/components/menus/TreeNavigationItem'
import { IHistory } from './models'
import { ChildSubmission, SubmissionData } from './reducer'

/**
 * WIP: using the form state history we should be able
 * to construct a path (breadcrumbs) from the current
 * visible "form" to its root, and down to its existing children.
 *
 * For example, if we navigate in the following way:
 * Puente -> Componentes -> Componente 1 -> Tableros -> Tablero 1
 *
 * We should be able to extract a list like:
 * Tableros (n) < Componente 1 < Componentes (1) < Puente
 *
 * Extracting descendants is only done using the current data and its models.
 * This is already working in the app. See "hooks/useSubmissionTreeNavigation.ts"
 *
 *
 */

export function getAncestors(
  history: IHistory[],
  onItemClick: Function,
): TreeItem[] {
  const fn = pipe(
    (h: IHistory[]) => dropLast(1, h),
    map<IHistory, TreeItem>((h) => {
      return {
        label: h.selectedLink || h.childId,
        name: h.childId,
        onClick: onItemClick,
        type: h.selectedLink ? TreeItemTypes.parentLink : TreeItemTypes.parent,
      } as TreeItem
    }),
  )
  return fn(history)
}

export function getDescendants(
  formId: string,
  model: ContentModel,
  formData: SubmissionData,
  childForms: { [id: string]: ChildSubmission },
): TreeItem[] {
  return []
}

function makeParentLinkItem(
  currentAncestors: string[],
  parentField: string,
  showIndex: boolean,
  childIndex: number | null,
  parentId: string,
  parentModel: ModelSummary,
  locale: string,
  onClick: (
    modelId: string,
    parentId: string,
    linkName?: string,
    showIndex?: boolean,
    childIndex?: number | null,
  ) => void,
  childForms: ChildSubmission[],
): TreeItem | null {
  let sameLevel = Object.values(childForms).filter(
    (child) =>
      equals(child.ancestors, currentAncestors) &&
      child.parentField === parentField,
  )
  return {
    name: parentField,
    label: getLocalizedProperty(locale, parentField, parentModel),
    type: TreeItemTypes.parentLink,
    childrenNumber: sameLevel.length > 1 ? `${sameLevel.length}` : undefined,
    onClick: () =>
      onClick(parentModel.id, parentId, parentField, showIndex, childIndex),
  }
}

function makeParentItem(
  childId: string,
  fieldName: string,
  showIndex: boolean,
  childIndex: number | null,
  model: ModelSummary,
  childModel: ModelSummary,
  onClick: (
    modelId: string,
    parentId: string,
    linkName?: string | null,
    showIndex?: boolean,
    childIndex?: number | null,
  ) => void,
  locale: string,
): TreeItem {
  let label = getLocalizedProperty(locale, 'label', childModel)

  return {
    name: childId,
    label:
      showIndex && childIndex != null
        ? label + ' ' + String(childIndex + 1)
        : label,
    type: TreeItemTypes.parent,
    onClick: () => onClick(childModel.id, childId, null, showIndex, childIndex),
  }
}

function makeCurrentItem(
  selectedId: string,
  model: ModelSummary,
  index: number | null,
  locale: string,
): TreeItem {
  const label = getLocalizedProperty(locale, 'label', model)
  return {
    name: selectedId,
    label: index != null ? label + ' ' + String(index + 1) : label,
    type: TreeItemTypes.form,
    onClick: () => {},
  }
}

export function makeLinkPath(
  selectedId: string,
  rootId: string,
  rootModelId: string,
  formData: SubmissionData,
  childForms: { [id: string]: ChildSubmission },
  onItemClick: (
    modelId: string,
    parentId: string,
    linkName?: string | null,
    showIndex?: boolean,
    index?: number | null,
  ) => void,
  getModelSummaryById: (modelId: string) => ModelSummary,
  locale: string,
): TreeItem[] {
  const paths = [] as TreeItem[]
  if (selectedId === rootId) return paths
  let selectedChild = childForms[selectedId]

  const getFormData = (id: string) =>
    id === rootId ? formData : childForms[id].data
  const getIdByndex = (index: number) =>
    index + 1 < selectedChild.ancestors.length
      ? selectedChild.ancestors[index + 1]
      : selectedId
  const getChildModel = (id: string) =>
    getModelSummaryById(childForms[id].modelId)
  const getLinkFieldName = (id: string) => childForms[id].parentField

  const getCurrentPath = (index: number) =>
    take(index + 1, selectedChild.ancestors)
  let index = 0

  let model = getModelSummaryById(rootModelId)
  for (let currentId of selectedChild.ancestors) {
    const childId = getIdByndex(index)
    const childModel = getChildModel(childId)
    const fieldName = getLinkFieldName(childId)
    let data = getFormData(currentId)
    let fieldData = data[fieldName] as string[]
    let childIndex = null
    let showIndex = false
    if (Array.isArray(fieldData) && fieldData.length > 1) {
      childIndex = fieldData.indexOf(childId) as number
      showIndex = true
    }

    const link = makeParentLinkItem(
      getCurrentPath(index),
      fieldName,
      showIndex,
      childIndex,
      currentId,
      model,
      locale,
      onItemClick,
      Object.values(childForms),
    )
    if (link) paths.push(link)

    if (childId !== selectedId) {
      const formLink = makeParentItem(
        childId,
        fieldName,
        showIndex,
        childIndex,
        model,
        childModel,
        onItemClick,
        locale,
      )
      paths.push(formLink)
    } else {
      paths.push(makeCurrentItem(childId, childModel, childIndex, locale))
    }

    model = childModel
    index++
  }

  return paths
}

import { useMemo } from 'react'

import { Submission } from 'core/Submission'

import { SortOptions } from 'ui/components/order/Order'

const parseDate = (dateString?: string | Date): number => {
  if (dateString instanceof Date) {
    return dateString.getTime()
  }
  return new Date(dateString ?? '').getTime()
}

const useOrderSubmissions = (
  submissions: Submission[],
  sortOptions: SortOptions,
) => {
  const sortedSubmissions = useMemo(() => {
    let sortedSubmissions = submissions

    if (sortOptions.date.enabled) {
      sortedSubmissions = sortedSubmissions.sort((a, b) => {
        const dateA = a.lastUpdatedAt
        const dateB = b.lastUpdatedAt

        const timestampA = parseDate(dateA)
        const timestampB = parseDate(dateB)

        if (sortOptions.date.direction === 'asc') {
          return timestampA - timestampB
        } else {
          return timestampB - timestampA
        }
      })
    }

    return sortedSubmissions
  }, [sortOptions.date.direction, sortOptions.date.enabled, submissions])

  return { sortedSubmissions }
}

export default useOrderSubmissions

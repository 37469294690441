import React from 'react'
import { IonPage } from '@ionic/react'

import AppHeader from 'ui/layouts/headers/AppHeader'
import AppContent from 'ui/layouts/AppContent'
import NavFooter from 'ui/components/menus/NavFooter'

import useConnectionStatus from 'hooks/useConnectionStatus'

export interface AppLayoutProps {
  pageTitle: string
  pageSubtitle?: string
  hasBackground?: boolean
  showOffline?: boolean
  actions?: React.ReactNode
  onSearch?: (e: CustomEvent) => void
  onBackClick?: () => void
  footer?: React.ReactNode
  extra?: React.ReactNode
  isHeaderFixed?: boolean
  hideBackLink?: boolean
}

const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = (
  props,
) => {
  const {
    pageTitle,
    pageSubtitle,
    showOffline = false,
    actions,
    onSearch,
    onBackClick,
    hasBackground,
    children,
    footer,
    extra,
    isHeaderFixed = true,
    hideBackLink,
  } = props

  const isOnline = useConnectionStatus()

  const header = (
    <AppHeader
      isOffline={showOffline && !isOnline}
      title={pageTitle}
      subtitle={pageSubtitle}
      onSearchbarChange={onSearch}
      onBackClick={onBackClick}
      actions={actions}
      isHeaderFixed={isHeaderFixed}
      hideBackLink={hideBackLink}
    />
  )

  return (
    <IonPage>
      {isHeaderFixed ? (
        <>
          {header}
          <AppContent hasBackground={hasBackground}>{children}</AppContent>
        </>
      ) : (
        <AppContent hasBackground={hasBackground}>
          {header}
          {children}
        </AppContent>
      )}
      {extra}
      {footer ? footer : <NavFooter />}
    </IonPage>
  )
}

export default AppLayout

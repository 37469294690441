import useToast from 'hooks/useToast'
import { useMutation } from 'react-query'

import useTranslation from 'hooks/useTranslation'

import { removeAsset as removeAssetDB } from 'services/assets'
import { removePendingSubmissionsBySourceId } from 'services/submissions'
import { removeInspectionsByAssetId } from 'services/inspections'

const removeAsset = async (assetId: string) => {
  await removePendingSubmissionsBySourceId(assetId)
  await removeInspectionsByAssetId(assetId)
  return removeAssetDB(assetId)
}

export default function useRemoveAsset() {
  const Toast = useToast()
  const t = useTranslation()

  return useMutation(removeAsset, {
    onSuccess: () => {
      Toast.success(t('notifications.deleteSuccess'))
    },
    onError: (err) => {
      console.log(err)
      Toast.success(t('notifications.deleteError'))
    },
  })
}

import React from 'react'
//import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import App, { PWAProps } from './App'
import { AppTheme } from 'core/Theme'
import * as serviceWorker from './serviceWorker'
import { requestPersistentStorageIfNeeded } from 'services/permissions'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container!)
function renderApp(props: PWAProps) {
  root.render(
    <App
      updateClick={props.updateClick}
      isUpdateAvailable={props.isUpdateAvailable}
    />,
  )
}

function customize() {
  document.title = AppTheme.appTitle
  // dynamic manifest
  const link = document.createElement('link')
  link.href = `/${AppTheme.customer}/manifest.json`
  link.rel = 'manifest'
  document.head.appendChild(link)
  // favicon
  document
    .querySelectorAll<HTMLLinkElement>('link[rel=icon]')
    .forEach((link) => {
      link.href = link.href.replace('default', AppTheme.customer)
    })
  // apple touch icons
  document
    .querySelectorAll<HTMLLinkElement>('link[rel=apple-touch-icon]')
    .forEach((link) => {
      link.href = link.href.replace('default', AppTheme.customer)
    })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  updateIntervalMins: 15,

  onUpdate: (registration) => {
    /*    if (!registration.waiting) {
      return
    } */
    if (!registration || !registration.waiting) return
    if (registration && registration.waiting) {
      // Save, because this might become `null` asynchronously
      const waitingWorker = registration.waiting
      const updateClick = () => {
        requestPersistentStorageIfNeeded()
        waitingWorker.postMessage({ type: 'SKIP_WAITING' })
      }
      const isUpdateAvailable = true
      renderApp({
        updateClick,
        isUpdateAvailable,
      })
    }
  },
})

// Show spinner and reload page when updating
let refreshing: boolean
serviceWorker.registerUpdateHandler(() => {
  if (refreshing) {
    return
  }

  refreshing = true
  /* const updateClick = undefined
  const isUpdateAvailable = false

  ReactDOM.render(
    <App
      updateClick={updateClick}
      isUpdateAvailable={isUpdateAvailable}
    />,
    document.getElementById('root'),
  ) */

  window.location.reload()
})

// start app
customize()
renderApp({})

import React, { useRef, useEffect, useCallback, useState } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import useCurrentLocation from 'hooks/useCurrentLocation'
import { useCurrentLocale } from 'context/LocaleContext'
import useForceUpdate from 'hooks/useForceUpdate'

import { GeoLocation, Bounds } from 'core/Geolocation'

import { GOOGLE_MAPS_DEFAULT_ZOOM } from 'configuration'

import Error from './Error'
import FloatingButtons, { MapTypes } from './FloatingButtons'
import light from './light.json'
import { LayerTiles } from 'services/weatherData'
import CloudLayer from './CloudLayer'
import TemperatureLayer from './TemperatureLayer'
import PrecipitationLayer from './PrecipitationLayer'
import WindLayer from './WindLayer'

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

export interface ExtendedMarkerOptions extends google.maps.MarkerOptions {
  id: string
  color?: string
}

type MarkerMap = { [key: string]: google.maps.Marker }

interface MapProps {
  center: google.maps.LatLngLiteral
  zoom: number
  markers: ExtendedMarkerOptions[]
  onBoundsUpdated?: (bounds: Bounds) => void
  onInfoWindowClick: (markerId: string) => void
}

// NOTE loading already handled in parent component
const Loading: React.FC = () => null

// TODO set maximum zoom
// TODO hide closing blade in info window (through CSS) and programatically
// close it when the user clicks on the map
const GMap: React.FC<MapProps> = (props) => {
  const { center, zoom, markers, onBoundsUpdated, onInfoWindowClick } = props

  const mapRef = useRef<google.maps.Map | null>(null)

  const markersRef = useRef<MarkerMap>({})
  const infoWindowRef = useRef<google.maps.InfoWindow>(
    new google.maps.InfoWindow(),
  )
  const currentMarkerRef = useRef<string>('')
  const ref = useRef<HTMLDivElement>(null)
  const trafficLayerRef = useRef<google.maps.TrafficLayer | null>(null)
  const [activeLayer, setActiveLayer] = useState<LayerTiles | null>(null)
  const [showTraffic, setShowTraffic] = useState(false)
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.ROADMAP)

  const currentLocationMarkerRef = useRef<google.maps.Marker>(
    new google.maps.Marker({
      // NOTE show above all other markers
      zIndex: 1000,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 7,
        // NOTE default google maps color for current location
        fillColor: '#4285f4',
        fillOpacity: 1,
        strokeColor: '#fff',
        // strokeOpacity,
        strokeWeight: 2,
      },
    }),
  )
  const accuracyCircleRef = useRef<google.maps.Circle | null>(
    new google.maps.Circle({
      fillColor: '#61a0bf',
      fillOpacity: 0.4,
      //radius: errorRange,
      strokeColor: '#1bb6ff',
      strokeOpacity: 0.4,
      strokeWeight: 1,
      zIndex: 1,
    }),
  )

  const handleToggleTraffic = useCallback(() => {
    setShowTraffic((traffic) => !traffic)
  }, [])

  const handleToggleMapType = useCallback((type: MapTypes) => {
    setMapType(type)
  }, [])

  const handleShowTile = useCallback((tileName: LayerTiles) => {
    setActiveLayer((currentTile) => {
      if (currentTile === tileName) return null
      return tileName
    })
  }, [])

  const setCurrentLocation = useCallback((location: GeoLocation) => {
    const { latitude: lat, longitude: lng, accuracy } = location
    currentLocationMarkerRef.current.setPosition({ lat, lng })
    accuracyCircleRef.current?.setCenter({ lat, lng })
    accuracy && accuracyCircleRef.current?.setRadius(accuracy)
  }, [])

  const { watchLocation } = useCurrentLocation(setCurrentLocation)

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (ref.current && !mapRef.current) {
      mapRef.current = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        clickableIcons: false,
        controlSize: 24,
        // NOTE disable all controls but the terrain/satellite switch
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        panControl: false,
        scaleControl: false, // NOTE disabled by default
        rotateControl: false,
        keyboardShortcuts: false,
        // disableDefaultUI: true, // NOTE disable all controls
      })

      currentLocationMarkerRef.current.setMap(mapRef.current)
      accuracyCircleRef.current?.setMap(mapRef.current)
      watchLocation()
      //create traffic layer
      trafficLayerRef.current = new google.maps.TrafficLayer()
      if (showTraffic) {
        trafficLayerRef.current.setMap(mapRef.current)
      }
    }
  }, [center, zoom, watchLocation, showTraffic])

  useEffect(() => {
    if (trafficLayerRef.current) {
      if (showTraffic) {
        trafficLayerRef.current.setMap(mapRef.current)
      } else {
        trafficLayerRef.current.setMap(null)
      }
    }
  }, [showTraffic])

  useEffect(() => {
    if (mapRef.current) {
      if (mapType === MapTypes.LIGHT) {
        mapRef.current.setOptions({ styles: light })
        mapRef.current.setMapTypeId(google.maps.MapTypeId.ROADMAP)
      } else {
        mapRef.current.setOptions({ styles: [] })
        mapRef.current.setMapTypeId(mapType)
      }
    }
  }, [mapType])

  useEffect(() => {
    if (mapRef.current && onBoundsUpdated) {
      mapRef.current.addListener('bounds_changed', () => {
        const mapBounds = mapRef.current?.getBounds()?.toJSON()

        if (!mapBounds) return

        // NOTE construct the polygon, starting and finishing in the same point,
        // otherwise the query will not work properly
        const bounds: Bounds = [
          [mapBounds.west - 0.1, mapBounds.north + 0.1],
          [mapBounds.east + 0.1, mapBounds.north + 0.1],
          [mapBounds.east + 0.1, mapBounds.south - 0.1],
          [mapBounds.west - 0.1, mapBounds.south - 0.1],
          [mapBounds.west - 0.1, mapBounds.north + 0.1],
        ]

        onBoundsUpdated(bounds)
      })
    }
  }, [onBoundsUpdated])

  // markers
  useEffect(() => {
    if (!mapRef.current) return

    const currentMarkers = Object.keys(markersRef.current)
    const newMarkers = markers.map((marker) => marker.id)

    // NOTE hide markers no longer in view
    currentMarkers.forEach((id) => {
      if (!newMarkers.includes(id)) {
        markersRef.current[id].setMap(null)
      }
    })

    // NOTE add markers, reusing already existing ones
    markersRef.current = markers.reduce((acc: MarkerMap, marker) => {
      const { id, color, ...options } = marker

      if (acc[id]) acc[id].setMap(mapRef.current)
      else {
        acc[id] = new google.maps.Marker({
          ...options,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8.5,
            fillColor: color,
            fillOpacity: 1,
            // strokeColor,
            // strokeOpacity,
            strokeWeight: 1,
          },
          map: mapRef.current,
        })

        acc[id].addListener('click', () => {
          currentMarkerRef.current = id

          infoWindowRef.current.setContent(options.title)
          infoWindowRef.current.open({
            map: mapRef.current,
            anchor: acc[id],
            shouldFocus: false,
          })

          // NOTE force update the component to add the click listener to the
          // info window (see effect below)
          forceUpdate()
        })
      }

      return acc
    }, markersRef.current)
  }, [markers, forceUpdate])

  // NOTE remove all markers on component unmount
  useEffect(() => {
    return () => {
      Object.keys(markersRef.current).forEach((id) =>
        markersRef.current[id].setMap(null),
      )
    }
  }, [])

  // info window
  useEffect(() => {
    infoWindowRef.current.addListener('closeclick', () => {
      // NOTE force update after the info window is dismissed to trigger the
      // effect below, so that closing and opening the same info window does
      // re-attach the click handler
      forceUpdate()
    })
    // NOTE we know `forceUpdate` will never change, so rest assured
  }, [forceUpdate])

  // HACK `InfoWindow` interface does not define a `getMap` method, although it
  // is available to it, and it is the only reliable way to check whether the
  // info window is opened
  const isInfoWindowOpen = Boolean((infoWindowRef.current as any).getMap())
  const infoWindowContent = infoWindowRef.current.getContent()

  // NOTE even though `InfoWindow` exposes a `addListener` method, it does not
  // trigger `click` events, so we need to add directly to its HTML content
  useEffect(() => {
    const infoWindow = document.querySelector('[role=dialog]')

    if (infoWindow) {
      infoWindow.addEventListener('click', (ev) => {
        const target = ev.target as HTMLElement

        // NOTE skip clicks in closing blade
        if (target && target.tagName === 'BUTTON') return

        onInfoWindowClick(currentMarkerRef.current)
      })
    }
  }, [isInfoWindowOpen, infoWindowContent, onInfoWindowClick])

  return (
    <>
      <div ref={ref} style={{ height: '100%' }} />
      <CloudLayer
        map={mapRef.current}
        active={activeLayer === LayerTiles.Clouds}
      />
      <TemperatureLayer
        map={mapRef.current}
        active={activeLayer === LayerTiles.Temperature}
      />
      <PrecipitationLayer
        map={mapRef.current}
        active={activeLayer === LayerTiles.Precipitation}
      />
      <WindLayer
        map={mapRef.current}
        active={activeLayer === LayerTiles.Wind}
      />
      <FloatingButtons
        showTraffic={showTraffic}
        mapType={mapType}
        activeLayer={activeLayer}
        onToggleTraffic={handleToggleTraffic}
        onToggleMapType={handleToggleMapType}
        onShowTile={handleShowTile}
      />
    </>
  )
}

const render = (status: Status) => {
  if (status === Status.FAILURE) return <Error />

  return <Loading />
}

interface MapWrapperProps {
  onLoad?: () => void
  markers: ExtendedMarkerOptions[]
  onBoundsUpdated?: (bounds: Bounds) => void
  onInfoWindowClick: (markerId: string) => void
}

// TODO handle loading states and errors for both @googlemaps/react-wrapper and
// useCurrentLocation
const MapWrapper: React.FC<MapWrapperProps> = (props) => {
  const { onLoad, ...rest } = props

  const locale = useCurrentLocale()
  const [language, region] = locale.split('-')

  const { location, isFetching, error, getCurrentLocation } =
    useCurrentLocation()

  const handleLoad = useCallback(
    (status: Status) => {
      if (status === Status.SUCCESS && onLoad) {
        onLoad()
      }
    },
    [onLoad],
  )

  useEffect(() => getCurrentLocation(), [getCurrentLocation])

  if (isFetching) return <Loading />
  if (error) return <Error />

  if (location) {
    return (
      <Wrapper
        apiKey={API_KEY}
        render={render}
        language={language}
        region={region}
        callback={handleLoad}
      >
        <GMap
          zoom={GOOGLE_MAPS_DEFAULT_ZOOM}
          center={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          {...rest}
        />
      </Wrapper>
    )
  }

  // NOTE this will be reached on first render, before the effect that requests
  // the current location is performed
  return null
}

export default MapWrapper

import bytes from 'bytes'
import { checkPersistentStorage } from './permissions'

export type PWATestResults = {
  diskSpace: {
    persistent: boolean
    storageEstimation: boolean
    availableText: string
    available: number
    usedText: string
    used: number
  }
  serviceWorker: boolean
  geolocation: boolean
  indexedDB: boolean
  cacheAPI: boolean
  createObjectURL: boolean
}
const ONE_GIGA = 1073741824

const convertDiskSpace = (n: any) =>
  Number.isFinite(n)
    ? bytes(n, {
        unit: n > ONE_GIGA ? 'GB' : 'MB',
        unitSeparator: ' ',
      })
    : '-'

export default async function runPWATests(): Promise<PWATestResults> {
  const testDiskSpace = async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { quota = 0, usage = null } = await navigator.storage.estimate()
      return {
        persistent: await checkPersistentStorage(),
        storageEstimation: true,
        available: quota,
        used: usage || 0,
        availableText: convertDiskSpace(quota),
        usedText: convertDiskSpace(usage),
      }
    }
    return {
      persistent: await checkPersistentStorage(),
      storageEstimation: false,
      availableText: 'Not available',
      usedText: 'Not available',
      used: 0,
      available: 0,
    }
  }

  return {
    diskSpace: await testDiskSpace(),
    serviceWorker: 'serviceWorker' in navigator,
    geolocation: 'geolocation' in navigator,
    indexedDB: 'indexedDB' in window,
    cacheAPI: 'caches' in window,
    createObjectURL: 'URL' in window && 'createObjectURL' in window.URL,
  }
}

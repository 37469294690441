import React from 'react'
import { IonToolbar, IonText, IonIcon } from '@ionic/react'
import { cloudOfflineOutline } from 'ionicons/icons'
import './OfflineMessage.css'

const OfflineMessage: React.FC = () => {
  return (
    <IonToolbar color="danger">
      <p className="offline-message-text">
        <IonIcon
          size="large"
          color="light"
          className="ion-margin-horizontal"
          icon={cloudOfflineOutline}
        />
        <IonText>Sin conexión</IonText>
      </p>
    </IonToolbar>
  )
}

export default OfflineMessage

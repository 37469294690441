import React from 'react'
import { Layout } from '../../../core/Layout'
import LinearLayoutEditor from './LinearLayout'
import GroupLayoutEditor from './GroupLayout'

interface EditLayoutProps {
  layout: Layout
  readOnly?: boolean
}

function EditLayout(props: EditLayoutProps) {
  switch (props.layout.type) {
    case 'linear':
      return (
        <LinearLayoutEditor
          readOnly={props.readOnly}
          fields={props.layout.fields}
        />
      )
    case 'group':
      return (
        <GroupLayoutEditor readOnly={props.readOnly} layout={props.layout} />
      )
  }
}

export default React.memo(EditLayout)

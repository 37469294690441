import Dexie, { Table } from 'dexie'
import { ContentModel, ModelTypes } from '../core/ContentModel'
import {
  Submission,
  SubmissionSourceType,
  SubmissionType,
} from '../core/Submission'
import { LayoutConfig } from '../core/Layout'
import { ListItem } from 'core/ListItem'
import { FullAsset } from 'core/Assets'
import { Inspection } from 'core/Inspections'
import { ModelSummary } from 'core/ModelSummary'

interface RemoteFile {
  id: string
  blob: Blob
}

class InesInspectorDatabase extends Dexie {
  Models: Dexie.Table<ContentModel, string>
  Layouts: Dexie.Table<LayoutConfig, string>
  ListItems: Dexie.Table<ListItem, string>
  Submissions: Dexie.Table<Submission, string>
  Files: Dexie.Table<RemoteFile, string>
  Assets: Dexie.Table<FullAsset, string>
  Inspections: Dexie.Table<Inspection, string>
  ModelSummaries: Dexie.Table<ModelSummary, string>

  constructor() {
    super('InesInspector', { autoOpen: false })

    this.version(1).stores({
      // content models
      models: '&id, modelType, sys.showInMap, *sys.usedByModels',
      // layouts (assetTypeId) is a model id
      layouts: '&id, assetTypeId',
      // all list items, indexed by listName
      listItems: '&id, listName',
      // store local submissions (either assets or inspections)
      submissions: '&id, modelId, modelType, status, sourceId',
      // local images or files, point to submissionIds
      files: '&id',
      // remote assets
      assets: '&id',
    })

    this.version(2).stores({
      assets: '&id, assetType',
    })

    this.version(3)
      .stores({
        submissions:
          '&id, modelId, modelType, status, sourceId, type, sourceType',
      })
      .upgrade((tx) => {
        // add type and sourceType to existing submissions
        tx.table('submissions')
          .toCollection()
          .modify((s: Submission) => {
            if (!s.type) {
              s.type =
                s.modelType === ModelTypes.asset
                  ? SubmissionType.NewAsset
                  : SubmissionType.NewInspection
            }
            if (!s.sourceType) {
              s.sourceType = SubmissionSourceType.Remote
            }
          })
      })
    this.version(4).stores({
      // link and file fields names by content model
      modelSummaries: '&id, modelType',
    })

    this.version(5).stores({
      files: '&id',
    })

    // NOTE add compound index to layouts table
    this.version(6).stores({
      layouts: '&id, assetTypeId, [assetTypeId+name]',
    })

    this.version(7).stores({
      inspections: '&id, assetId',
    })

    this.Models = this.table('models')
    this.Layouts = this.table('layouts')
    this.ListItems = this.table('listItems')
    this.Submissions = this.table('submissions')
    this.Files = this.table('files')
    this.Assets = this.table('assets')
    this.Inspections = this.table('inspections')
    this.ModelSummaries = this.table('modelSummaries')
  }
}

const db = new InesInspectorDatabase()

export const openDb = () => {
  return db.open().catch((err) => {})
}

export const clearConfig = async () => {
  await db.table('models').clear()
  await db.table('layouts').clear()
  await db.table('listItems').clear()
  await db.table('modelSummaries').clear()
  await db.table('files').clear()
}

export const Models: Table<ContentModel, string> = db.table('models')
export const Layouts: Table<LayoutConfig, string> = db.table('layouts')
export const ListItems: Table<ListItem, string> = db.table('listItems')
export const Submissions: Table<Submission, string> = db.Submissions
export const Files: Table<RemoteFile, string> = db.table('files')
export const Assets: Table<FullAsset, string> = db.table('assets')
export const Inspections: Table<Inspection, string> = db.Inspections
export const ModelSummaries: Table<ModelSummary, string> = db.ModelSummaries

export default db

declare global {
  interface Window {
    db?: Dexie
  }
}

/** In Development, quick way to test queries */
if (process.env.NODE_ENV === 'development') {
  window.db = db
}

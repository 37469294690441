import { always } from 'ramda'
import { useQuery } from 'react-query'
import { getIndexedModelSummaries } from 'services/modelSummaries'

export default function useModelSummaries() {
  return useQuery(
    ['modelSummaries'],
    async function () {
      return getIndexedModelSummaries().catch(always({}))
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}

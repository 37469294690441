import { get, post } from './network/api'
import { Assets, Models } from './db'
import { MinimalAsset, AssetView, FullAsset, ChildAsset } from 'core/Assets'
import { Bounds } from 'core/Geolocation'
import { API_URL } from './network/constants'
import { getSubmission } from './submissions'
import { makeFullAssetFromRevision } from 'core/mappers'

import { getTitleField } from 'core/ContentModel'
import { Filters, FieldFilter } from 'core/ContentFieldFilter'

type SearchResponse = {
  success: boolean
  data: MinimalAsset[]
  error?: string
}

export const searchAssets = (
  keyword: string,
  modelId: string,
): Promise<SearchResponse> => {
  return post(`${API_URL}/search-id`, {
    assetModelId: modelId,
    idOrTitle: keyword,
    includeTitle: true,
  })
}

type ViewAssetsQuery = {
  modelId: string
  // NOTE the API expects a polygon in GeoJSON format, which is a
  // three-dimensional array
  // SEE https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6
  currentBounds?: [Bounds]
  [param: `data.${string}`]: FieldFilter
}

const ensureData = (asset: AssetView): AssetView => {
  if (!asset.data) asset.data = {}
  return asset
}

export const viewAssets = async (
  modelId: string,
  bounds?: Bounds,
  filters?: Filters,
): Promise<AssetView[]> => {
  // NOTE prepare query
  const query: ViewAssetsQuery = { modelId }

  if (bounds) query.currentBounds = [bounds]

  if (filters) {
    Object.keys(filters).forEach((param) => {
      query[`data.${param}`] = filters[param]
    })
  }

  // NOTE find field used as title for the given model
  const model = await Models.get(modelId)
  const field = getTitleField(model)

  const assets = await post(`${API_URL}/search/assetView`, {
    query,
    projection: [
      '_id',
      'location',
      'meanings', // NOTE fallback for asset title
      `data.${field}`, // NOTE `data.undefined` will do no harm here
    ],
  })

  // NOTE normalize id field
  return assets.map(fixId).map(ensureData)
}

// Some entities have "id" others have mongo "_id"
interface ServerEntity {
  _id?: string
  id?: string
}

const fixId = (entity: ServerEntity) => {
  if (entity.id) return entity
  entity.id = entity._id
  delete entity._id
  return entity
}

interface APIChildAsset extends ChildAsset {
  assetModelId: string
}
interface APIFullAsset extends FullAsset {
  assetModelId: string
  documents?: any
  geometry?: any
  subAssets: { [id: string]: APIChildAsset }
}
const renameModelIdField = (entity: APIFullAsset): FullAsset => {
  //console.log('before', entity)
  entity.assetType = entity.assetModelId
  //clean asset
  delete entity.documents
  delete entity.geometry

  Object.keys(entity.subAssets).forEach((id) => {
    entity.subAssets[id].assetType = entity.subAssets[id].assetModelId
    entity.subAssets[id]._id = id
  })
  //  console.log('after', entity)
  return entity
}

export const fetchFullAsset = (id: string): Promise<FullAsset> => {
  return get(`${API_URL}/fullAssets/${id}`).then((a) =>
    renameModelIdField(fixId(a) as APIFullAsset),
  )
}

export const saveAsset = (asset: FullAsset) => {
  return Assets.put({
    ...asset,
    downloadedAt: new Date(),
  }).catch((err) => {
    console.error('Error saving assets from IDB')
    throw err
  })
}

export const getIsAssetSaved = (id: string) => {
  return Assets.where('id')
    .equals(id)
    .count()
    .then((n) => n === 1)
}

export const removeAsset = (id: string) => {
  return Assets.delete(id).catch((err) => {
    console.error('Error removing asset from IDB for id ' + id)
    throw err
  })
}

export const getRemoteAssets = () => {
  return Assets.toArray().catch((err) => {
    console.error('Error retrieving assets from IDB')
    throw err
  }) as Promise<FullAsset[]>
}

export const getDownloadAssetsOfModel = (modelId: string) => {
  return Assets.where('assetType').equals(modelId).toArray()
}

export async function getAsset(
  id: string | undefined,
): Promise<FullAsset | undefined> {
  if (!id) return

  return Assets.where('id')
    .equals(id)
    .first()
    .then(async (asset) => {
      if (!!asset?.revisionSubmissionId) {
        //console.log('Should load revision, merge pending')
        const revision = await getSubmission(asset.revisionSubmissionId)
        if (revision) return makeFullAssetFromRevision(revision)
      }
      return asset
    })
    .catch((err) => {
      console.error('Error fetching asset from local DB for id' + id, err)
      throw err
    })
}

export const addAssetRevision = async (
  assetId: string,
  submissionId: string,
) => {
  try {
    let asset = (await getAsset(assetId)) as FullAsset
    /*   return Assets.update(assetId, {
      revisionSubmissionId: submissionId
    })
    .then(n => {
      if (n !== 1) {
        throw new Error(
          'Error updating asset with revision'
          )
        }
        return true
      }) */
    return Assets.put({ ...asset, revisionSubmissionId: submissionId }, assetId)
  } catch (e) {
    throw new Error('Error updating asset with revision')
  }
}

export const removeAssetRevision = (assetId: string) => {
  return Assets.update(assetId, {
    revisionSubmissionId: undefined,
  }).then((n) => {
    if (n !== 1) {
      throw new Error('Error updating asset with revision')
    }
    return true
  })
}

import React, { useMemo, useCallback } from 'react'
import { IonActionSheet } from '@ionic/react'
import {
  pencilOutline,
  checkmarkCircleOutline,
  documentAttachOutline,
  documentTextOutline,
  duplicateOutline,
  locationOutline,
  trailSignOutline,
} from 'ionicons/icons'

import { useHistory } from 'react-router-dom'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import useToast from 'hooks/useToast'
import useModel from 'hooks/useModel'
import { useInspectionModels } from 'hooks/useModelList'
import useInterventionModels from 'hooks/useInterventionModels'

import { saveSubmission } from 'services/submissions'

import {
  Submission,
  SubmissionStatus,
  SubmissionSourceType,
} from 'core/Submission'
import { extractTitle } from 'core/ContentModel'
import { getLocalizedProperty } from 'core/common'
import { createSubmissionCopy } from 'core/mappers'
import openMap from 'core/openMap'
import generateKml from 'core/generateKml'

const getLocation = (submission: Submission) => {
  return [
    submission.location?.longitude,
    submission.location?.latitude,
    submission.location?.altitude,
  ]
}

interface LocalAssetMenuProps {
  submission: Submission
  onDidDismiss: () => void
}

const LocalAssetMenu: React.FC<LocalAssetMenuProps> = (props) => {
  const { submission, onDidDismiss } = props

  const locale = useCurrentLocale()
  const t = useTranslation()
  const Toast = useToast()

  const { push } = useHistory()

  const { data: model, isLoading: isLoadingModel } = useModel(
    submission.modelId,
  )

  const { data: inspectionModels, isLoading: isLoadingInspectionModels } =
    useInspectionModels(submission.modelId)

  const { data: interventionModels, isLoading: isLoadingInterventionModels } =
    useInterventionModels(submission.modelId)

  const submissionTitle = extractTitle(model, submission)
  const modelLabel = model ? getLocalizedProperty(locale, 'label', model) : ''

  const handleEditInventory = useCallback(() => {
    const { modelId, id } = submission
    push(`/edit-inventory/${modelId}/${id}`)
  }, [push, submission])

  const handleCreateLocalInspection = useCallback(async () => {
    const { id } = submission
    // NOTE this is unreachable unless `inspectionModels` is available (see
    // the guard below)

    if (inspectionModels!.length > 1) {
      push(`/choose-inspection/${id}`, {
        sourceType: SubmissionSourceType.Local,
      })
    } else {
      const modelId = inspectionModels![0].id

      push(`/new-local-inspection/${id}/${modelId}`)
    }
  }, [submission, inspectionModels, push])

  const handleFinishEdition = useCallback(async () => {
    await saveSubmission({
      ...submission,
      status: SubmissionStatus.finished,
    })
  }, [submission])

  const handleCreateLocalIntervention = useCallback(async () => {
    const { id } = submission

    if (interventionModels!.length > 1) {
      push(`/choose-intervention/${id}`, {
        sourceType: SubmissionSourceType.Local,
      })
    } else {
      const modelId = interventionModels![0].id

      push(`/new-local-intervention/${id}/${modelId}`)
    }
  }, [submission, interventionModels, push])

  const handleDuplicateNewAsset = useCallback(async () => {
    const copy = createSubmissionCopy(submission)
    await saveSubmission(copy)
    // NOTE submissions should be refetched after creating a copy of the asset,
    // which could be achieved through the `refetch` function of react-query,
    // or by calling `queryCache.invalidateQueries`
    // NOTE however, we are doing this operation in the parent component for now
  }, [submission])

  const handleDownloadKml = useCallback(async () => {
    try {
      const [lng, lat, alt] = getLocation(submission)

      if (lat == null || lng == null) {
        Toast.error(t('notifications.locationError'))
        return
      }

      await generateKml({
        modelLabel,
        sourceId: submission.id,
        title: submissionTitle,
        lat,
        lng,
        alt,
      })
    } catch (error) {
      console.error('Error generating zip with submissions:', error)
      Toast.error(t('notifications.downloadError'))
    }
  }, [t, Toast, submission, submissionTitle, modelLabel])

  const handleNavigateToLocation = useCallback(async () => {
    const [lng, lat] = getLocation(submission)

    if (lat == null || lng == null) {
      Toast.error(t('notifications.locationError'))
      return
    }

    openMap(lat, lng)
  }, [t, Toast, submission])

  const buttons = useMemo(() => {
    const buttons = [
      {
        text: t('assetMenu.edit'),
        icon: pencilOutline,
        handler: handleEditInventory,
      },
    ]

    if (submission.status !== SubmissionStatus.finished) {
      buttons.push({
        text: t('assetMenu.finish'),
        icon: checkmarkCircleOutline,
        handler: handleFinishEdition,
      })
    }

    if (inspectionModels?.length) {
      buttons.push({
        text: t('assetMenu.inspect'),
        icon: documentAttachOutline,
        handler: handleCreateLocalInspection,
      })
    }

    if (interventionModels?.length) {
      buttons.push({
        text: t('assetMenu.intervention'),
        icon: documentTextOutline,
        handler: handleCreateLocalIntervention,
      })
    }

    buttons.push(
      {
        // REVIEW can assets always be duplicated?
        text: t('assetMenu.duplicate'),
        icon: duplicateOutline,
        handler: handleDuplicateNewAsset,
      },
      {
        text: t('assetMenu.downloadKml'),
        icon: locationOutline,
        handler: handleDownloadKml,
      },
      {
        text: t('assetMenu.directions'),
        icon: trailSignOutline,
        handler: handleNavigateToLocation,
      },
    )

    return buttons
  }, [
    t,
    submission,
    inspectionModels,
    interventionModels,
    handleEditInventory,
    handleFinishEdition,
    handleCreateLocalInspection,
    handleCreateLocalIntervention,
    handleDuplicateNewAsset,
    handleDownloadKml,
    handleNavigateToLocation,
  ])

  return (
    <IonActionSheet
      isOpen={
        !isLoadingModel &&
        !isLoadingInspectionModels &&
        !isLoadingInterventionModels
      }
      onDidDismiss={onDidDismiss}
      buttons={buttons}
    />
  )
}

export default LocalAssetMenu

import React, { useMemo } from 'react'
import './FloatingButtons.css'
import {
  mapOutline,
  carOutline,
  layersOutline,
  earthOutline,
  thermometerOutline,
  umbrellaOutline,
} from 'ionicons/icons'
import SatelliteIcon from 'ui/icons/Satellite'
import TerrainIcon from 'ui/icons/Terrain'
import WindIcon from 'ui/icons/Wind'
import RoundButton from '../buttons/RoundButton'
import { hasWeatherService, LayerTiles } from 'services/weatherData'

export enum MapTypes {
  ROADMAP = 'roadmap', ////google.maps.MapTypeId['ROADMAP'],
  SATELLITE = 'satellite', //google.maps.MapTypeId['SATELLITE'],
  TERRAIN = 'terrain', //google.maps.MapTypeId['TERRAIN'],
  HYBRID = 'hybrid', //google.maps.MapTypeId['HYBRID'],
  LIGHT = 'light',
}
export interface FloatingButtonsProps {
  showTraffic: boolean
  activeLayer: LayerTiles | null
  mapType: MapTypes
  onToggleTraffic: () => void
  onToggleMapType: (type: MapTypes) => void
  onShowTile: (tileName: LayerTiles) => void
}

const FloatingButtons: React.FC<FloatingButtonsProps> = ({
  mapType,
  activeLayer,
  showTraffic,
  onToggleTraffic,
  onToggleMapType,
  onShowTile,
}) => {
  const [showOptions, setShowOptions] = React.useState(false)
  const showWeather = useMemo(() => hasWeatherService(), [])

  return (
    <div className="fab-container">
      {showOptions && (
        <div
          className={'floating-buttons' + (showWeather ? ' button-grid' : '')}
        >
          <RoundButton
            icon={mapOutline}
            onClick={() => onToggleMapType(MapTypes.ROADMAP)}
            fill={'solid'}
            color={mapType === MapTypes.ROADMAP ? 'primary' : 'medium'}
            size="medium"
          />
          {/*           <RoundButton
            icon={SatelliteIcon}
            onClick={() => onToggleMapType(MapTypes.SATELLITE)}
            fill={'solid'}
            color={mapType === MapTypes.SATELLITE ? 'primary' : 'medium'}
            size="medium"
          /> */}
          <RoundButton
            icon={SatelliteIcon}
            color={mapType === MapTypes.HYBRID ? 'primary' : 'medium'}
            onClick={() => onToggleMapType(MapTypes.HYBRID)}
            fill={'solid'}
            size="medium"
          />
          <RoundButton
            icon={TerrainIcon}
            onClick={() => onToggleMapType(MapTypes.TERRAIN)}
            fill={'solid'}
            color={mapType === MapTypes.TERRAIN ? 'primary' : 'medium'}
            size="medium"
          />
          <RoundButton
            onClick={() => onToggleMapType(MapTypes.LIGHT)}
            icon={earthOutline}
            fill={'solid'}
            color={mapType === MapTypes.LIGHT ? 'primary' : 'medium'}
            size="medium"
          />
          <RoundButton
            icon={carOutline}
            onClick={onToggleTraffic}
            fill={'solid'}
            color={showTraffic ? 'primary' : 'medium'}
            size="medium"
          />
          {showWeather && (
            <>
              <RoundButton
                icon={thermometerOutline}
                onClick={() => onShowTile(LayerTiles.Temperature)}
                fill={'solid'}
                color={
                  activeLayer === LayerTiles.Temperature ? 'primary' : 'medium'
                }
                size="medium"
              />
              <RoundButton
                icon={umbrellaOutline}
                onClick={() => onShowTile(LayerTiles.Precipitation)}
                fill={'solid'}
                color={
                  activeLayer === LayerTiles.Precipitation
                    ? 'primary'
                    : 'medium'
                }
                size="medium"
              />
              <RoundButton
                icon={WindIcon}
                onClick={() => onShowTile(LayerTiles.Wind)}
                fill={'solid'}
                color={activeLayer === LayerTiles.Wind ? 'primary' : 'medium'}
                size="medium"
              />
            </>
          )}
        </div>
      )}
      <RoundButton
        icon={layersOutline}
        onClick={() => setShowOptions(!showOptions)}
        color={showOptions ? 'primary' : 'medium'}
        fill={'solid'}
      />
    </div>
  )
}

export default FloatingButtons

import React from 'react'
import {
  IonItemGroup,
  IonLabel,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonText,
  IonList,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'

import AppLayout from 'ui/layouts/AppLayout'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import { useAssetModels } from 'hooks/useModelList'

import { getTitle } from 'core/ContentModel'

const TakeData = () => {
  const locale = useCurrentLocale()
  const t = useTranslation()

  const { push } = useHistory()

  // asset models
  const models = useAssetModels()

  return (
    <AppLayout pageTitle={t('menu.data')} hideBackLink>
      <IonList lines="full" style={{ padding: 0 }}>
        <IonAccordionGroup>
          <IonAccordion>
            <IonItem
              slot="header"
              lines="full"
              style={{
                // NOTE restore border width, which is overriden for items that
                // are accordion headers 🤷‍♂️
                '--border-width': '0 0 1px 0',
              }}
            >
              <IonLabel className="ion-text-wrap">
                <IonText color="primary">{t('takeData.createNew')}</IonText>
              </IonLabel>
            </IonItem>
            <IonItemGroup slot="content">
              {models.map((model) => (
                <IonItem
                  key={model.id}
                  detail
                  button
                  onClick={() => push(`/new-inventory/${model.id}`)}
                >
                  <IonLabel className="ion-text-wrap">
                    <IonText>{getTitle(locale, model)}</IonText>
                  </IonLabel>
                </IonItem>
              ))}
            </IonItemGroup>
          </IonAccordion>
        </IonAccordionGroup>
        <IonItem detail button onClick={() => push('/take-data/in-progress')}>
          <IonLabel className="ion-text-wrap">
            <IonText color="primary">{t('takeData.inProgress')}</IonText>
          </IonLabel>
        </IonItem>
      </IonList>
    </AppLayout>
  )
}

export default TakeData

import React from 'react'
import { IonItem, IonLabel, IonSkeletonText } from '@ionic/react'

const FormSkeleton: React.FC = () => {
  return (
    <>
      <IonItem>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '40%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '40%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '40%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '40%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '40%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <h3>
            <IonSkeletonText animated style={{ width: '40%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </IonItem>
    </>
  )
}

export default FormSkeleton

import { ContentField, FieldType } from 'core/ContentField'

import { FilterDefaultValues } from './index.d'

const getDefaultValuesForFields = (fields: ContentField[]) => {
  return fields.reduce((acc: FilterDefaultValues, field) => {
    switch (field.type) {
      case FieldType.Text:
      case FieldType.Select:
        acc[field.name] = null
        break
      case FieldType.Boolean:
        acc[field.name] = { true: false, false: false, null: false }
        break
      case FieldType.Numeric:
      case FieldType.Datetime:
        acc[field.name] = { gte: null, lte: null }
        break
    }
    return acc
  }, {})
}

export default getDefaultValuesForFields

import { useLiveQuery } from 'dexie-react-hooks'

import {
  getSubmissionsByType,
  getPendingSubmissionsBySourceId,
} from 'services/submissions'

import { SubmissionType, SubmissionStatus } from 'core/Submission'

const useLocalAssets = () => {
  return useLiveQuery(
    async () => {
      const assets = await getSubmissionsByType(SubmissionType.NewAsset)

      const result = []
      for (const asset of assets) {
        // include all assets not yet uploaded ...
        if (asset.status !== SubmissionStatus.uploaded) {
          result.push(asset)
          continue
        }

        const submissions = await getPendingSubmissionsBySourceId(asset.id)
        // ... and those with pending submissions
        if (submissions.length > 0) {
          result.push(asset)
        }
      }

      return result
    },
    [],
    [],
  )
}

export default useLocalAssets

import { useLiveQuery } from 'dexie-react-hooks'

import { getPendingSubmissionsBySourceId } from 'services/submissions'

const useAssetLocalSubmissions = (assetId: string) => {
  return useLiveQuery(
    () => getPendingSubmissionsBySourceId(assetId),
    [assetId],
    [],
  )
}

export default useAssetLocalSubmissions

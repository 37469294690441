import React from 'react'
import { IonChip } from '@ionic/react'
import cx from 'classnames'

import Typography, { Variant } from '../typography/Typography'

import useTranslation from 'hooks/useTranslation'

import { SubmissionStatus } from 'core/Submission'

import './StatusTag.css'

interface StatusTagProps {
  status: SubmissionStatus | 'downloaded'
}

// TODO this only contemplates "finished" and "updated" (default) tags
// REVIEW background colors in ionic chips are slightly lighter than in current
// design (8% vs 10% opacity)
const StatusTag: React.FC<StatusTagProps> = (props) => {
  const { status } = props

  const t = useTranslation()

  return (
    <IonChip className={cx('status-tag', `status-tag-${status}`)}>
      <Typography variant={Variant.caption}>{t(`tags.${status}`)}</Typography>
    </IonChip>
  )
}

export default StatusTag

import { makeExportSubmissions } from 'useCases'
import { sendSubmission, sendSubmissionFile } from 'services/export'
import { saveSubmission } from 'services/submissions'
const { start, cancel } = makeExportSubmissions({
  sendSubmission,
  sendSubmissionFile,
  updateSubmissionStatus: saveSubmission,
})
const useExport = () => {
  return { startExport: start, cancelExport: cancel }
}

export default useExport

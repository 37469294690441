import { useQuery } from 'react-query'

import { getInterventionModelsForAssetModelId } from 'services/models'

const useInterventionModels = (assetModelId?: string) => {
  return useQuery(
    ['interventions', assetModelId],
    () => getInterventionModelsForAssetModelId(assetModelId!),
    { enabled: Boolean(assetModelId) },
  )
}

export default useInterventionModels

import React, { useMemo } from 'react'
import { range } from 'ramda'
import ItemSkeleton from './ItemSkeleton'

interface ListSkeletonProps {
  count?: number
}

const ListSkeleton: React.FC<ListSkeletonProps> = (props) => {
  const { count = 10 } = props
  const itemRange = useMemo(() => range(0, count), [count])

  return (
    <>
      {itemRange.map((num) => (
        <ItemSkeleton key={num} />
      ))}
    </>
  )
}

export default ListSkeleton

import { Models } from './db'
import { ContentModel, canBeCreated, ModelTypes } from 'core/ContentModel'
/**
 * Returns a content model (asset/inspection) by id
 * @param id Model id
 */
export async function getModelById(
  id: string,
): Promise<ContentModel | undefined> {
  return Models.get(id)
}

export async function getModelsWhereIdIn(ids: string[]) {
  return Models.where('id').anyOf(ids).toArray()
}

/**
 * Returns all asset models
 */
export async function getAssetModels(): Promise<ContentModel[]> {
  return Models.where({
    modelType: ModelTypes.asset,
  })
    .filter(canBeCreated)
    .toArray()
    .catch((err) => {
      console.error('Error fetching asset models from local DB', err)
      throw err
    })
}
/**
 * Returns all inspection models that can be used on an asset model
 * @param assetModelId asset model id
 * @returns {Promise<ContentModel[]>}
 */
export async function getInspectionModelsForAssetModelId(assetModelId: string) {
  return Models.where('sys.usedByModels')
    .equals(assetModelId)
    .toArray()
    .catch((err) => {
      console.error(
        'Error retrieving inspection model from IDB for asset ' + assetModelId,
      )
      throw err
    })
}

export const getInterventionModelsForAssetModelId = async (
  assetModelId: string,
) => {
  return Models.where('modelType')
    .equals(ModelTypes.intervention)
    .filter(
      (intervention) =>
        // REVIEW remove optional chaining (?.) after separating interfaces for
        // different content models
        intervention.config?.applicableModels.includes(assetModelId) ?? false,
    )
    .toArray()
}

/**
 * Returns the title field name from a model and label
 * @param modelId asset model id
 */
export async function getTitleFieldAndType(modelId: string) {
  try {
    const model = await getModelById(modelId)
    const titleField = model?.fields.find((field) => field.meta.useAsTitle)

    return {
      label: model?.label,
      titleFieldName: titleField?.name,
    }
  } catch (err) {
    console.error('Error retrieving field info from IDB for model ' + modelId)
    throw err
  }
}

import { useQuery } from 'react-query'
import { getLayoutForModel } from '../services/layouts'
import useToast from './useToast'
export default function useLayout(modelId?: string) {
  const Toast = useToast()

  return useQuery(
    ['layout', modelId],
    () => getLayoutForModel(String(modelId)),
    {
      enabled: !!modelId,
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast.error('Ocurrió un error al obtener el layout')
      },
    },
  )
}

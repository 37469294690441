import React from 'react'
import { IonItem, IonLabel, IonSkeletonText, IonText } from '@ionic/react'

const ItemSkeleton: React.FC<any> = (props) => {
  return (
    <IonItem>
      <IonLabel>
        <h3>
          <IonSkeletonText animated style={{ width: '90%' }} />
        </h3>
      </IonLabel>
      <IonText slot="end">
        <IonSkeletonText animated style={{ width: '100%' }} />
      </IonText>
    </IonItem>
  )
}

export default ItemSkeleton

import { isPlatform } from '@ionic/react'

export default function openMap(lat: number, lng: number) {
  const baseURL = isPlatform('ios')
    ? 'maps://maps.google.com/maps'
    : 'https://maps.google.com/maps'

  window.open(`${baseURL}?daddr=${lat},${lng}&amp;ll=`, '_top')

  return
}

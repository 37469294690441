import React, { useCallback, useMemo } from 'react'
import { GroupLayout } from 'core/Layout'
import { IonList } from '@ionic/react'
import LinearLayoutEditor from './LinearLayout'
import GroupInnerContent from 'ui/layouts/GroupInnerContent'
import AssetNav from 'ui/components/menus/AssetNav'
import AssetNavItem from 'ui/components/menus/AssetNavItem'
import useLayoutNavigation from 'hooks/useLayoutNavigation'
import useForm from 'hooks/useForm'
import { useRelevants } from 'context/ExpressionContext'
import { filter } from 'ramda'
import { getCurrentGroup } from 'context/FormContext/selectors'
import { useCurrentLocale } from 'context/LocaleContext'
import { getLocalizedProperty } from 'core/common'
interface GroupLayoutProps {
  layout: GroupLayout
  readOnly?: boolean
}
function GroupLayoutEditor(props: GroupLayoutProps) {
  const {
    readOnly,
    layout: { groups },
  } = props
  const locale = useCurrentLocale()
  const { state } = useForm()
  const { shouldDisplayGroup } = useRelevants()
  const groupName = getCurrentGroup(state)

  const selectedGroup = useMemo(() => {
    if (!groupName) return null
    return groups.find((g) => g.name === groupName)
  }, [groups, groupName])

  const visibleGroups = filter((g) => shouldDisplayGroup(g.name), groups)

  const { navigateToGroup } = useLayoutNavigation(groupName, visibleGroups)

  const handleGroupClick = useCallback(
    (groupName: string) => {
      navigateToGroup(groupName)
    },
    [navigateToGroup],
  )
  return (
    <>
      {selectedGroup ? (
        <GroupInnerContent
          title={getLocalizedProperty(locale, 'label', selectedGroup)}
        >
          <LinearLayoutEditor
            readOnly={readOnly}
            fields={selectedGroup.fields}
          />
        </GroupInnerContent>
      ) : (
        <IonList>
          <AssetNav>
            {visibleGroups.map((group) => (
              <AssetNavItem
                key={group.name}
                title={getLocalizedProperty(locale, 'label', group)}
                onClick={() => handleGroupClick(group.name)}
              />
            ))}
          </AssetNav>
        </IonList>
      )}
    </>
  )
}

export default React.memo(GroupLayoutEditor)

import generateId from 'common/generateId'
import { map, mapObjIndexed, update } from 'ramda'
import { FullAsset } from './Assets'
import {
  Submission,
  SubmissionSourceType,
  SubmissionStatus,
  SubmissionType,
} from './Submission'
import { duplicateSubmission } from './duplicateSubmission'
import { ModelTypes } from './ContentModel'

import { Inspection } from './Inspections'

export function createAssetRevisionSubmission(a: FullAsset): Submission {
  const submissionId = generateId()
  return {
    id: submissionId,
    modelId: a.assetType,
    modelType: ModelTypes.asset,
    type: SubmissionType.AssetRevision,
    sourceId: a.id,
    sourceModelId: a.assetType,
    sourceType: SubmissionSourceType.Remote,
    data: a.data,
    files: [],
    children: map(
      (child) => ({
        id: child._id,
        modelId: child.assetType,
        ancestors: update(0, submissionId, child.ancestors),
        parentField: child.parentField,
        data: child.data,
        meanings: child?.meanings ?? {},
      }),
      a.subAssets,
    ),
    status: SubmissionStatus.draft,
    location: {
      accuracy: null,
      altitude: a.location?.coordinates?.[2] ?? null,
      longitude: a.location.coordinates[0],
      latitude: a.location.coordinates[1],
    },
    meanings: a?.meanings ?? {},
  }
}

export const createInspectionCopy = (inspection: Inspection): Submission => {
  return duplicateSubmission({
    id: inspection.id,
    type: SubmissionType.NewInspection,
    sourceType: SubmissionSourceType.Remote,
    modelId: inspection.inspectionModelId,
    // REVIEW `inspection.modelType` does not exist in `Inspection` interface,
    // even though it is sometimes available
    modelType: ModelTypes.inspection,
    sourceId: inspection.assetId,
    sourceModelId: inspection.assetModelId,
    // NOTE omit `inventoryId` so that the backend sets the latest
    // inventoryId: inspection.inventoryId,
    status: SubmissionStatus.duplicated,
    data: inspection.data,
    meanings: inspection.meanings,
    children: map(
      (child) => ({
        id: child.id,
        ancestors: child.ancestors,
        parentField: child.parentField,
        modelId: child.inspectionModelId,
        sourceId: child.assetId,
        sourceModelId: child.assetModelId,
        data: child.data,
        meanings: child?.meanings ?? {},
      }),
      inspection.subInspections,
    ),
    files: [],
  })
}

export function createAssetCopy(a: FullAsset): Submission {
  return duplicateSubmission({
    id: a.id,
    modelId: a.assetType,
    modelType: ModelTypes.asset,
    type: SubmissionType.NewAsset,
    data: a.data,
    files: [],
    meanings: a?.meanings ?? {},
    children: map(
      (child) => ({
        id: child._id,
        modelId: child.assetType,
        ancestors: child.ancestors,
        parentField: child.parentField,
        data: child.data,
        meanings: child?.meanings ?? {},
      }),
      a.subAssets,
    ),
    status: SubmissionStatus.duplicated,
    location: {
      accuracy: null,
      altitude: a.location?.coordinates?.[2] ?? null,
      longitude: a.location.coordinates[0],
      latitude: a.location.coordinates[1],
    },
  })
}

export const createSubmissionCopy = (submission: Submission) => {
  return duplicateSubmission({
    ...submission,
    status: SubmissionStatus.duplicated,
  })
}

export function makeFullAssetFromRevision(s: Submission): FullAsset {
  /** For AssetRevision, sourceId is the asset id. For NewAsset, the submission id IS the asset id */
  const rootId = s.type === SubmissionType.AssetRevision ? s.sourceId! : s.id
  const generatedAsset = {
    id: rootId,
    assetType: s.modelId,
    data: s.data,
    location: {
      type: 'Point',
      coordinates: [
        s.location?.longitude || 0,
        s.location?.latitude || 0,
        s.location?.altitude || 0,
      ],
    } as FullAsset['location'],
    subAssets: mapObjIndexed(
      (child) => ({
        _id: child.id,
        assetType: child.modelId,
        ancestors: update(0, rootId, child.ancestors),
        parentField: child.parentField,
        data: child.data,
        meanings: child.meanings,
      }),
      s.children,
    ),
    revisionSubmissionId: s.id,
    meanings: s?.meanings ?? {},
  }
  return generatedAsset
}

import React, { useCallback, useMemo } from 'react'
import { prop } from 'ramda'
import TextInput from './TextInput'
import NumericInput from './NumericInput'
import DateTime from './DateTime'
import GeoLocation from './Geolocation'
import { ContentField, FieldType } from 'core/ContentField'
import SelectContainer from './SelectContainer'
import Link from './Link'
import BooleanField from './BooleanField'
import { useCurrentLocale } from 'context/LocaleContext'
import { getLocalizedProperty } from 'core/common'
import { FieldProps } from './common'
import useForm from 'hooks/useForm'
import Image from './Image'
//import InspectionAssetReference from './InspectionAssetReference'
import { useRelevants } from 'context/ExpressionContext'
import { useDispatchContext } from 'context/FormContext'
import {
  getCurrentSourceId,
  getCurrentFormData,
} from 'context/FormContext/selectors'
import InspectionAssetReference2 from './InspectionAssetReference2'
import * as Actions from 'context/FormContext/actions'
import Note from './Note'
import Sketch from './Sketch'
interface EditFieldProps {
  fieldModel: ContentField
  tabIndex?: number
  readOnly?: boolean
}

const EditField: React.FC<EditFieldProps> = (props) => {
  const { fieldModel, readOnly } = props
  const locale = useCurrentLocale() as string
  const { state } = useForm()
  const dispatch = useDispatchContext()
  const formData = getCurrentFormData(state)

  const { shouldDisplayField } = useRelevants()
  const handleChange = useCallback(
    (fieldName: string, value: any) => {
      dispatch(Actions.setFieldData(fieldName, value))
    },
    [dispatch],
  )
  const commonProps: FieldProps = useMemo(
    () => ({
      readOnly,
      name: fieldModel.name,
      label: getLocalizedProperty(locale, 'label', fieldModel),
      isRequired: fieldModel.meta.required,
      value: prop<string, any>(fieldModel.name, formData),
      hint: getLocalizedProperty(locale, 'hint', fieldModel),
      fileId: fieldModel.fileId ? (fieldModel.fileId as string) : undefined,
      onChange: handleChange,
    }),
    [readOnly, fieldModel, formData, handleChange, locale],
  )

  if (!shouldDisplayField(fieldModel.name)) {
    if (Object.keys(state.rootData).includes(fieldModel.name))
      handleChange(fieldModel.name, null)
    return null
  }
  switch (fieldModel.type) {
    case FieldType.Boolean:
      return (
        <BooleanField {...commonProps} defaultValue={fieldModel.defaultValue} />
      )
    case FieldType.Text:
      return <TextInput {...commonProps} isMultiline={fieldModel.multiLine} />
    case FieldType.Select:
      return (
        <SelectContainer
          {...commonProps}
          listName={fieldModel.listName}
          isMultiple={fieldModel.isMultiple}
        />
      )
    case FieldType.Numeric:
      return (
        <NumericInput
          {...commonProps}
          numericType={fieldModel.numericType}
          min={fieldModel.min}
          max={fieldModel.max}
          increment={fieldModel.increment}
        />
      )
    case FieldType.Datetime: {
      return (
        <DateTime
          {...commonProps}
          displayFormat="DD/MM/YYYY"
          min={fieldModel.minDate}
          max={fieldModel.maxDate}
        />
      )
    }
    case FieldType.Image:
    case FieldType.File:
      return <Image {...commonProps} />
    case FieldType.Sketch:
      return (
        <Sketch
          {...commonProps}
          height={fieldModel.height}
          width={fieldModel.width}
          backgroundColor={fieldModel.backgroundColor}
          backgroundFileId={fieldModel.backgroundFileId}
          defaultColor={fieldModel.defaultColor}
          defaultStrokeWidth={fieldModel.defaultStrokeWidth}
          aspectRatio={fieldModel.aspectRatio}
          showGrid={fieldModel.showGrid}
          allowCustomBackground={fieldModel.allowCustomBackground}
          exportWithBackground={fieldModel.exportWithBackground}
          backgroundFieldName={fieldModel.backgroundFieldName}
        />
      )
    case FieldType.Geopoint:
      return (
        <GeoLocation
          {...commonProps}
          ownPosition={fieldModel.meta.ownPosition}
        />
      )
    case FieldType.Link:
      const childrenOfLink = ((formData[fieldModel.name] as string[]) || [])
        .length
      return (
        <Link
          {...commonProps}
          childrenTotal={childrenOfLink}
          goToLink={(linkName) => dispatch(Actions.goToLink(linkName))}
        />
      )
    case FieldType.InspectionAssetReference:
      const sourceId = getCurrentSourceId(state)
      return (
        <InspectionAssetReference2
          {...commonProps}
          value={commonProps.value || sourceId}
        />
      )
    case FieldType.Note:
      return <Note {...commonProps} appearance={fieldModel.appearance} />
    default: {
      //console.log('Nothing to render for field', fieldModel)
      return null
    }
  }
}

export default React.memo(EditField)

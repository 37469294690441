import { ChildAsset, FullAsset, makeBreadCrumb } from 'core/Assets'
import { ContentModel } from 'core/ContentModel'
import { SubmissionSourceType } from 'core/Submission'
import useSubmissionSource from 'hooks/submission/useSubmissionSource'
import useModelsById from 'hooks/useModelsByIds'
import { assoc, filter, has, map, prop, values } from 'ramda'
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { useCurrentLocale } from './LocaleContext'

export type InspectedAssetContext = {
  asset?: FullAsset
  model?: ContentModel
  getModelById: (id: string) => ContentModel | null
  getAssetBreadCrumb: (id: string) => string
  findChildAssets: (predicate: (c: ChildAsset) => boolean) => ChildAsset[]
}

const Context = createContext<InspectedAssetContext>({
  getModelById: () => null,
  getAssetBreadCrumb: () => '',
  findChildAssets: () => [],
})

type InspectedAssetProviderProps = React.PropsWithChildren<{
  inspectedAssetId?: string
  model?: ContentModel
  sourceType?: SubmissionSourceType
}>
export const InspectedAssetProvider: React.FC<InspectedAssetProviderProps> = ({
  inspectedAssetId,
  model,
  sourceType = SubmissionSourceType.Remote,
  children,
}) => {
  const currentLocale = useCurrentLocale()
  const { data: asset, error } = useSubmissionSource({
    id: inspectedAssetId,
    sourceType,
  }) //useAsset(inspectedAssetId)

  const usedModelIds = useMemo(() => {
    if (asset == null) return []
    return [
      asset.assetType,
      ...map<ChildAsset, string>(prop('assetType'), values(asset.subAssets)),
    ]
  }, [asset])

  const { data: usedModels, error: getModelsError } =
    useModelsById(usedModelIds)

  const getModelById = useCallback(
    (id: string) => {
      return prop(id, usedModels as { [id: string]: ContentModel })
    },
    [usedModels],
  )

  const assetBreadcrumbs: {
    [assetId: string]: string
  } = useMemo(() => {
    const getAsset = (id: string) => {
      if (id === inspectedAssetId) {
        return asset as FullAsset
      }
      let subAssets = asset?.subAssets || {}
      if (has(id, subAssets)) {
        return subAssets[id] || null
      }
      console.error('getAsset', id, 'not found in root or subAssets')
      return null
    }
    if (!asset) return {}

    return values(asset.subAssets).reduce((acc, childAsset) => {
      return assoc(
        childAsset._id,
        makeBreadCrumb(getAsset, getModelById, currentLocale, childAsset),
        acc,
      )
    }, {})
  }, [asset, inspectedAssetId, getModelById, currentLocale])

  const getAssetBreadCrumb = useCallback(
    (assetId: string) => {
      return assetBreadcrumbs[assetId]
    },
    [assetBreadcrumbs],
  )

  const findChildAssets = useCallback(
    (pred: (child: ChildAsset) => boolean) => {
      if (!asset) return []
      return filter<ChildAsset>(pred, values(asset.subAssets))
    },
    [asset],
  )

  return (
    <Context.Provider
      value={{
        asset,
        model,
        getModelById,
        getAssetBreadCrumb,
        findChildAssets,
      }}
    >
      {error || getModelsError ? null : children}
    </Context.Provider>
  )
}

export const useInspectedAsset = () => {
  return useContext(Context)
}

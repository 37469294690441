import { useCallback, useMemo } from 'react'
import { useFormState } from 'context/FormContext'
import useModel from './useModel'
import { GroupLayoutConfig, makeLayoutWithFields } from 'core/Layout'
import useLayout from './useLayout'
import { LinkField, FieldType } from 'core/ContentField'
import { propEq, filter, values, allPass, equals, last } from 'ramda'
import { getFieldByName } from 'core/ContentModel'
import { sortByLabel } from 'common/sortByLabel'
import { getLocalizedProperty } from 'core/common'
import { useCurrentLocale } from 'context/LocaleContext'
import { getCurrentHistory } from 'context/FormContext/selectors'

export default function useForm() {
  const locale = useCurrentLocale()
  const state = useFormState()
  const { rootData, formChildren, files, rootId } = state

  const currentHistory = getCurrentHistory(state)

  const { data: model } = useModel(currentHistory?.modelId || '')
  const { data: rootModel } = useModel(state.rootModelId || '')
  const { data: layout } = useLayout(currentHistory?.modelId || '')

  const isTreeStructure = useMemo(() => {
    return rootModel?.sys.treeStructure
  }, [rootModel])

  const layoutWithFields = useMemo(() => {
    if (!model || !layout) return null
    return makeLayoutWithFields(model, layout)
  }, [model, layout])

  const linkFields = useMemo(() => {
    if (!model) return []

    let fields: LinkField[] = []

    if (currentHistory?.selectedLink) {
      fields = [
        getFieldByName(currentHistory?.selectedLink, model) as LinkField,
      ]
    } else if (isTreeStructure) {
      fields = model.fields.filter(
        (f) => f.type === FieldType.Link,
      ) as LinkField[]
    }
    return sortByLabel(
      fields
        .filter((field) => {
          const totalChildrenOfType =
            values(
              filter(
                allPass([
                  (c) =>
                    equals(
                      last(c.ancestors),
                      currentHistory?.childId
                        ? currentHistory?.childId
                        : rootId,
                    ),
                  propEq('parentField', field.name),
                ]),
                formChildren,
              ),
            ).length || 0

          if (!field.isMultiple && totalChildrenOfType) return false
          if (field.maxLength && totalChildrenOfType >= field.maxLength)
            return false
          return true
        })
        .map((linkField) => ({
          name: linkField.name,
          label: getLocalizedProperty(locale, 'label', linkField),
          fieldModelId: linkField.ofType,
        })),
    )
  }, [model, currentHistory, isTreeStructure, formChildren, locale, rootId])

  const groups = useMemo(() => (layout as GroupLayoutConfig)?.groups ?? [], [layout])

  const selectedLinkField = useMemo(() => {
    if (!currentHistory?.selectedLink || !model) return
    return getFieldByName(currentHistory.selectedLink, model) as LinkField
  }, [currentHistory, model])

  const getCurrentFormData = useCallback(() => {
    if (currentHistory?.childId)
      return formChildren[currentHistory.childId].data
    return rootData
  }, [currentHistory, formChildren, rootData])

  const getFile = useCallback(
    (fileId: string) => files.find((f) => f.id === fileId),
    [files],
  )

  return {
    state,
    getCurrentFormData,
    model,
    rootModel,
    layoutWithFields,
    selectedLinkField,
    groups,
    linkFields,
    isTreeStructure,
    getFile,
    currentHistory,
  }
}

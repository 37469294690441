import React from 'react'
import {
  Redirect as RouterRedirect,
  Route as RouterRoute,
} from 'react-router-dom'
import { IonRouterOutlet } from '@ionic/react'

import Home from './pages/Home'
import Login from './pages/Login'
import {
  RedirectProps,
  RouteProps,
  useLocation,
  useRouteMatch,
} from 'react-router'

import useUser from './hooks/useUser'
import TakeData from 'pages/takeData/TakeData'
import InProgress from 'pages/takeData/InProgress'
import AssetMap from 'pages/takeData/AssetMap'
import EditSubmission, {
  EditSubmissionProps,
  EditSubmissionParams,
} from 'pages/EditSubmission'
import Import from 'pages/import/Import'
import ChooseAssetImport from 'pages/import/ChooseAssetImport'
import ImportAsset from 'pages/import/ImportAsset'
import ImportAssetByLocation from 'pages/import/ImportAssetByLocation'
import ImportAssetByAttributes from 'pages/import/ImportAssetByAttributes'
import ChooseInspection from 'pages/ChooseInspection'
import ChooseIntervention from 'pages/ChooseIntervention'
import Export from 'pages/export/Export'
import Settings from 'pages/Settings'
import Help from 'pages/Help'
import FormContextProvider from 'context/FormContext'
import { SubmissionSourceType, SubmissionType } from 'core/Submission'
import generateId from 'common/generateId'

const Redirect =
  RouterRedirect as unknown as React.ComponentClass<RedirectProps>
const Route = RouterRoute as unknown as React.ComponentClass<RouteProps>

const wrapWithFormProvider =
  (
    Component: React.ComponentFactory<any, any>,
    extraProps: Partial<EditSubmissionProps> = {},
  ) =>
  (props: any) => {
    const {
      params: {
        /** Root form model  */
        modelId,
        /** Submission id */
        submissionId = generateId(),
        /** Inspected asset id -may be a local or remote asset */
        assetId,
      },
    } = useRouteMatch<EditSubmissionParams>()
    const location = useLocation()
    const isEdit = location.pathname.includes('edit')

    return (
      <FormContextProvider>
        <Component
          {...props}
          {...extraProps}
          submissionId={submissionId}
          modelId={modelId}
          assetId={assetId}
          isEdit={isEdit}
        />
      </FormContextProvider>
    )
  }

const NewInventory = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewAsset,
})
const ViewInspection = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewInspection,
})
const NewInspection = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewInspection,
})
const EditInventory = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewAsset,
})
const EditInspection = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewInspection,
})
const EditInventoryRevision = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.AssetRevision,
})
const NewLocalInspection = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewInspection,
  sourceType: SubmissionSourceType.Local,
})

const EditLocalInspection = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewInspection,
  sourceType: SubmissionSourceType.Local,
})

const NewIntervention = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewIntervention,
})

const EditIntervention = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewIntervention,
})

const NewLocalIntervention = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewIntervention,
  sourceType: SubmissionSourceType.Local,
})

const EditLocalIntervention = wrapWithFormProvider(EditSubmission, {
  type: SubmissionType.NewIntervention,
  sourceType: SubmissionSourceType.Local,
})

const AppRoutes: React.FC = () => {
  const { isLogged } = useUser()

  if (!isLogged) return <Login />
  return (
    <IonRouterOutlet>
      <Route exact path="/home" render={() => <Home />} />
      <Route exact path="/import" component={Import} />
      <Route exact path="/import/assets" component={ChooseAssetImport} />
      <Route exact path="/import/assets/:modelId" component={ImportAsset} />
      <Route
        exact
        path="/import/assets/:modelId/map"
        component={ImportAssetByLocation}
      />
      <Route
        exact
        path="/import/assets/:modelId/attributes"
        component={ImportAssetByAttributes}
      />
      <Route exact path="/take-data" component={TakeData} />
      <Route exact path="/take-data/in-progress" component={InProgress} />
      <Route exact path="/take-data/in-progress/map" component={AssetMap} />
      <Route exact path="/new-inventory/:modelId" component={NewInventory} />
      <Route
        exact
        path="/edit-inventory/:modelId/:submissionId"
        component={EditInventory}
      />
      <Route
        exact
        path="/inspection/:assetId/:modelId"
        component={ViewInspection}
      />
      <Route
        exact
        path="/choose-inspection/:assetId"
        component={ChooseInspection}
      />
      <Route
        exact
        path="/new-inspection/:assetId/:modelId"
        component={NewInspection}
      />
      <Route
        exact
        path="/new-local-inspection/:assetId/:modelId"
        component={NewLocalInspection}
      />
      <Route
        exact
        path="/edit-inspection/:assetId/:modelId/:submissionId"
        component={EditInspection}
      />
      <Route
        exact
        path="/edit-local-inspection/:assetId/:modelId/:submissionId"
        component={EditLocalInspection}
      />
      <Route
        exact
        path="/choose-intervention/:assetId"
        component={ChooseIntervention}
      />
      <Route
        exact
        path="/new-intervention/:assetId/:modelId"
        component={NewIntervention}
      />
      <Route
        exact
        path="/edit-intervention/:assetId/:modelId/:submissionId"
        component={EditIntervention}
      />
      <Route
        exact
        path="/update-inventory/:submissionId/:modelId"
        component={EditInventoryRevision}
      />
      <Route
        exact
        path="/new-local-intervention/:assetId/:modelId"
        component={NewLocalIntervention}
      />
      <Route
        exact
        path="/edit-local-intervention/:assetId/:modelId/:submissionId"
        component={EditLocalIntervention}
      />

      <Route exact path="/export" component={Export} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/settings/help" component={Help} />
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
    </IonRouterOutlet>
  )
}

export default AppRoutes

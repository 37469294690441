import React from 'react'

import ImportAsset from 'ui/views/import/ImportAsset'

// REVIEW is this intermediate component really necessary?
const ImportAssetPage = () => {
  return <ImportAsset />
}

export default ImportAssetPage

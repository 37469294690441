import React, { ReactNode, useState, useRef, useCallback, useMemo } from 'react'
import { ToastOptions, IonToast } from '@ionic/react'
import { ReactControllerProps } from '@ionic/react/dist/types/components/createControllerComponent'

// BASED ON: https://github.com/agneym/ir-toast

type ReactToastOptions = ToastOptions & Partial<ReactControllerProps>

type ToastInstance = {
  present: (options?: ReactToastOptions) => void
  dismiss: () => void
}

export type ToastProviderOptions = {
  create: (options: ReactToastOptions) => ToastInstance
  success: (message: string, duration?: number) => ToastInstance
  error: (message: string, duration?: number) => ToastInstance
  warning: (message: string, duration?: number) => ToastInstance
}

const Context = React.createContext({})

export function ToastContextProvider({
  value,
  children,
}: {
  value?: ToastOptions
  children: ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<ReactToastOptions>()
  const ref = useRef<HTMLIonToastElement | null>(null)

  const create = useCallback(
    (options: ReactToastOptions) => {
      const present = (options: ReactToastOptions) => () => {
        setOptions({
          ...value,
          ...options,
        })
        setIsOpen(true)
      }

      const dismiss = () => {
        ref.current?.dismiss()
      }

      return {
        present: present(options),
        dismiss,
      }
    },
    [value],
  )

  // @diana puedes cambiar los colores de los toasts en esta función
  const contextValue = useMemo(() => {
    const translateToOptions =
      (color: 'dark' | 'warning' | 'danger') =>
      (message: string, duration: number = 2000) => {
        const toast = create({ message, color, duration })
        toast.present()
        return toast
      }

    return {
      create,
      success: translateToOptions('dark'),
      error: translateToOptions('danger'),
      warning: translateToOptions('warning'),
    }
  }, [create])

  return (
    <Context.Provider value={contextValue}>
      {children}
      <IonToast
        ref={ref}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        {...(options as any)}
      />
    </Context.Provider>
  )
}

export default Context

import useAuthenticatedQuery from 'hooks/useAuthenticatedQuery'

import { fetchInspectionsForAsset } from 'services/inspections'

const useAssetInspections = (assetId: string, options = {}) => {
  return useAuthenticatedQuery(
    ['inspections', assetId],
    (_key, assetId: string) => {
      return fetchInspectionsForAsset(assetId)
    },
    options,
  )
}

export default useAssetInspections

import React from 'react'
import {
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  isPlatform,
} from '@ionic/react'
import ListSkeleton from 'ui/components/loaders/ListSkeleton'
import './AssetNav.css'
import classNames from 'classnames'

export interface AssetNavProps {
  title?: string
  isLoading?: boolean
  children: any
}

const AssetNav: React.FC<AssetNavProps> = (props) => {
  const { title, isLoading, children } = props
  const isIOS = isPlatform('ios')
  return (
    <IonItemGroup>
      {title ? (
        <IonItemDivider
          sticky
          className={classNames({
            'asset-nav-divider-ios': isIOS,
          })}
        >
          <IonLabel style={{ fontSize: isIOS ? '100%' : '120%' }}>
            {title}
          </IonLabel>
        </IonItemDivider>
      ) : null}
      {isLoading ? <ListSkeleton /> : children}
    </IonItemGroup>
  )
}

export default AssetNav

import { useCallback, useMemo } from 'react'
import { useDispatchContext } from 'context/FormContext'
import useForm from './useForm'
import * as Actions from 'context/FormContext/actions'
import { prefixRemoteUri } from 'services/files'

export function useFiles(fieldName: string, value: any) {
  const { getFile } = useForm()
  const dispatch = useDispatchContext()
  const addFile = useCallback(
    (fieldName: string, file: File) => {
      dispatch(Actions.addFile(fieldName, file))
    },
    [dispatch],
  )
  const removeFile = useCallback(
    (fileId: string, fieldName: string) => {
      dispatch(Actions.removeFile(fieldName, fileId))
    },
    [dispatch],
  )
  const replaceFile = useCallback(
    (fieldName: string, fileId: string, file: File) => {
      dispatch(Actions.replaceFile(fileId, fieldName, file))
    },
    [dispatch],
  )
  const imageValue = useMemo(() => {
    if (!value) return undefined
    if (typeof value === 'string') return getFile(value)?.file
    if (value.uri)
      return {
        ...value,
        uri: prefixRemoteUri(value.uri),
      }
    else return value
  }, [value, getFile])
  const handleImageChange = useCallback(
    (fieldName: string, newValue?: File) => {
      if (!newValue) return value && removeFile(value, fieldName)
      if (value) return replaceFile(fieldName, value, newValue)

      addFile(fieldName, newValue)
    },
    [value, addFile, removeFile, replaceFile],
  )

  return {
    imageValue,
    handleImageChange,
  }
}

export type FileValue =
  | File
  | {
      fieldId: string
      fieldType: string
      name: string
      size: string
      uri: string
    }

export function downloadImage(imageDataURL: string, value: FileValue) {
  const downloadLink = document.createElement('a')
  downloadLink.href = imageDataURL
  downloadLink.download = value.name
  downloadLink.click()
}

export default useFiles

import React, { useState, useCallback, useEffect } from 'react'
import {
  IonList,
  IonToolbar,
  IonButton,
  IonHeader,
  IonContent,
} from '@ionic/react'
import AppLayout from 'ui/layouts/AppLayout'
import ListSkeleton from 'ui/components/loaders/ListSkeleton'
import './Export.css'
import useTranslation from '../../../hooks/useTranslation'
import { Submission, SubmissionType } from 'core/Submission'
import ExportItem from 'components/ExportItem'
import ExportFooter from 'ui/layouts/footers/ExportFooter'
import ConfirmationAlert from 'ui/components/alerts/ConfirmationAlert'
import { EmptyItem } from '../../components/loaders/EmptyItem'
import Order, { SortOptions } from 'ui/components/order/Order'

import ExportTabChip from './ExportTabChip'

export type ExportTab = 'finished' | 'sent' | 'all'

interface ExportProps {
  submissions: Submission[]
  finishedSubmissions: Submission[]
  draftSubmissions: Submission[]
  isFetching?: boolean
  selectedTab: ExportTab
  searchText?: string
  isDownloading: boolean
  sortOptions: SortOptions
  onTabChange: (tab: string) => void
  onRemove: (ids: string[]) => void
  onSearch: (keyword: string) => void
  onExport: (submissionIds: string[]) => void
  onDownload: (submissionIds: string[]) => void
  onSortChange: (sortOptions: SortOptions) => void
}

const Export: React.FC<ExportProps> = (props) => {
  const {
    submissions = [],
    finishedSubmissions = [],
    draftSubmissions = [],
    searchText,
    isFetching,
    selectedTab,
    isDownloading,
    sortOptions,
    onTabChange,
    onRemove,
    onSearch,
    onExport,
    onDownload,
    onSortChange,
  } = props
  const [isSelecting, setIsSelecting] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([])

  const t = useTranslation()

  const handleTabChange = (value: string) => {
    onTabChange(value)
  }

  const handleEditionMode = useCallback(() => {
    setIsSelecting((selecting) => !selecting)
    setSelectedSubmissions([])
  }, [])

  useEffect(() => {
    setIsSelecting(false)
    setSelectedSubmissions([])
  }, [onSearch, selectedTab])

  const handleSelectAsset = useCallback(
    (id: string) => {
      setIsSelecting(true)

      const newSelectedAssets = [...selectedSubmissions]
      const selectedIndex = selectedSubmissions.indexOf(id)

      if (selectedIndex === -1) {
        newSelectedAssets.push(id)
      } else {
        newSelectedAssets.splice(selectedIndex, 1)
      }

      setSelectedSubmissions(newSelectedAssets)
    },
    [selectedSubmissions],
  )

  const handleSelectAll = useCallback(() => {
    const assetsIds = submissions.map((sub) => sub.id)
    setSelectedSubmissions(assetsIds)
  }, [submissions])

  const handleRemove = useCallback(() => {
    onRemove(selectedSubmissions)
    setShowDeleteAlert(false)
  }, [onRemove, selectedSubmissions])

  const handleSearch = useCallback(
    (e: CustomEvent) => {
      onSearch(e.detail.value)
    },
    [onSearch],
  )

  const getHasDependentAsset = useCallback(
    (submission: Submission, submissionsToCheck: Submission[]) => {
      if (submission.type === SubmissionType.AssetRevision) {
        return false
      }

      const assetExists = submissionsToCheck.some((submissionFromList) => {
        const isAsset =
          submissionFromList.type === SubmissionType.AssetRevision ||
          submissionFromList.type === SubmissionType.NewAsset

        if (!isAsset) {
          return false
        }

        return submissionFromList.sourceId === submission.sourceId
      })

      return assetExists
    },
    [],
  )

  const hasSelectedFinishedSubmissions =
    selectedTab === 'finished' && selectedSubmissions.length > 0

  return (
    <AppLayout
      footer={
        isSelecting ? (
          <ExportFooter
            hasDeleteAction={selectedSubmissions.length > 0}
            hasSendAction={hasSelectedFinishedSubmissions}
            hasDownloadAction={selectedSubmissions.length > 0}
            onShowDeleteAlert={() => setShowDeleteAlert(true)}
            onExport={() => onExport(selectedSubmissions)}
            onDownload={() => onDownload(selectedSubmissions)}
            isDownloading={isDownloading}
          />
        ) : null
      }
      pageTitle={t('menu.export')}
      onSearch={handleSearch}
      hideBackLink
      showOffline
    >
      {
        <ConfirmationAlert
          isDestructive
          isOpen={showDeleteAlert}
          onDidDismiss={() => setShowDeleteAlert(false)}
          title={t('buttons.delete')}
          description={t('confirmation.deleteSubmission')}
          cancelText={t('buttons.cancelSelection')}
          acceptText={t('buttons.delete')}
          onAccept={handleRemove}
        />
      }
      {isFetching ? (
        <ListSkeleton />
      ) : (
        <>
          <IonHeader className="export-header">
            <IonToolbar className="export-chips">
              <div className="export-row">
                <ExportTabChip
                  value="all"
                  selectedValue={selectedTab}
                  label={t('export.all')}
                  onClick={handleTabChange}
                />

                <ExportTabChip
                  value="finished"
                  selectedValue={selectedTab}
                  label={t('export.finished')}
                  onClick={handleTabChange}
                />

                <ExportTabChip
                  value="sent"
                  selectedValue={selectedTab}
                  label={t('export.sent')}
                  onClick={handleTabChange}
                />

                <div className="export-order">
                  <Order
                    sortOptions={sortOptions}
                    onChange={onSortChange}
                    isSmall
                  />
                </div>
              </div>
            </IonToolbar>

            {submissions.length > 0 && (
              <IonToolbar>
                {isSelecting ? (
                  <IonButton
                    fill="clear"
                    slot="start"
                    size="small"
                    onClick={handleSelectAll}
                  >
                    {t('select.all')}
                  </IonButton>
                ) : null}

                <IonButton
                  fill="clear"
                  slot="end"
                  className="asset-card-download-button"
                  onClick={handleEditionMode}
                >
                  {isSelecting ? t('buttons.cancel') : t('buttons.select')}
                </IonButton>
              </IonToolbar>
            )}
          </IonHeader>
          <IonContent className="export-content">
            <IonList>
              {submissions.length > 0 ? (
                submissions.map((submission) => {
                  return (
                    <ExportItem
                      key={submission.id}
                      searchQuery={searchText}
                      submission={submission}
                      isEditionMode={isSelecting}
                      isSelected={selectedSubmissions.includes(submission.id)}
                      onSelect={() => handleSelectAsset(submission.id)}
                      hasUnsubmittedAsset={getHasDependentAsset(
                        submission,
                        finishedSubmissions,
                      )}
                      hasDraftAsset={getHasDependentAsset(
                        submission,
                        draftSubmissions,
                      )}
                    />
                  )
                })
              ) : (
                <EmptyItem
                  text={t('export.noDataFound')}
                  secondaryText={t('export.noDataFound.description')}
                />
              )}
            </IonList>
          </IonContent>
        </>
      )}
    </AppLayout>
  )
}

export default Export

import React from 'react'

export interface FormProps {
  children: any
  onSubmit: () => void
}

const Form: React.FC<FormProps> = ({ children, onSubmit }) => {
  return <form onSubmit={onSubmit}>{children}</form>
}

export default Form

import { useMutation, queryCache } from 'react-query'
import { removeSubmission as removeSubmissionDB } from 'services/submissions'
import useToast from 'hooks/useToast'
import useTranslation from 'hooks/useTranslation'
import { CacheKeys } from './common'

const remove = async (submissionId: string) => {
  await removeSubmissionDB(submissionId)
  return submissionId
}

export default function useRemoveSubmission() {
  const Toast = useToast()
  const t = useTranslation()

  const [mutateRemove, { isLoading: isFetchingRemove }] = useMutation(remove, {
    onSuccess: (submissionId) => {
      Toast.success(t('notifications.deleteSuccess'))
      queryCache.invalidateQueries(CacheKeys.SubmissionByStatus)
      queryCache.invalidateQueries([CacheKeys.Submission, submissionId])
    },
    onError: (err) => {
      Toast.error(t('notifications.deleteError'))
    },
  })

  const removeSubmission = async (submissionId: string) => {
    await mutateRemove(submissionId)
  }

  return {
    removeSubmission,
    isFetching: isFetchingRemove,
  }
}

import React from 'react'
import { IonActionSheet } from '@ionic/react'
import 'ui/components/modals/Popover.css'
import useTranslation from 'hooks/useTranslation'

export interface SaveMenuProps {
  isOpen: boolean
  onDidDismiss: () => void
  onSave: () => void
  onSaveAndFinish: () => void
}

const SaveMenu: React.FC<SaveMenuProps> = (props) => {
  const { isOpen, onDidDismiss, onSave, onSaveAndFinish } = props
  const t = useTranslation()
  return (
    <IonActionSheet
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      buttons={[
        {
          text: t('buttons.save'),
          handler: onSave,
        },
        {
          text: t('buttons.saveAndFinish'),
          handler: onSaveAndFinish,
        },
      ]}
    />
  )
}

export default SaveMenu

import React from 'react'
import { IonFooter, IonToolbar, IonButton } from '@ionic/react'

import useTranslation from 'hooks/useTranslation'

import { FORM_ID } from './constants'

interface FooterProps {
  onReset: () => void
}

const Footer: React.FC<FooterProps> = (props) => {
  const { onReset } = props

  const t = useTranslation()

  return (
    <IonFooter>
      <IonToolbar className="import-by-attributes-footer-toolbar">
        <IonButton
          slot="start"
          fill="outline"
          form={FORM_ID}
          type="reset"
          onClick={onReset}
        >
          {t('action.removeFilters')}
        </IonButton>
        <IonButton slot="end" form={FORM_ID} type="submit">
          {t('action.viewResults')}
        </IonButton>
      </IonToolbar>
    </IonFooter>
  )
}

export default Footer

import React from 'react'
import cx from 'classnames'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import './FormField.css'
import HintImage from '../fields/HintImage'

export interface FormFieldProps {
  label?: string
  labelPosition?: 'stacked' | 'floating' | 'fixed' | undefined
  hint?: string
  classnames?: string
  errorText?: string
  requiredText?: string
  hasError?: boolean
  isRequired?: boolean
  isLabelInline?: boolean
  isLabelSmall?: boolean
  isClickable?: boolean
  tabIndex?: number
  fileId?: string
  onClick?: () => void
  readOnly?: boolean
}

const FormField: React.FC<React.PropsWithChildren<FormFieldProps>> = ({
  label,
  labelPosition = 'stacked',
  hint,
  classnames,
  errorText,
  requiredText,
  hasError,
  isRequired,
  isLabelInline,
  isLabelSmall = true,
  isClickable,
  onClick,
  readOnly,
  tabIndex,
  fileId,
  children,
}) => {
  const labelClasses = cx('ion-text-wrap', {
    'label-inline': isLabelInline,
    'label-small': isLabelSmall,
  })

  return (
    <>
      <IonItem
        lines="full"
        detail={false}
        className={`form-field-wrapper ${classnames || ''}`}
        button={isClickable ? true : false}
        onClick={onClick}
        tabIndex={tabIndex}
        // NOTE disable focus animation and highlight (for text and numeric
        // inputs), changes (for toggles) and open handlers (for select,
        // datepicker) when read only
        style={readOnly ? { pointerEvents: 'none' } : undefined}
      >
        <div className={`hint-image ${classnames || ''}`}>
          {label ? (
            <IonLabel
              position={labelPosition}
              className={labelClasses}
              color={hasError ? 'danger' : 'default'}
            >
              {label}
              {isRequired && requiredText ? ` (${requiredText})` : null}
            </IonLabel>
          ) : null}
          {fileId && <HintImage fileId={fileId} />}

          {hint ? (
            <IonText color="medium" className="form-field-hint">
              {hint
                .split('\n')
                .filter((l) => !!l)
                .map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
            </IonText>
          ) : null}
          {children}
        </div>
      </IonItem>
      {hasError && errorText ? (
        <IonText color="danger">
          <p className="form-field-error ion-padding-horizontal">{errorText}</p>
        </IonText>
      ) : null}
    </>
  )
}

export default FormField

import { useState, useCallback } from 'react'

const useForceUpdate = () => {
  const [, setState] = useState<number>()

  return useCallback(() => {
    setState(Math.random())
  }, [])
}

export default useForceUpdate

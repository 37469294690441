import React from 'react'
import { IonText } from '@ionic/react'
import './FormError.css'

export interface FormErrorProps {
  text: string
}

const FormError: React.FC<FormErrorProps> = ({ text }) => {
  return (
    <div className="form-error ion-margin">
      <IonText color="danger">
        <p className="form-error-text ion-padding-horizontal">{text}</p>
      </IonText>
    </div>
  )
}

export default FormError

import React from 'react'
import { IonActionSheet } from '@ionic/react'
import useTranslation from 'hooks/useTranslation'
import {
  cameraOutline,
  closeOutline,
  downloadOutline,
  expandOutline,
} from 'ionicons/icons'

const FileActionButtons: React.FC<{
  open: boolean
  value?:
    | File
    | {
        fieldId: string
        fieldType: string
        name: string
        size: string
        uri: string
      }
  readOnly?: boolean
  onDownload: () => void
  onDelete: () => void
  onModifyClick: () => void
  onPreviewClick: () => void
  onDismiss: () => void
}> = ({
  open,
  value,
  readOnly,
  onDownload,
  onDelete,
  onModifyClick,
  onPreviewClick,
  onDismiss,
}) => {
  const t = useTranslation()
  const buttons = [
    {
      text: t('buttons.viewImage'),
      icon: expandOutline,
      handler: () => onPreviewClick(),
    },
  ]

  if (!readOnly) {
    buttons.push(
      {
        text: t('buttons.modify'),
        icon: cameraOutline,
        handler: onModifyClick,
      },
      {
        text: t('buttons.save'),
        icon: downloadOutline,
        handler: onDownload,
      },
      {
        text: t('buttons.delete'),
        icon: closeOutline,
        handler: onDelete,
      },
    )
  }

  return (
    <IonActionSheet
      isOpen={open}
      onDidDismiss={onDismiss}
      header={value?.name}
      buttons={buttons}
    />
  )
}

export default FileActionButtons

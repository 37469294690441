import React from 'react'

import { ContentField, FieldType } from 'core/ContentField'

import BooleanFilterField from './BooleanFilterField'
import TextFilterField from './TextFilterField'
import NumericFilterField from './NumericFilterField'
import SelectFilterField from './SelectFilterField'
import DatetimeFilterField from './DatetimeFilterField'

interface FilterFieldProps {
  fieldModel: ContentField
}

const FilterField: React.FC<FilterFieldProps> = (props) => {
  const { fieldModel } = props

  switch (fieldModel.type) {
    case FieldType.Boolean:
      return <BooleanFilterField fieldModel={fieldModel} />
    case FieldType.Text:
      return <TextFilterField fieldModel={fieldModel} />
    case FieldType.Numeric:
      return <NumericFilterField fieldModel={fieldModel} />
    case FieldType.Select:
      return <SelectFilterField fieldModel={fieldModel} />
    case FieldType.Datetime:
      return <DatetimeFilterField fieldModel={fieldModel} />
    default:
      break
  }

  return null
}

export default FilterField

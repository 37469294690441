import React, { useMemo } from 'react'
import {
  IonItemSliding,
  IonItem,
  IonLabel,
  IonItemOptions,
  IonItemOption,
  IonIcon,
  IonBadge,
} from '@ionic/react'
import { trashOutline, duplicateOutline } from 'ionicons/icons'

export interface LinkFieldNavItemProps {
  title: string | React.ReactElement
  childrenNumber?: number
  onClick: () => void
  onDeleteLink?: () => void
  onDuplicateLink?: () => void
  allowDuplicate?: boolean
  readOnly?: boolean
}

const LinkFieldNavItem: React.FC<LinkFieldNavItemProps> = ({
  readOnly,
  title,
  childrenNumber,
  onClick,
  onDeleteLink,
  onDuplicateLink,
  allowDuplicate = false,
}) => {
  const ItemOptions = useMemo(() => {
    if (readOnly || (!onDeleteLink && !allowDuplicate)) {
      return null
    }

    return (
      <IonItemOptions side="end">
        {allowDuplicate && (
          <IonItemOption color="primary" onClick={onDuplicateLink}>
            <IonIcon icon={duplicateOutline} size="large" />
          </IonItemOption>
        )}
        {onDeleteLink && (
          <IonItemOption color="danger" onClick={onDeleteLink}>
            <IonIcon icon={trashOutline} size="large" />
          </IonItemOption>
        )}
      </IonItemOptions>
    )
  }, [readOnly, onDeleteLink, onDuplicateLink, allowDuplicate])

  return (
    <IonItemSliding>
      <IonItem lines="full" button detail onClick={onClick}>
        <IonLabel className="ion-text-wrap">{title}</IonLabel>
        {childrenNumber != null && (
          <IonBadge slot="end" color="primary">
            {childrenNumber}
          </IonBadge>
        )}
      </IonItem>
      {ItemOptions}
    </IonItemSliding>
  )
}

export default LinkFieldNavItem

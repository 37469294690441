import { Layouts, Models } from './db'
import {
  LayoutConfig,
  LinearLayoutConfig,
  isEditLayout,
  isIDLayout,
} from 'core/Layout'
import { ContentField, FieldType } from 'core/ContentField'

const SUPPORTED_ID_FIELD_TYPES = [
  FieldType.Boolean,
  FieldType.Text,
  FieldType.Numeric,
  FieldType.Select,
  FieldType.Datetime,
]

export const getIdFields = async (
  modelId: string,
): Promise<Array<ContentField> | undefined> => {
  const _layout = await Layouts.where({
    assetTypeId: modelId,
    name: 'ID',
  }).first()

  if (!_layout) return

  // NOTE all layouts with `name` equal to `ID` are linear
  const layout = _layout as LinearLayoutConfig

  const model = await Models.get(modelId)

  if (!model) return

  const fields = model.fields
    .filter((field) => layout.fields.includes(field._id))
    // NOTE filter fields of unsupported types
    .filter((field) => SUPPORTED_ID_FIELD_TYPES.includes(field.type))
    // NOTE sort fields according to layout
    .sort(
      (field1, field2) =>
        layout.fields.indexOf(field1._id) - layout.fields.indexOf(field2._id),
    )

  return fields
}

export async function getLayoutForModel(
  modelId: string,
  type: 'edit' | 'id' = 'edit',
): Promise<LayoutConfig | undefined> {
  return Layouts.where('assetTypeId')
    .equals(modelId)
    .filter(type === 'id' ? isIDLayout : isEditLayout)
    .last()
    .then((res) => {
      if (res) return res

      return Models.get(modelId).then((model) => {
        const layout: LinearLayoutConfig = {
          id: 'asset-details',
          name: 'details',
          assetTypeId: modelId,
          type: 'linear',
          fields: model?.fields.map((f) => f._id) || [],
        }
        return layout
      })
    })
    .catch((err) => {
      console.error('Error retrieving layout from IDB for model ' + modelId)
      throw err
    })
}

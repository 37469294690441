import React, { useRef, useCallback, useState, useEffect } from 'react'
import { IonText, IonImg, IonIcon, IonButton, IonSpinner } from '@ionic/react'
import { cameraOutline, imagesOutline } from 'ionicons/icons'
import FormField from '../forms/FormField'
import './ImageUploader.css'
import { FieldProps } from './common'
import useTranslation from 'hooks/useTranslation'
import { fetchRemoteFile } from 'services/network/api'
import useToast from 'hooks/useToast'
import offlinePlaceholder from 'logos/offline.png'
import useLocalConfiguration from 'hooks/useLocalConfiguration'
import FullImagePreview from './FullImagePreview'
import FileActionButtons from './FileActions'
import compressImage from 'common/compressImage'

const ALLOWED_IMAGE_EXTENSIONS = 'image/*;capture=camera'

export interface ImageUploaderProps extends FieldProps {
  value?:
    | File
    | {
        fieldId: string
        fieldType: string
        name: string
        size: string
        uri: string
      }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const readFileAsBase64 = async (uploadedFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(uploadedFile)
  })
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  readOnly,
  name,
  label,
  hint,
  isRequired,
  hasError,
  errorText,
  onChange,
  value,
  tabIndex,
  fileId,
}) => {
  const t = useTranslation()
  const Toast = useToast()
  const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const { config } = useLocalConfiguration()
  const [imageDataURL, saveImageDataURL] = useState<string>()
  const [actionsVisible, setActionsVisible] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)

  const [remoteImageState, setRemoteImageState] = useState({
    isFetching: false,
    error: false,
  })

  const cancelController = useRef(new AbortController())

  const handleFileInputClick = useCallback(
    () => inputFileRef.current.click(),
    [inputFileRef],
  )

  const handleUpload = useCallback(
    async (e: any) => {
      const file = e.target.files[0]
      if (file) {
        const reducedFile = await compressImage(file)

        onChange(name, reducedFile)
        const imageDataURL = URL.createObjectURL(reducedFile)
        saveImageDataURL(imageDataURL)
        Boolean(config.autoDownloadImages) &&
          downloadImage(imageDataURL, reducedFile)
      }
    },
    [onChange, name, config.autoDownloadImages],
  )

  const handleDeleteFile = useCallback(() => {
    onChange(name, undefined)
    saveImageDataURL(undefined)
  }, [name, onChange])

  const handleDownload = useCallback(() => {
    if (imageDataURL != null && value != null) {
      downloadImage(imageDataURL, value)
    }
  }, [imageDataURL, value])

  // load image as ObjectURL
  useEffect(() => {
    if (!value) return
    if (value instanceof File) {
      const url = URL.createObjectURL(value)
      saveImageDataURL(url)
    } else {
      if (value.uri) {
        console.log('Got remote file uri', value.uri)
        setRemoteImageState({
          isFetching: true,
          error: false,
        })
        fetchRemoteFile(value.uri, cancelController.current.signal)
          .then((x: any) => URL.createObjectURL(x))
          //.then(saveImageDataURL)
          .then((url) => {
            saveImageDataURL(url)
            setRemoteImageState({
              isFetching: false,
              error: false,
            })
          })
          .catch((err: Error) => {
            if (err.name !== 'AbortError') {
              setRemoteImageState({
                isFetching: false,
                error: true,
              })
              Toast.warning(
                'No se ha podido cargar la imagen del servidor',
                3000,
              )
            }
          })
      }
    }
    // remote files
  }, [value, Toast])
  // revoke (clean memory)
  useEffect(() => {
    let controller = cancelController.current
    return () => {
      if (imageDataURL) {
        URL.revokeObjectURL(imageDataURL)
      }
      if (controller) {
        controller.abort()
      }
    }
  }, [imageDataURL])

  return (
    <FormField
      label={label}
      hint={hint}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      tabIndex={tabIndex}
      fileId={fileId}
    >
      {imageDataURL ? (
        <div className="image-uploader-trigger">
          <p
            className="image-uploader-picture"
            onClick={() => setActionsVisible(true)}
          >
            <IonImg
              src={imageDataURL}
              style={{ maxHeight: '300px' }}
              onScroll={console.log}
            />
          </p>
        </div>
      ) : (
        <div className="image-uploader-trigger">
          <IonButton
            className="image-uploader-button"
            fill="clear"
            onClick={handleFileInputClick}
            disabled={readOnly}
          >
            <IonText className="image-uploader-placeholder">
              {!remoteImageState.error && (
                <IonIcon
                  icon={readOnly ? imagesOutline : cameraOutline}
                  size="large"
                  className="ion-margin-vertical"
                />
              )}
              {remoteImageState.error ? (
                <IonImg src={offlinePlaceholder} />
              ) : (
                <IonText className="image-uploader-placeholder-text">
                  {remoteImageState.isFetching ? (
                    <IonSpinner />
                  ) : readOnly ? (
                    t('withoutValue')
                  ) : (
                    t('buttons.takePicture')
                  )}
                </IonText>
              )}
            </IonText>
          </IonButton>
        </div>
      )}

      <input
        type="file"
        className="hidden-input-file"
        ref={inputFileRef}
        onChange={handleUpload}
        accept={ALLOWED_IMAGE_EXTENSIONS}
        hidden
      />
      <FileActionButtons
        open={actionsVisible}
        value={value}
        readOnly={readOnly}
        onDownload={handleDownload}
        onDelete={handleDeleteFile}
        onModifyClick={handleFileInputClick}
        onPreviewClick={() => setPreviewVisible(true)}
        onDismiss={() => setActionsVisible(false)}
      />
      {value && (
        <FullImagePreview
          isOpen={previewVisible}
          title={value.name}
          onClose={() => setPreviewVisible(false)}
          src={imageDataURL}
        />
      )}
    </FormField>
  )
}

export default ImageUploader
function downloadImage(
  imageDataURL: string,
  value:
    | File
    | {
        fieldId: string
        fieldType: string
        name: string
        size: string
        uri: string
      },
) {
  const downloadLink = document.createElement('a')
  downloadLink.href = imageDataURL
  downloadLink.download = value.name
  downloadLink.click()
}

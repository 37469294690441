import { useState, useCallback, useRef, useEffect } from 'react'

import useTranslation from './useTranslation'
import useToast from './useToast'
import useLocalConfiguration from './useLocalConfiguration'

import { GeoLocation } from 'core/Geolocation'

import { GEOLOCATION_OPTIONS } from 'configuration'

const getGeoLocation = (coordinates: GeolocationCoordinates): GeoLocation => {
  const { latitude, longitude, altitude, accuracy } = coordinates
  return { latitude, longitude, altitude, accuracy }
}

const useCurrentLocation = (
  onSuccess?: (result: GeoLocation) => void,
  onError?: (error: GeolocationPositionError) => void,
) => {
  const t = useTranslation()
  const Toast = useToast()

  const {
    config: { gpsTimeout },
  } = useLocalConfiguration()

  const isMounted = useRef(true)
  const [location, setLocation] = useState<GeoLocation>()

  const isFetching = useRef(false)
  const [error, setError] = useState<GeolocationPositionError | undefined>()

  useEffect(() => {
    // NOTE make sure we recognize the component is mounted when hot reloading,
    // otherwise just the cleanup effect will run
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleSuccess = useCallback(
    (position: GeolocationPosition) => {
      if (!isMounted.current) return

      const geolocation = getGeoLocation(position.coords)

      if (onSuccess) onSuccess(geolocation)
      else setLocation(geolocation)

      isFetching.current = false
    },
    [onSuccess],
  )

  const handleError = useCallback(
    (error: GeolocationPositionError) => {
      if (!isMounted.current) return

      if (onError) {
        onError(error)
      }

      let errorDetails = 'Unknown error'
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorDetails = t('notifications.permisisonDenied')
          break
        case error.POSITION_UNAVAILABLE:
          errorDetails = t('notifications.positionUnavailable')
          break
        case error.TIMEOUT:
          errorDetails = t('notifications.operationTimedOut')
          break
      }

      Toast.error('GPS error: ' + errorDetails)

      setError(error)

      isFetching.current = false
    },
    [Toast, onError, t],
  )

  const getCurrentLocation = useCallback(() => {
    if (!navigator.geolocation) {
      Toast.error(t('notifications.gpsLocationNotAvailable'))
      return
    }

    if (isFetching.current) return
    isFetching.current = true

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
      ...GEOLOCATION_OPTIONS,
      timeout: gpsTimeout,
    })
  }, [Toast, handleError, handleSuccess, gpsTimeout, t])

  const watchLocation = useCallback(() => {
    if (!navigator.geolocation) {
      Toast.error(t('notifications.gpsLocationNotAvailable'))
      return
    }

    // NOTE do not set as loading nor provide error callback when watching
    const id = navigator.geolocation.watchPosition(handleSuccess, undefined, {
      ...GEOLOCATION_OPTIONS,
      timeout: gpsTimeout,
    })

    return () => navigator.geolocation.clearWatch(id)
  }, [Toast, t, gpsTimeout, handleSuccess])

  return {
    location,
    isFetching: isFetching.current,
    error,
    getCurrentLocation,
    watchLocation,
  }
}

export default useCurrentLocation

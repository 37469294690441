import * as React from 'react'
import Login from 'ui/views/Login/Login'
import useUser from 'hooks/useUser'
import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router'

export default function LoginPage() {
  const { login, isLoginLoading, loginError, isLogged } = useUser()
  const t = useTranslation()
  const { replace } = useHistory()

  React.useEffect(() => {
    if (isLogged && !isLoginLoading) {
      replace('/')
    }
  }, [isLogged, replace, isLoginLoading])

  return (
    <Login
      login={login}
      isLoginLoading={isLoginLoading}
      loginError={loginError}
      signInText={t('buttons.login')}
      usernameLabel={t('login.username')}
      passwordLabel={t('login.password')}
    />
  )
}

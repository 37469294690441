import React from 'react'
import { IonButton, IonIcon, IonLabel } from '@ionic/react'
import cx from 'classnames'
import './RoundButton.css'

export interface RoundButtonProps {
  mainText?: string
  icon?: string | React.FC<{ className?: string; slot?: string }>
  fill?: string
  color?: string
  routerLink?: string
  disabled?: boolean
  onClick?: () => void
  size?: 'small' | 'medium' | 'large'
  className?: string
}

const RoundButton: React.FC<RoundButtonProps> = ({
  mainText,
  icon,
  fill = 'solid',
  color = 'primary',
  routerLink,
  disabled,
  onClick,
  size = 'large',
  className,
}) => {
  return (
    <div className={`round-button-container ${className}`}>
      <IonButton
        shape="round"
        slot="icon-only"
        color={color}
        fill={fill === 'outline' ? 'outline' : 'solid'}
        className={cx(`round-button is-${size}`)}
        routerLink={routerLink}
        disabled={disabled}
        onClick={onClick}
      >
        {typeof icon === 'string' ? (
          <IonIcon icon={icon} className="round-button-icon" />
        ) : (
          icon && React.createElement(icon, { className: 'round-button-icon' })
        )}
      </IonButton>
      {mainText ? (
        <IonLabel className="round-button-label">{mainText}</IonLabel>
      ) : null}
    </div>
  )
}

export default RoundButton

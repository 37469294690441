import React from 'react'

import SavedAssetCard from 'ui/components/cards/SavedAssetCard'
import { Submission, SubmissionStatus } from 'core/Submission'
import useInventorySubmission from './hooks/useInventorySubmission'
import ItemSkeleton from 'ui/components/loaders/ItemSkeleton'
import useInspectionSubmission from './hooks/useInspectionSubmission'
import { ModelTypes } from 'core/ContentModel'
import formatDate from 'common/formatDate'

interface HomeItemProps {
  submission: Submission
  onSelect?: () => void
}

interface HomeItemPropsExtended extends HomeItemProps {
  isUploaded: boolean
  updatedAt?: string
}

export const HomeInventoryItem: React.FC<HomeItemPropsExtended> = (props) => {
  const { submission, onSelect, updatedAt = '', isUploaded } = props

  const { modelTitle, title, isLoading } = useInventorySubmission(submission)

  if (isLoading) return <ItemSkeleton />

  return (
    <SavedAssetCard
      title={title || ''}
      assetType={modelTitle}
      assetDetails={updatedAt}
      onSelect={onSelect}
      isUploaded={isUploaded}
      hasLocation={submission.location != null}
      status={submission.status}
    />
  )
}

export const HomeInspectionItem: React.FC<HomeItemPropsExtended> = (props) => {
  const { submission, onSelect, updatedAt = '', isUploaded } = props
  const { modelTitle, title, isLoading } = useInspectionSubmission(submission)

  if (isLoading) return <ItemSkeleton />

  return (
    <SavedAssetCard
      title={title || ''}
      assetType={modelTitle}
      assetDetails={updatedAt}
      onSelect={onSelect}
      isUploaded={isUploaded}
      status={submission.status}
    />
  )
}

export default function HomeItem(props: HomeItemProps) {
  const updatedAt = props.submission.lastUpdatedAt
    ? formatDate(props.submission.lastUpdatedAt)
    : '-'

  const isUploaded = props.submission.status === SubmissionStatus.uploaded

  switch (props.submission.modelType) {
    case ModelTypes.asset:
      return (
        <HomeInventoryItem
          {...props}
          updatedAt={updatedAt}
          isUploaded={isUploaded}
        />
      )
    case ModelTypes.inspection:
    case ModelTypes.intervention:
      return (
        <HomeInspectionItem
          {...props}
          updatedAt={updatedAt}
          isUploaded={isUploaded}
        />
      )
    default: {
      console.warn('Unknown submission model type', props.submission.modelType)
      return null
    }
  }
}

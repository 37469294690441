import React, { useRef, useCallback, useMemo } from 'react'
import {
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  isPlatform,
} from '@ionic/react'

import { close, swapVerticalOutline } from 'ionicons/icons'
import cx from 'classnames'

import useTranslation from 'hooks/useTranslation'

import './Order.css'

const MODAL_TRIGGER_ID = 'order-modal'
const FORM_ID = 'order-modal-form'

export type SortOptions = Record<
  string,
  { enabled: boolean; direction: string }
>

interface OrderProps {
  sortOptions: SortOptions
  isSmall?: boolean
  onChange: (options: SortOptions) => void
}

const Order: React.FC<OrderProps> = ({ sortOptions, isSmall, onChange }) => {
  const t = useTranslation()
  const modalRef = useRef<HTMLIonModalElement>(null)

  const handleClose = useCallback(() => {
    modalRef.current?.dismiss()
  }, [])

  const handleSortOptionChange = useCallback(
    (option: string, enabled: boolean) => {
      const newSortOptions: SortOptions = Object.keys(sortOptions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...sortOptions[key],
            enabled: key === option ? enabled : false,
            direction:
              key === option && enabled ? sortOptions[key].direction : 'asc',
          },
        }),
        {},
      )

      onChange(newSortOptions)
    },
    [onChange, sortOptions],
  )

  const handleSortDirectionChange = useCallback(
    (option: string, direction: string) => {
      onChange({
        ...sortOptions,
        [option]: { ...sortOptions[option], direction },
      })
    },
    [onChange, sortOptions],
  )

  const isOrderApplied = useMemo(
    () => Object.values(sortOptions).some((option) => option.enabled),
    [sortOptions],
  )

  return (
    <>
      <IonButton
        id={MODAL_TRIGGER_ID}
        size="small"
        fill={isSmall ? 'clear' : 'outline'}
        className="ion-no-margin"
      >
        {!isSmall ? t('order') : null}
        {isOrderApplied ? <div className="takeData-applied-icon"></div> : null}
        <IonIcon
          className={cx({ 'order-small-button': isSmall })}
          slot="end"
          icon={swapVerticalOutline}
        />
      </IonButton>
      <IonModal ref={modalRef} trigger={MODAL_TRIGGER_ID}>
        <IonHeader
          className={cx('order-modal-header', {
            'order-modal-header-ios': isPlatform('ios'),
          })}
        >
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                slot="start"
                fill="clear"
                className="ion-margin-start"
                onClick={handleClose}
              >
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>{t('order')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding-top ion-padding-bottom">
          <form id={FORM_ID}>
            <IonList lines="none">
              {Object.keys(sortOptions).map((option) => (
                <IonItem key={option}>
                  <div className="order-modal-fieldset">
                    <IonCheckbox
                      labelPlacement="end"
                      justify="start"
                      checked={sortOptions[option].enabled}
                      onIonChange={(ev) =>
                        handleSortOptionChange(option, ev.detail.checked)
                      }
                      className="order-modal-checkboxItem"
                    >
                      {t(`sort.${option}`)}
                    </IonCheckbox>
                    <IonRadioGroup
                      value={sortOptions[option].direction}
                      className="order-modal-radioGroup"
                      onIonChange={(e) =>
                        handleSortDirectionChange(option, e.detail.value)
                      }
                    >
                      <IonItem className="order-modal-radioItem no-divider">
                        <IonLabel className="order-modal-radioLabel">
                          {t('sort.ascending')}
                        </IonLabel>
                        <IonRadio
                          labelPlacement="end"
                          slot="start"
                          value="asc"
                          disabled={!sortOptions[option].enabled}
                          className="order-modal-radioButton"
                        />
                      </IonItem>
                      <IonItem className="order-modal-radioItem">
                        <IonLabel className="order-modal-radioLabel">
                          {t('sort.descending')}
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          value="desc"
                          disabled={!sortOptions[option].enabled}
                          className="order-modal-radioButton"
                        />
                      </IonItem>
                    </IonRadioGroup>
                  </div>
                </IonItem>
              ))}
            </IonList>
          </form>
        </IonContent>
      </IonModal>
    </>
  )
}

export default Order

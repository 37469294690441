import React from 'react'
import { IonText } from '@ionic/react'

import './AppVersion.css'

import pkgJSON from '../../../../package.json'

// Extend the type of `pkgJSON` to include `timestamp`
interface PackageJSON {
  version: string
  timestamp?: string
  [key: string]: any
}

const { version, timestamp }: PackageJSON = pkgJSON

const formatVersionDate = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
}

const AppVersion: React.FC = () => {
  return (
    <p className="app-version">
      <IonText>
        v {version}
        {timestamp ? ` - ${formatVersionDate(timestamp)}` : ''}
      </IonText>
    </p>
  )
}

export default AppVersion

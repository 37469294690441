import React from 'react'
import { IonList, IonInput } from '@ionic/react'
import Form from './Form'
import './LoginForm.css'
import FormField from './FormField'
import FormError from './FormError'

export interface LoginFormProps {
  error?: string
  usernameLabel?: string
  passwordLabel?: string
  onUsernameChange: (e: CustomEvent) => void
  onPasswordChange: (e: CustomEvent) => void
  onSubmit: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({
  error,
  onUsernameChange,
  onPasswordChange,
  onSubmit,
  usernameLabel = 'Usuario',
  passwordLabel = 'Contraseña',
}) => {
  return (
    <div className="login-form-wrapper">
      <Form onSubmit={onSubmit}>
        <IonList>
          <FormField label={usernameLabel} isRequired>
            <IonInput
              id="username"
              aria-label="username"
              autocomplete="off"
              onIonChange={onUsernameChange}
            />
          </FormField>
          <FormField label={passwordLabel} isRequired>
            <IonInput
              id="password"
              aria-label="password"
              type="password"
              onIonChange={onPasswordChange}
            />
          </FormField>
        </IonList>
        {error ? (
          <div className="ion-padding-vertical">
            <FormError text={error} />
          </div>
        ) : null}
      </Form>
    </div>
  )
}

export default LoginForm

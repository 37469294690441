import generateId from 'common/generateId'
import { ModelSummary } from 'core/ModelSummary'
import { append, assoc, lensPath, omit, over } from 'ramda'
import { ChildSubmission, ChildSubmissions, SubmissionData } from './reducer'

export function transformLinkFields(
  data: SubmissionData,
  modelSummary: ModelSummary,
) {
  return modelSummary.linkFields.reduce(
    (
      acc: {
        linkName: string
        sourceId: string
        newId: string
      }[],
      linkName,
    ) => {
      if (data[linkName] as string[]) {
        data[linkName].forEach((sourceId: string) =>
          acc.push({
            linkName,
            sourceId,
            newId: generateId(),
          }),
        )
      }
      return acc
    },
    [],
  )
}

function dataWithOutOmitedFields(
  data: SubmissionData,
  modelSummary: ModelSummary,
) {
  const { fileFields = [], linkFields } = modelSummary
  return omit([...fileFields, ...linkFields], data)
}

export function duplicateChild(
  submission: ChildSubmission,
  newId: string,
  ancestors: string[],
  modelSummary: ModelSummary,
) {
  const linkData = transformLinkFields(submission.data, modelSummary)
  let newChild: ChildSubmission = {
    id: newId,
    modelId: submission.modelId,
    parentField: submission.parentField,
    ancestors: ancestors,
    data: dataWithOutOmitedFields(submission.data, modelSummary),
    meanings: submission.meanings,
    sourceId: submission.sourceId,
    sourceModelId: submission.sourceModelId,
  }
  if (linkData.length > 0) {
    linkData.forEach((l) => {
      newChild = over(lensPath(['data', l.linkName]), append(l.newId), newChild)
    })
  }
  return {
    newChild,
    nextChildren: linkData.map(assoc('ancestors', append(newId, ancestors))),
  }
}

export default function duplicateChildren(
  childId: string,
  children: ChildSubmissions,
  getModelSummary: (modelId: string) => ModelSummary,
) {
  const newId = generateId()
  const submission = children[childId]
  const ancestors = submission.ancestors
  let duplicatedChildren = [] as ChildSubmission[]

  const { nextChildren, newChild } = duplicateChild(
    submission,
    newId,
    ancestors,
    getModelSummary(submission.modelId),
  )
  let childrenToDuplicate = nextChildren

  duplicatedChildren.push(newChild)
  for (let nextChild of childrenToDuplicate) {
    let submissionToDuplicate = children[nextChild.sourceId]
    const { nextChildren, newChild } = duplicateChild(
      submissionToDuplicate,
      nextChild.newId,
      nextChild.ancestors,
      getModelSummary(submissionToDuplicate.modelId),
    )
    duplicatedChildren.push(newChild)
    childrenToDuplicate.push(...nextChildren)
  }

  return {
    newId,
    ancestors,
    parentField: submission.parentField,
    duplicatedChildren,
  }
}

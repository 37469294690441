import { last, pathOr } from 'ramda'
import { getLocalizedProperty } from './common'
import { LinkField } from './ContentField'
import { ContentModel, extractTitle, getFieldByName } from './ContentModel'
import { Location } from './Geolocation'
import { SubmissionType } from './Submission'

// NOTE as provided by the `/search-id` endpoint
export interface MinimalAsset {
  id: string
  title: string
  lastUpdated: string
}

export enum AssetType {
  Remote = 'remote',
  Local = 'local',
}

// NOTE as provided by the `/search/assetView` endpoint
export interface AssetView {
  id: string
  location: Location
  data: {
    [key: string]: string
  }
  createdAt: string
  updatedAt: string
  createdByUser: string
}

// NOTE as provided by the `/fullAssets/{id}` endpoint
export interface FullAsset {
  id: string
  location: Location
  label?: string
  type?: string
  assetType: string
  ancestors?: string[]
  data: {
    [field: string]: any
  }
  createdAt?: string
  updatedAt?: string
  subAssets: {
    [assetId: string]: ChildAsset
  }
  downloadedAt?: Date
  /**
   * Points to a local Submission with updates to this asset
   */
  revisionSubmissionId?: string
  /** If present, specific inventory version */
  inventoryId?: string
  meanings?: any
  category?: AssetType
  status?: SubmissionType
}

export interface ChildAsset {
  _id: string
  assetType: string
  ancestors: string[]
  parentField: string
  data: { [field: string]: any }
  meanings?: { [title: string]: any }
}

export function makeBreadCrumb(
  getAsset: (assetId: string) => {
    assetType: string
    data: { [field: string]: any }
  } | null,
  getModel: (modelId: string) => ContentModel | null,
  locale: string,
  asset: ChildAsset,
) {
  let parentId = last(asset.ancestors)
  let assetModel = getModel(asset.assetType)
  if (!assetModel) return ''
  let label = asset.meanings?.title
    ? `${getLocalizedProperty(locale, 'label', assetModel)} (${asset.meanings
        ?.title})`
    : getLocalizedProperty(locale, 'label', assetModel)
  let assetTitle = extractTitle(assetModel, asset)
  const defaultTitle = `${label} ${assetTitle || ''}`
  if (!parentId) {
    // this is the root
    return defaultTitle
  }
  let parentAsset = getAsset(parentId)
  if (!parentAsset) {
    return defaultTitle
  }
  let parentModel = getModel(parentAsset.assetType)
  if (!parentModel) {
    console.warn(
      `makeBreadCrumb: model ${parentAsset.assetType} not found`,
      asset,
    )
    return defaultTitle
  }
  let linkField = getFieldByName(asset.parentField, parentModel) as LinkField
  if (!linkField) return asset.parentField
  if (!linkField.isMultiple) {
    return getLocalizedProperty(locale, 'label', linkField)
  }
  let linkContents = pathOr<string[]>([], ['data', linkField.name], parentAsset)
  return `${label} ${linkContents.indexOf(asset._id) + 1}`
}

import { useQuery } from 'react-query'
import { getListItems } from 'services/listItems'
import useToast from './useToast'
export default function useListItems(listName: string) {
  const Toast = useToast()

  return useQuery(['listItems', listName], () => getListItems(listName), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      Toast.error('Ocurrió un error al obtener la información')
    },
  })
}

import React from 'react'
import { IonProgressBar, IonText, IonButton } from '@ionic/react'
import Popover from 'ui/components/modals/Popover'
import useTranslation from 'hooks/useTranslation'
import './SendDataPopover.css'

export interface FormProps {
  totalSubmissions: number
  currentSubmission: number
  totalFiles?: number
  currentFile?: number
  onCancel?: () => void
  disabled: boolean
}

const SendDataPopover: React.FC<FormProps> = (props) => {
  const {
    totalSubmissions,
    currentSubmission,
    totalFiles,
    currentFile,
    onCancel,
    disabled,
  } = props
  const t = useTranslation()
  return (
    <Popover
      isOpen
      showBackdrop
      backdropDismiss={false}
      cssClass="send-data-popover"
    >
      <h2 className="send-data-title">
        {disabled ? t('export.canceling') : t('export.sending')}
      </h2>
      <IonProgressBar
        className="send-data-progress-bar"
        value={currentSubmission / totalSubmissions}
      />
      {totalSubmissions && totalSubmissions > 0 ? (
        <p className="send-data-text">
          {t('export.submission')}
          <IonText className="send-data-figure">
            {' ' + currentSubmission}
          </IonText>
          <span> {t('export.connector')} </span>
          <IonText className="send-data-figure">{totalSubmissions}</IonText>
        </p>
      ) : null}
      {totalFiles && totalFiles > 0 ? (
        <p className="send-data-text">
          {t('export.submissionFile')}
          <IonText className="send-data-figure">{' ' + currentFile}</IonText>
          <span> {t('export.connector')} </span>
          <IonText className="send-data-figure">{totalFiles}</IonText>
        </p>
      ) : null}
      <IonButton
        expand="block"
        color="danger"
        className="send-data-action"
        onClick={onCancel}
        disabled={disabled}
      >
        {t('buttons.cancel')}
      </IonButton>
    </Popover>
  )
}

export default SendDataPopover

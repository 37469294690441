import React from 'react'
import { openDb } from 'services/db'
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonText,
  IonButton,
  IonItem,
  IonFooter,
  IonToolbar,
  IonIcon,
} from '@ionic/react'
import { withRouter } from 'react-router-dom'
import { injectIntl, IntlShape } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import { alertCircleOutline } from 'ionicons/icons'

interface ErrorBoundaryProps extends RouteComponentProps {
  intl: IntlShape
}

interface ErrorBoundaryState {
  error?: Error
  showDetails: boolean
}
class DbErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      error: undefined,
      showDetails: false,
    }
  }
  componentDidMount() {
    openDb()
      //.then(() => console.log('Connected to IDB'))
      .catch((err) => {
        console.error('Error opening DB', err)
        this.setState({ error: err })
      })
  }
  componentDidCatch(err: Error) {
    this.setState({
      error: err,
    })
  }
  render() {
    const { intl, history } = this.props
    return this.state.error ? (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <IonText>Error</IonText>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonIcon icon={alertCircleOutline} />
            <IonText color="danger">An unexpected error has occurred</IonText>
          </IonItem>
          <IonItem>
            <IonButton
              expand="block"
              onClick={() =>
                this.setState({
                  showDetails: !this.state.showDetails,
                })
              }
            >
              Show logs
            </IonButton>
          </IonItem>
          {this.state.showDetails && (
            <IonItem>
              <IonText>
                {this.state.error.name}
                <br />
                {this.state.error.message}
                <br />
                {this.state.error.stack}
              </IonText>
            </IonItem>
          )}
        </IonContent>
        <IonFooter>
          <IonButton
            expand="block"
            onClick={() => {
              this.setState({ error: undefined })
              history.push('/')
            }}
          >
            {intl.formatMessage({
              id: 'buttons.cancel',
              defaultMessage: 'Back to Home',
            })}
          </IonButton>
        </IonFooter>
      </IonPage>
    ) : (
      this.props.children
    )
  }
}

export default withRouter(injectIntl(DbErrorBoundary) as any) as any

import { useMemo } from 'react'

import { isPlatform } from '@ionic/react'
import { useQuery } from 'react-query'

import runPWATests from 'services/pwa-test'

const useDeviceCompatibility = () => {
  const isIOS = isPlatform('ios')
  const {
    data: features,
    isFetching,
    error,
    refetch,
  } = useQuery('features', runPWATests)

  const isCompatible = useMemo(() => {
    return (
      features &&
      features.serviceWorker &&
      features.indexedDB &&
      features.cacheAPI &&
      features.createObjectURL &&
      features.geolocation &&
      (features.diskSpace.persistent || isIOS)
    )
  }, [features, isIOS])

  return { features, isFetching, error, refetch, isCompatible, isIOS }
}

export default useDeviceCompatibility

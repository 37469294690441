import React, { useCallback } from 'react'
import LinkFieldNavItem from '../menus/LinkFieldNavItem'
import { FieldProps } from './common'

export interface LinkProps extends FieldProps {
  childrenTotal: number
  goToLink: (linkName: string) => void
}

const Link: React.FC<LinkProps> = ({
  readOnly,
  label,
  name,
  childrenTotal,
  goToLink,
}) => {
  const handleClick = useCallback(() => {
    goToLink(name)
  }, [name, goToLink])

  return (
    <LinkFieldNavItem
      readOnly={readOnly}
      title={label}
      childrenNumber={childrenTotal}
      onClick={handleClick}
    />
  )
}

export default Link

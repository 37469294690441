import { useInspectedAsset } from 'context/InspectedAssetContext'
import { ModelTypes } from 'core/ContentModel'
import { drop } from 'ramda'
import { useMemo } from 'react'
import { Translation } from 'core/common'

export function useInspectionAssetRef() {
  const { model, getAssetBreadCrumb, findChildAssets } = useInspectedAsset()
  const { items, ancestorsById } = useMemo(() => {
    if (!model || model.modelType !== ModelTypes.inspection) {
      console.log('Missing model or modelType is not inspection')
      return {
        items: [],
        ancestorsById: {},
      }
    }
    const inspectableModelIds = model.sys.usedByModels || []
    const inspectableChildAssets = findChildAssets((c) =>
      inspectableModelIds.includes(c.assetType),
    )

    const { items, ancestorsById } = inspectableChildAssets.reduce<{
      items: Array<{
        value: string
        modelId: string
        ancestors: string[]
        locales: Translation[]
        label: string
        isLeaf: boolean
      }>
      ancestorsById: { [id: string]: object }
    }>(
      (acc, x) => {
        const itemAncestors = drop(1, x.ancestors)
        itemAncestors.forEach((id) => {
          if (!acc.ancestorsById[id]) {
            acc.ancestorsById[id] = {
              value: id,
              label: getAssetBreadCrumb(id),
              isLeaf: false,
            }
          }
        })
        acc.items.push({
          value: x._id,
          modelId: x.assetType,
          ancestors: itemAncestors,
          label: getAssetBreadCrumb(x._id),
          locales: [],
          isLeaf: true,
        })
        return acc
      },
      { items: [], ancestorsById: {} },
    )
    return { items, ancestorsById }
  }, [findChildAssets, getAssetBreadCrumb, model])
  return {
    items,
    ancestorsById,
    getAssetBreadCrumb,
  }
}

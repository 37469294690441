/**
 * @description It resolves typeScript issue: Property 'standalone' does not exist on type 'Navigator'
 * https://stackoverflow.com/questions/47831741/property-share-does-not-exist-on-type-navigator/47831873
 */

export default function getPWADisplayMode() {
  let myNavigatorVariable: any
  myNavigatorVariable = window.navigator
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches
  if (document.referrer.startsWith('android-app://')) return 'twa'
  if (myNavigatorVariable.standalone || isStandalone) return 'standalone'
  return 'browser'
}

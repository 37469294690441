import { useLiveQuery } from 'dexie-react-hooks'
import { useQuery } from 'react-query'
import { identity } from 'ramda'

import { getDownloadAssetsOfModel, getRemoteAssets } from 'services/assets'
import {
  getSubmission,
  getPendingSubmissionsBySourceId,
} from 'services/submissions'

import { SubmissionStatus } from 'core/Submission'

const useAssets = () => {
  return useLiveQuery(
    async () => {
      const assets = await getRemoteAssets()

      const result = []
      for (const asset of assets) {
        // include all assets that haven't been modified ...
        if (asset.revisionSubmissionId === undefined) {
          result.push(asset)
          continue
        }

        const submission = await getSubmission(asset.revisionSubmissionId)

        // NOTE include assets that had a revision, but it has already been
        // exported and deleted
        // REVIEW we may need to think better about this, because the user will
        // be able to create new submissions for the outdated asset, however if
        // we do not include the asset, they will not be able to remove it
        if (!submission) {
          result.push(asset)
          continue
        }

        // ... all assets with pending revisions ...
        if (submission.status !== SubmissionStatus.uploaded) {
          result.push(asset)
          continue
        }

        const submissions = await getPendingSubmissionsBySourceId(asset.id)
        // ... and those with pending submissions
        if (submissions.length > 0) {
          result.push(asset)
        }
      }

      return result
    },
    [],
    [],
  )
}

export default useAssets

// REVIEW move to somewhere else? turn into live query?
export function useAssetsByModelId(modelId: string, transform = identity) {
  return useQuery(['assetsByModelId', modelId], function () {
    return getDownloadAssetsOfModel(modelId).then(transform)
  })
}

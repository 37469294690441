import React, { useEffect, useMemo, useState } from 'react'
import { IonToast } from '@ionic/react'
import { useLocation } from 'react-router'

import useTranslation from 'hooks/useTranslation'
import getPWADisplayMode from 'services/getDisplayMode'
import isDev from 'common/isDev'

const ROUTES_TO_SHOW = ['/import/assets', '/take-data', '/export', '/settings']

function InstallAppToast() {
  const t = useTranslation()
  const location = useLocation()

  const [showAlert, setShowAlert] = useState(false)

  const needsInstall = useMemo(() => {
    return getPWADisplayMode() === 'browser' && !isDev
  }, [])

  useEffect(() => {
    setShowAlert(ROUTES_TO_SHOW.includes(location.pathname) && needsInstall)
  }, [needsInstall, location.pathname])

  return (
    <IonToast
      isOpen={showAlert}
      message={t('notifications.appNotInstalledMessage')}
      position="top"
      color="danger"
      buttons={[
        {
          text: t('buttons.close'),
          role: 'cancel',
        },
      ]}
      onDidDismiss={() => setShowAlert(false)}
    ></IonToast>
  )
}

export default InstallAppToast

import React from 'react'
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  isPlatform,
} from '@ionic/react'
import { add, folderOpen } from 'ionicons/icons'
import classNames from 'classnames'
import './ContentHeader.css'

export interface ContentHeaderProps {
  title: string
  onShowLinksMenu?: () => void
  onShowTreeNavigation?: () => void
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
  onShowLinksMenu,
  onShowTreeNavigation,
  title,
}) => {
  const headerClasses = classNames('content-header', {
    'content-header-ios': isPlatform('ios'),
  })

  return (
    <IonHeader className={headerClasses}>
      <IonToolbar className="content-header-toolbar">
        <IonTitle className="content-header-title">{title}</IonTitle>
        {onShowLinksMenu || onShowTreeNavigation ? (
          <IonButtons slot="end">
            {onShowLinksMenu ? (
              <IonButton
                slot="icon-only"
                color="primary"
                onClick={onShowLinksMenu}
              >
                <IonIcon icon={add} />
              </IonButton>
            ) : null}
            {onShowTreeNavigation ? (
              <IonButton
                slot="icon-only"
                color="primary"
                onClick={onShowTreeNavigation}
              >
                <IonIcon icon={folderOpen} />
              </IonButton>
            ) : null}
          </IonButtons>
        ) : null}
      </IonToolbar>
    </IonHeader>
  )
}

export default ContentHeader

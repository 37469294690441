import React from 'react'

import ImportAssetByLocation from 'ui/views/import/ImportAssetByLocation'

// REVIEW is this intermediate component really necessary? I have created it
// just for simmetry with other parts of the application
const ImportAssetByLocationPage = () => {
  return <ImportAssetByLocation />
}

export default ImportAssetByLocationPage

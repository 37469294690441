import { useCallback, useContext, useState } from 'react'
import UserContext from 'context/UserContext'
import {
  loginWithCredentials,
  getUserDetails,
  refreshToken,
} from 'services/user'
import { UserCredentials } from '../core/User'
import useTranslation from './useTranslation'
import useConfig from './useConfig'

interface IUseUser {
  username: string | null
  isLogged: boolean
  isLoginLoading: boolean
  loginError: string
  isUserLoaded: boolean
  login: (credentials: UserCredentials) => Promise<void>
  logout: () => void
  tryRefreshToken: () => Promise<any>
}

export default function useUser(): IUseUser {
  const { jwt, isLoaded, username, saveUser, clearUser } =
    useContext(UserContext)
  const t = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { fetchAppConfig } = useConfig()

  const login = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      if (username === '' || password === '') {
        setError(t('login.requiredError'))
        return
      }

      setIsLoading(true)

      return await loginWithCredentials({
        username,
        password,
      })
        .then(async (jwt) => {
          // fetch user details
          await saveUser(username, jwt)
          await getUserDetails(jwt).then(async (userInfo) => {
            setError('')
            //console.log('userInfo', userInfo)
            await fetchAppConfig(jwt)
            setIsLoading(false)
          })
        })
        .catch((err) => {
          setIsLoading(false)
          setError(t('login.authError'))
          console.log('Login error', err)
        })
    },
    [t, saveUser, fetchAppConfig],
  )

  const logout = useCallback(() => {
    clearUser()
  }, [clearUser])

  const tryRefreshToken = useCallback(
    () =>
      refreshToken()
        .then(async (token) => {
          await saveUser(null, token)
          return token
        })
        .catch((err) => logout()),
    [logout, saveUser],
  )

  return {
    username,
    isLogged: Boolean(jwt),
    isLoginLoading: isLoading,
    loginError: error,
    isUserLoaded: isLoaded,
    login,
    logout,
    tryRefreshToken,
  }
}

import React, { useEffect, useMemo, useState } from 'react'
import { requestPersistentStorage } from 'services/permissions'
import { IonAlert, isPlatform } from '@ionic/react'
import useTranslation from 'hooks/useTranslation'
import './PersistentStorageAlert.css'

function PersistentStorageAlert() {
  const [showAlert, setShowAlert] = useState(false)
  const t = useTranslation()
  useEffect(() => {
    if (!isPlatform('ios')) {
      requestPersistentStorage().then((isCompatible) => {
        setShowAlert(!isCompatible)
      })
    }
  }, [])
  const primaryButton = useMemo(() => {
    return {
      text: t('buttons.ok'),
      role: 'cancel',
      handler: () => setShowAlert(!showAlert),
    }
  }, [showAlert, t])

  return (
    <IonAlert
      isOpen={showAlert}
      cssClass="persistent-storage-alert"
      backdropDismiss
      buttons={[primaryButton]}
      header={t('notifications.persistentHeader')}
      subHeader={t('notifications.incompatibleDeviceMessage')}
      message={t('notifications.persistentMessage')}
    />
  )
}

export default PersistentStorageAlert

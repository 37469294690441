interface GeoFeatureProps {
  type?: 'Point'
  lat: number
  lng: number
  alt?: number | null
  properties?: object
}

export function makeGeoFeature({
  type = 'Point',
  lat,
  lng,
  alt,
  properties = {},
}: GeoFeatureProps) {
  return {
    type: 'Feature',
    properties,
    geometry: {
      type,
      coordinates: [lng, lat, alt],
    },
  }
}

export function makeGeoFeatureCollection(
  features: Array<GeoFeatureProps> = [],
) {
  return {
    type: 'FeatureCollection',
    features: features.map(makeGeoFeature),
  }
}

const API_Key = process.env.REACT_APP_WEATHER_API_KEY

export const hasWeatherService = () => {
  return !!API_Key
}

export const fetchWeatherData = async (center: {
  lat: number
  lon: number
}) => {
  const { lat, lon } = center
  const response = await fetch(
    `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&appid=${API_Key}`,
  )
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

export enum LayerTiles {
  Clouds = 'clouds_new',
  Precipitation = 'precipitation_new',
  Wind = 'wind_new',
  Temperature = 'temp_new',
}

const fetchMapUrl = (
  center: { x: number; y: number; z?: number },
  layer: string,
) => {
  const { x, y, z = 4 } = center
  return `https://tile.openweathermap.org/map/${layer}/${z}/${x}/${y}.png?appid=${API_Key}`
}

export const fetchCloudMap = (center: { x: number; y: number; z: number }) => {
  return fetchMapUrl(center, LayerTiles.Clouds)
}

export const fetchPrecipitationMap = (center: {
  x: number
  y: number
  z: number
}) => {
  return fetchMapUrl(center, LayerTiles.Precipitation)
}

export const fetchWindMap = (center: { x: number; y: number; z: number }) => {
  return fetchMapUrl(center, LayerTiles.Wind)
}

export const fetchTemperatureMap = (center: {
  x: number
  y: number
  z: number
}) => {
  return fetchMapUrl(center, LayerTiles.Temperature)
}

import React from 'react'
import ImageUploader from './ImageUploader'
import { FieldProps } from './common'
import useFiles from 'hooks/useFiles'

interface ImageProps extends FieldProps {}

const Image: React.FC<ImageProps> = (props) => {
  const {
    readOnly,
    name,
    label,
    hasError,
    hint,
    isRequired,
    errorText,
    isLoading,
    value,
  } = props
  const { imageValue, handleImageChange } = useFiles(name, value)

  return (
    <ImageUploader
      readOnly={readOnly}
      name={name}
      label={label}
      onChange={handleImageChange}
      errorText={errorText}
      isRequired={isRequired}
      isLoading={isLoading}
      hasError={hasError}
      hint={hint}
      value={imageValue}
    />
  )
}

export default Image

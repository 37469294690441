export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3030'

export class UnathorizedError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'INVALID_TOKEN'
  }
}

export class APIError extends Error {
  public status: number
  constructor(status: number, message: string) {
    super(message)
    this.status = status
  }
}

export class NetworkError extends Error {}

import { makeFullAssetFromRevision } from 'core/mappers'
import { SubmissionSourceType } from 'core/Submission'
import { useQuery } from 'react-query'
import { getAsset } from 'services/assets'
import { getSubmission } from 'services/submissions'

type SubmissionSourceParams = {
  id?: string
  sourceType: SubmissionSourceType
}
export default function useSubmissionSource({
  id,
  sourceType = SubmissionSourceType.Remote,
}: SubmissionSourceParams) {
  return useQuery(
    ['submissionSource', id, sourceType],
    async function (_key, id: string, type: SubmissionSourceType) {
      if (!id) return undefined
      switch (type) {
        case SubmissionSourceType.Remote:
          return getAsset(id)
        case SubmissionSourceType.Local:
          return getSubmission(id).then((x) =>
            x != null ? makeFullAssetFromRevision(x) : x,
          )
      }
    },
    {
      retry: false,
      refetchOnMount: true,
    },
  )
}

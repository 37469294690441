import useToast from 'hooks/useToast'
import { useMutation, queryCache } from 'react-query'
import { fetchFullAsset, saveAsset as saveAssetDB } from 'services/assets'
import useTranslation from 'hooks/useTranslation'

interface AssetIdAndLabel {
  assetId: string
  label: string
}

const saveAsset = async ({ assetId, label }: AssetIdAndLabel) => {
  return fetchFullAsset(assetId).then((asset) =>
    saveAssetDB({ ...asset, label }).then(() => asset),
  )
}

export default function useSaveAsset() {
  const Toast = useToast()
  const t = useTranslation()

  return useMutation(saveAsset, {
    onSuccess(asset) {
      queryCache.invalidateQueries(['assets'])
      queryCache.invalidateQueries(['asset', asset.id])
    },
    onError: (err) => {
      console.log(err)
      Toast.error(t('notifications.saveError'))
    },
  })
}

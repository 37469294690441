import React, { useCallback, useMemo, useRef } from 'react'
import { LinkChildren } from 'core/LinkChildren'
import { IonList, IonItem, IonLabel, IonIcon, IonButton } from '@ionic/react'
import AssetNav from 'ui/components/menus/AssetNav'
import LinkFieldNavItem from 'ui/components/menus/LinkFieldNavItem'
import { addCircleOutline, informationCircleOutline } from 'ionicons/icons'
import useTranslation from 'hooks/useTranslation'
import { LinkField } from 'core/ContentField'
import GroupInnerContent from 'ui/layouts/GroupInnerContent'
import { useCurrentLocale } from 'context/LocaleContext'
import { getLocalizedProperty } from 'core/common'
import TranslatedTitleField from 'components/TranslatedTitleField'
import useTitleField from 'hooks/useTitleField'
interface LinkListProps {
  children: LinkChildren
  value?: string[]
  navigateTo: (
    modelId: string,
    linkName: string,
    showIndex: boolean,
    index?: number | null,
  ) => void
  onRemove: (childId: string) => void
  onDuplicate: (childId: string) => void
  onAdd: () => void
  allowDuplicate: boolean
  selectedLinkField: LinkField
  readOnly?: boolean
}

const canAddLink = (field: LinkField, value: string[] = []) => {
  if (!value || !Array.isArray(value)) return true
  if (value.length === 0) return true
  if (field.isMultiple && value.length < (field.maxLength || Infinity))
    return true
  return false
}

const LinkList: React.FC<LinkListProps> = ({
  readOnly,
  children,
  value = [],
  navigateTo,
  onRemove,
  onAdd,
  onDuplicate,
  allowDuplicate = false,
  selectedLinkField,
}) => {
  const listRef = useRef<HTMLIonListElement>(null)
  const t = useTranslation()
  const currentLocale = useCurrentLocale()
  const childrenOfLink = useMemo(() => {
    return value.map((childId) => children[childId])
  }, [value, children])

  const linkLabel = useMemo(
    () => getLocalizedProperty(currentLocale, 'label', selectedLinkField),
    [currentLocale, selectedLinkField],
  )

  const handleDuplicate = useCallback(
    (childId: string) => {
      onDuplicate(childId)
      listRef.current && listRef.current.closeSlidingItems()
    },
    [onDuplicate],
  )

  const canAddChild = !readOnly && canAddLink(selectedLinkField, value)
  const fieldModel = useTitleField(selectedLinkField.ofType)

  return (
    <GroupInnerContent title={linkLabel}>
      <IonList ref={listRef}>
        <AssetNav>
          {childrenOfLink.length ? (
            childrenOfLink.map((child, index) => {
              return (
                <LinkFieldNavItem
                  key={child.id}
                  readOnly={readOnly}
                  title={
                    <IonLabel>
                      {linkLabel}{' '}
                      {selectedLinkField.isMultiple ? index + 1 : ''}
                      {fieldModel ? (
                        <TranslatedTitleField
                          fieldModel={fieldModel}
                          child={child}
                        />
                      ) : (
                        ''
                      )}
                    </IonLabel>
                  }
                  onClick={() =>
                    navigateTo(
                      child.modelId,
                      child.id,
                      selectedLinkField.isMultiple,
                      index,
                    )
                  }
                  onDeleteLink={() => onRemove(child.id)}
                  onDuplicateLink={() => handleDuplicate(child.id)}
                  allowDuplicate={allowDuplicate && canAddChild}
                />
              )
            })
          ) : (
            <>
              <IonItem lines="none" className="ion-text-center">
                <IonLabel>
                  <IonIcon
                    icon={informationCircleOutline}
                    style={{ paddingRight: 10 }}
                  />
                  {canAddChild ? t('withoutValue') : t('empty.link')}
                </IonLabel>
              </IonItem>
            </>
          )}
        </AssetNav>
      </IonList>
      {canAddChild && (
        <IonButton color="primary" expand="block" onClick={onAdd}>
          <IonIcon icon={addCircleOutline} />
          {t('buttons.addChild')}
        </IonButton>
      )}
    </GroupInnerContent>
  )
}

export default LinkList

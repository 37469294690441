import { useState, useMemo } from 'react'
import { useLocation } from 'react-router'

import { telescopeOutline, cog, megaphoneOutline } from 'ionicons/icons'
import useDeviceCompatibility from 'hooks/useDeviceCompatibility'

import getPWADisplayMode from 'services/getDisplayMode'
import isDev from 'common/isDev'

export enum HomeInfoModeTypes {
  install = 'install',
  config = 'config',
  data = 'data',
  incompatible = 'incompatible',
}

const INFO_CONTENT: Record<
  HomeInfoModeTypes,
  {
    icon: string
    dividerTitle: string
    title: string
    description: string
    linkTo?: string
  }
> = {
  [HomeInfoModeTypes.install]: {
    icon: megaphoneOutline,
    dividerTitle: 'home.info.divider',
    title: 'home.install.title',
    description: 'home.install.description',
  },
  [HomeInfoModeTypes.config]: {
    icon: cog,
    dividerTitle: 'home.info.divider',
    title: 'home.config.title',
    description: 'home.config.description',
    linkTo: '/settings',
  },
  [HomeInfoModeTypes.incompatible]: {
    icon: cog,
    dividerTitle: 'home.info.divider',
    title: 'home.incompatible.title',
    description: 'home.incompatible.description',
    linkTo: '/settings',
  },
  [HomeInfoModeTypes.data]: {
    icon: telescopeOutline,
    dividerTitle: 'home.recentActivity',
    title: 'home.noDataFound',
    description: 'home.noDataFound.description',
    linkTo: '/import/assets',
  },
}

const useHomeInfo = () => {
  const location = useLocation()

  const [isFirstRender, setIsFirstRender] = useState(true)

  const {
    isFetching: isCompatibilityChecking,
    isCompatible: isDeviceCompatible,
  } = useDeviceCompatibility()

  const infoMode = useMemo(() => {
    if (getPWADisplayMode() === 'browser' && !isDev) {
      return HomeInfoModeTypes.install
    }

    if (!isCompatibilityChecking && !isDeviceCompatible) {
      return HomeInfoModeTypes.incompatible
    }

    if (!location.pathname.includes('home')) {
      setIsFirstRender(false)
      return HomeInfoModeTypes.data
    }

    if (isFirstRender) {
      return HomeInfoModeTypes.config
    }

    return HomeInfoModeTypes.data
  }, [
    isCompatibilityChecking,
    isDeviceCompatible,
    isFirstRender,
    location.pathname,
  ])

  return { mode: infoMode, content: INFO_CONTENT[infoMode] }
}

export default useHomeInfo

import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonRow,
  IonText,
  isPlatform,
} from '@ionic/react'
import { NoteField } from 'core/ContentField'
import { informationCircle } from 'ionicons/icons'
import * as React from 'react'
import FormField from '../forms/FormField'
import { FieldProps } from './common'

export interface NoteFieldProps extends FieldProps {
  appearance: NoteField['appearance']
}

const linesToParagraphs = (text = '') => {
  if (text == null) return null
  return String(text).split('\n\n')
}

const Note: React.FC<NoteFieldProps> = (props) => {
  const { hint = '', appearance = 'note', fileId } = props
  const isiOS = isPlatform('ios')
  const content = React.useMemo(
    () => (appearance === 'note' ? linesToParagraphs(hint) : hint),
    [hint, appearance],
  )
  if (!content) return null
  switch (appearance) {
    case 'note': {
      return (
        <FormField fileId={fileId} classnames="note">
          <IonGrid>
            <IonRow style={{ alignItems: 'baseline' }}>
              <IonCol size="1">
                <IonIcon icon={informationCircle} color="primary" />
              </IonCol>
              <IonCol>
                <IonText>
                  {(content as string[]).map((line, index) => (
                    <p key={index} style={{ width: '100%' }}>
                      {line}
                    </p>
                  ))}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </FormField>
      )
    }
    case 'header': {
      return (
        <IonItemDivider>
          <IonLabel
            color="primary"
            style={{ fontSize: isiOS ? '100%' : '120%' }}
          >
            {content}
          </IonLabel>
        </IonItemDivider>
      )
    }
  }
}

export default React.memo(Note)

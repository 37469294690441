import React, { useEffect } from 'react'

import useTranslation from 'hooks/useTranslation'
import useToast from 'hooks/useToast'

const Error: React.FC = () => {
  const t = useTranslation()
  const Toast = useToast()

  useEffect(() => {
    Toast.error(t('notifications.mapLoadingError'))
  }, [t, Toast])

  // NOTE does not render anything because parent component will be suck with a
  // loading screen
  return null
}

export default Error

import React, { useState } from 'react'
import { IonButton, IonIcon, IonRange } from '@ionic/react'
import {
  colorPaletteOutline,
  brushOutline,
  reloadCircleOutline,
  arrowUndoCircleOutline,
  arrowRedoCircleOutline,
  gridOutline,
  imagesOutline,
} from 'ionicons/icons'
import EraserIcon from 'ui/icons/Eraser'
import StrokeIcon from 'ui/icons/Stroke'
import ImageOffIcon from 'ui/icons/ImageOff'

interface SketchButtonsProps {
  onClear: () => void
  onUndo: () => void
  onRedo: () => void
  onLoadBackground: () => void
  onRemoveBackground?: () => void
  hasBackground: boolean
  allowGrid: boolean
  allowCustomBackground: boolean
  gridState?: boolean
  onChangeShowGrid: () => void
  isLandscape: boolean
}

interface EditButtonsProps {
  onColorChange: (color: string) => void
  onStrokeChange: (strokeWidth: number) => void
  onModeChange: (eraseMode: 'draw' | 'erase') => void
  color: string
  strokeWidth: number
  eraseMode: boolean
  isLandscape: boolean
}

export const EditButtons: React.FC<EditButtonsProps> = ({
  isLandscape,
  onColorChange,
  onStrokeChange,
  onModeChange,
  color,
  strokeWidth,
  eraseMode,
}) => {
  const [showStrokeSlider, setShowStrokeSlider] = useState(false)

  return (
    <>
      <div className="sketch-buttons-down">
        <IonButton
          fill="outline"
          //className="color-button"
          onClick={() => document.getElementById('color-picker')?.click()}
          style={{
            padding: '0',
            width: 60,
          }}
        >
          <input
            type="color"
            id="color-picker"
            onChange={(e) => onColorChange(e.target.value)}
            value={color}
            style={{
              width: '50px',
              height: '50px',
              border: 'none',
              padding: '0',
              cursor: 'pointer',
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: 1,
              opacity: 0,
            }}
          />
          <IonIcon
            icon={colorPaletteOutline}
            slot="icon-only"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: color,
              zIndex: 2,
            }}
          />
        </IonButton>
        <IonButton
          fill={showStrokeSlider ? 'solid' : 'clear'}
          onClick={() => setShowStrokeSlider(!showStrokeSlider)}
        >
          <StrokeIcon slot="icon-only" />
        </IonButton>
        <IonButton
          fill={!eraseMode ? 'solid' : 'clear'}
          onClick={() => onModeChange('draw')}
        >
          <IonIcon icon={brushOutline} slot="icon-only" />
        </IonButton>
        <IonButton
          fill={eraseMode ? 'solid' : 'clear'}
          onClick={() => onModeChange('erase')}
        >
          <EraserIcon slot="icon-only" />
        </IonButton>
      </div>
      {showStrokeSlider && (
        <div className="sketch-range-container">
          <IonRange
            min={1}
            max={100}
            value={strokeWidth}
            pin={true}
            onIonChange={(e) => onStrokeChange(e.detail.value as number)}
          />
        </div>
      )}
    </>
  )
}

export const SketchButtons: React.FC<SketchButtonsProps> = ({
  onClear,
  onUndo,
  onRedo,
  onLoadBackground,
  onRemoveBackground,
  hasBackground,
  allowGrid,
  allowCustomBackground,
  gridState,
  onChangeShowGrid,
}) => {
  return (
    <div className="sketch-buttons-top">
      <IonButton fill="clear" onClick={onClear}>
        <IonIcon icon={reloadCircleOutline} slot="icon-only" />
      </IonButton>
      <IonButton fill="clear" onClick={onUndo}>
        <IonIcon icon={arrowUndoCircleOutline} slot="icon-only" />
      </IonButton>
      <IonButton fill="clear" onClick={onRedo}>
        <IonIcon icon={arrowRedoCircleOutline} slot="icon-only" />
      </IonButton>
      {allowGrid && (
        <IonButton
          fill={gridState ? 'solid' : 'clear'}
          onClick={onChangeShowGrid}
        >
          <IonIcon icon={gridOutline} slot="icon-only" />
        </IonButton>
      )}
      {allowCustomBackground && (
        <>
          <IonButton fill="clear" onClick={onLoadBackground}>
            <IonIcon icon={imagesOutline} slot="icon-only" />
          </IonButton>
          <IonButton
            fill="clear"
            onClick={onRemoveBackground}
            disabled={!hasBackground}
          >
            <ImageOffIcon slot="icon-only" />
          </IonButton>
        </>
      )}
    </div>
  )
}

export default SketchButtons

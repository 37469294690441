import React from 'react'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'

import Typography, { Variant } from 'ui/components/typography/Typography'

import useTranslations from 'hooks/useTranslation'
import { useIdFields } from 'hooks/assets'

import './AdvancedAssetSearch.css'

interface AdvancedAssetSearchProps {
  modelId: string
}

const AdvancedAssetSearch: React.FC<AdvancedAssetSearchProps> = (props) => {
  const t = useTranslations()
  const { modelId } = props
  const { data: fields } = useIdFields(modelId)

  return (
    <div className="advanced-asset-search">
      <Typography variant={Variant.overline}>
        {t('import.advancedSearch')}
      </Typography>
      <div className="advanced-asset-search-links">
        <IonRouterLink
          className="advanced-asset-search-link"
          routerLink={`/import/assets/${modelId}/map`}
        >
          <span className="advanced-asset-search-link-content">
            <IonIcon icon={chevronForwardOutline} />
            <Typography variant={Variant.subtitle2}>
              {t('import.advancedSearch.location')}
            </Typography>
          </span>
        </IonRouterLink>
        {fields ? (
          <IonRouterLink
            className="advanced-asset-search-link"
            routerLink={`/import/assets/${modelId}/attributes`}
          >
            <span className="advanced-asset-search-link-content">
              <IonIcon icon={chevronForwardOutline} />
              <Typography variant={Variant.subtitle2}>
                {t('import.advancedSearch.attributes')}
              </Typography>
            </span>
          </IonRouterLink>
        ) : null}
      </div>
    </div>
  )
}

export default AdvancedAssetSearch

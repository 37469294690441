import React from 'react'
import { IonSpinner } from '@ionic/react'

import Typography, { Variant } from 'ui/components/typography/Typography'

import './LoadingOverlay.css'

interface LoadingOverlayProps {
  text: string
  // rest props passed to the div
  style?: React.CSSProperties
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  const { text, ...rest } = props

  return (
    <div className="loading-overlay" {...rest}>
      <div className="loading-spinner-wrapper">
        <IonSpinner color="primary" className="loading-spinner" />
        <Typography variant={Variant.subtitle2}>{text}</Typography>
      </div>
    </div>
  )
}

export default LoadingOverlay

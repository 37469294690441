import { makeGeoFeatureCollection } from 'common/geoFeature'

interface GenerateKmlParams {
  sourceId: string
  lat: number
  lng: number
  alt?: number | null
  title?: string | null
  modelLabel?: string
}

export default async function generateKml({
  lat,
  lng,
  alt,
  sourceId,
  title,
  modelLabel,
}: GenerateKmlParams) {
  const feature = makeGeoFeatureCollection([
    {
      lng,
      lat,
      alt,
      properties: { id: sourceId, title, model: modelLabel },
    },
  ])
  const toKml = require('tokml')
  const content = new Blob(
    [
      toKml(feature, {
        name: title,
        description: modelLabel,
        documentName: title ? title : sourceId,
      }),
    ],
    {
      type: 'application/vnd.google-earth.kml+xml',
    },
  )

  const downloadLink = document.createElement('a')
  downloadLink.href = URL.createObjectURL(content)
  downloadLink.download = (title ? title : sourceId) + '_location.kml'
  downloadLink.click()
}

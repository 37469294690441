import { append, head, path, startsWith, uniq, without } from 'ramda'

export type CascadeSelectItem = {
  value: string
  label: string
  isLeaf?: boolean
  modelId?: string
  ancestors?: string[]
}

export type CascadeSelectState = {
  /**
   *
   */
  items: CascadeSelectItem[]
  ancestorIds: string[]
  children: string[]
  value: string | null
}

type CascadeSelectAction =
  | {
      type: 'AncestorClicked'
      value: string
    }
  | { type: 'ChildClicked'; value: string }
  | { type: 'LeaftSelected'; value: string }
  | {
      type: 'Reset'
      items: CascadeSelectItem[]
      value?: string
    }

const getUniqueAncestors = (items: CascadeSelectItem[], atLevel = 0) =>
  Array.from(
    items
      .reduce((acc, item) => {
        const ancestorAtLevel: string | undefined = path(
          ['ancestors', atLevel],
          item,
        )
        if (ancestorAtLevel) {
          acc.add(ancestorAtLevel)
        } else {
          acc.add(item.value)
        }
        return acc
      }, new Set<string>())
      .values(),
  )

const findDescendants = (items: CascadeSelectItem[], ancestors: string[]) => {
  return items.reduce<string[]>((acc, item) => {
    const itemAncestors = item.ancestors || []
    if (startsWith(ancestors, itemAncestors)) {
      // this is a descendent, but it is direct?
      if (itemAncestors.length === ancestors.length) {
        acc.push(item.value)
      } else {
        // there are intermediate ancestors
        // remove the common ancestors and
        // remove duplicates
        return uniq(
          append(head(without(ancestors, itemAncestors)) as string, acc),
        )
      }
    }
    return acc
  }, [])
}

const reset = (
  items: CascadeSelectItem[],
  value: string | null,
): CascadeSelectState => {
  const selectedItem = items.find((i) => i.value === value)
  return {
    items,
    value: selectedItem?.value || null,
    ancestorIds: selectedItem?.ancestors || [],
    children: items
      .filter((i) =>
        startsWith(selectedItem?.ancestors || [], i.ancestors || []),
      )
      .map((i) => i.value),
  }
}

export const makeInitialState = ({
  items = [],
  value,
}: {
  items: CascadeSelectItem[]
  value?: string
}): CascadeSelectState => {
  const selectedItem = value ? items.find((x) => x.value === value) : null
  if (!value || !selectedItem) {
    return {
      items,
      ancestorIds: [],
      children: getUniqueAncestors(items),
      value: null,
    }
  } else return reset(items, value)
}

export function reducer(
  state: CascadeSelectState,
  action: CascadeSelectAction,
): CascadeSelectState {
  switch (action.type) {
    case 'AncestorClicked': {
      const ancestorIndex = state.ancestorIds.indexOf(action.value)
      if (ancestorIndex === -1) return state
      const ancestorIds = state.ancestorIds.slice(0, ancestorIndex)
      return {
        ...state,
        ancestorIds,
        children: findDescendants(state.items, ancestorIds),
      }
    }
    case 'ChildClicked': {
      const ancestorIds = append(action.value, state.ancestorIds)
      return {
        ...state,
        ancestorIds,
        children: findDescendants(state.items, ancestorIds),
      }
    }
    case 'LeaftSelected':
      return {
        ...state,
        value: action.value,
      }
    case 'Reset': {
      return reset(action.items, action.value || null)
    }
    /* default: {
      throw new Error('Unknown action type ' + action.type)
    } */
  }
}

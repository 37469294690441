import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  IonCard,
  IonLabel,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
  IonPopover,
  IonContent,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from '@ionic/react'
import {
  calendarOutline,
  cloudUploadOutline,
  radioButtonOff,
  radioButtonOn,
  trashOutline,
  navigateSharp,
  informationCircle,
} from 'ionicons/icons'

import useTranslation from 'hooks/useTranslation'

import ConfirmationAlert from 'ui/components/alerts/ConfirmationAlert'
import { SubmissionStatus } from 'core/Submission'

import StatusTag from 'ui/components/tags/StatusTag'

export interface SavedAssetCardProps {
  title: string
  assetType?: string
  assetDetails?: string
  info?: string
  isEditionMode?: boolean
  isSelected?: boolean
  isUploaded: boolean
  hasLocation?: boolean
  hasUnsubmittedAsset?: boolean
  hasDraftAsset?: boolean
  status: SubmissionStatus
  assetId?: string
  onSelect?: () => void
  onDelete?: () => void
}

const SavedAssetCard: React.FC<SavedAssetCardProps> = ({
  title,
  assetType,
  assetDetails,
  isEditionMode,
  info,
  onSelect,
  onDelete,
  isSelected,
  isUploaded,
  hasLocation,
  hasUnsubmittedAsset,
  hasDraftAsset,
  status,
  assetId,
}) => {
  const { push } = useHistory()
  const t = useTranslation()

  const [showDraftAssetAlert, setShowDraftAssetAlert] = useState(false)
  const [showUnsubmittedAssetAlert, setShowUnsubmittedAssetAlert] =
    useState(false)

  const randomId = Math.random()

  const handleDraftAssetAction = () => {
    push('/take-data/in-progress', { assetId })
  }

  const handleUnsubmittedAssetAction = () => {
    setShowUnsubmittedAssetAlert(false)
  }

  return (
    <IonCard>
      <IonItemSliding>
        <IonItem lines="none" onClick={onSelect}>
          {isEditionMode ? (
            <IonButton
              fill="clear"
              slot="start"
              className="asset-card-download-button"
              onClick={onSelect}
            >
              <IonIcon
                slot="icon-only"
                color="primary"
                icon={isSelected ? radioButtonOn : radioButtonOff}
              />
            </IonButton>
          ) : null}
          <IonLabel className="ion-text-wrap">
            <IonText>
              <h2>{title}</h2>
            </IonText>
            <p>{assetType}</p>
            <p className="asset-card-item-details">
              <IonIcon
                icon={isUploaded ? cloudUploadOutline : calendarOutline}
                style={{ verticalAlign: 'text-top' }}
              />{' '}
              <span>{assetDetails}</span>
              {hasLocation === true && (
                <IonIcon
                  icon={navigateSharp}
                  color="primary"
                  style={{ fontSize: '14px' }}
                />
              )}
              {hasLocation === false && (
                <IonIcon
                  icon={navigateSharp}
                  color="danger"
                  style={{ fontSize: '14px' }}
                />
              )}
            </p>
            <StatusTag status={status} />
            {/* </p>
            <p className='asset-card-item-details'>
              {assetDetails}
            </p> */}
          </IonLabel>
          {hasDraftAsset ? (
            <>
              <IonIcon
                slot="end"
                id={`click-trigger-${randomId}`}
                icon={informationCircle}
                onClick={(ev) => {
                  // NOTE prevent card's `onClick` handler from firing
                  ev.stopPropagation()

                  setShowDraftAssetAlert(true)
                }}
              />

              <ConfirmationAlert
                isOpen={showDraftAssetAlert}
                title={t('confirmation.draftAssetTitle')}
                description={t('confirmation.draftAssetDescription')}
                acceptText={t('confirmation.draftAssetAction')}
                cancelText={t('buttons.cancelSelection')}
                onAccept={handleDraftAssetAction}
                onDidDismiss={() => setShowDraftAssetAlert(false)}
                onClick={(ev) => {
                  // NOTE prevent card's `onClick` handler from firing
                  ev.stopPropagation()
                }}
              />
            </>
          ) : null}
          {hasUnsubmittedAsset ? (
            <>
              <IonIcon
                slot="end"
                id={`click-trigger-${randomId}`}
                icon={informationCircle}
                onClick={(ev) => {
                  // NOTE prevent card's `onClick` handler from firing
                  ev.stopPropagation()

                  setShowUnsubmittedAssetAlert(true)
                }}
              />

              <ConfirmationAlert
                isOpen={showUnsubmittedAssetAlert}
                title={t('confirmation.unsubmittedAssetTitle')}
                description={t('confirmation.unsubmittedAssetDescription')}
                acceptText={t('confirmation.unsubmittedAssetAction')}
                onAccept={handleUnsubmittedAssetAction}
                onClick={(ev) => {
                  // NOTE prevent card's `onClick` handler from firing
                  ev.stopPropagation()
                }}
              />
            </>
          ) : null}
          {info ? (
            <>
              <IonIcon
                slot="end"
                id={`click-trigger-${randomId}`}
                icon={informationCircle}
                // NOTE prevent card's `onClick` handler from firing
                onClick={(ev) => ev.stopPropagation()}
              />
              <IonPopover
                trigger={`click-trigger-${randomId}`}
                // NOTE class defined in `ui/components/items/AssetItem.css`
                className="asset-item-popover"
              >
                <IonContent className="ion-padding">{info}</IonContent>
              </IonPopover>
            </>
          ) : null}
        </IonItem>
        {onDelete && (
          <IonItemOptions side="end">
            <IonItemOption color="danger" onClick={onDelete}>
              <IonIcon icon={trashOutline} size="large" />
            </IonItemOption>
          </IonItemOptions>
        )}
      </IonItemSliding>
    </IonCard>
  )
}

export default SavedAssetCard

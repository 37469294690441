import React from 'react'
import { Controller } from 'react-hook-form'
import { IonItemGroup, IonItem, IonInput } from '@ionic/react'

import useTranslation from 'hooks/useTranslation'

import { NumericField } from 'core/ContentField'

interface NumericFilterFieldProps {
  fieldModel: NumericField
}

const NumericFilterField: React.FC<NumericFilterFieldProps> = (props) => {
  const { fieldModel } = props
  const { name } = fieldModel

  const t = useTranslation()

  return (
    <IonItemGroup>
      <IonItem>
        <Controller
          name={`${name}.gte`}
          render={({ field }) => (
            <IonInput
              type="number"
              label={t('field.from')}
              clearInput
              value={field.value}
              onIonChange={field.onChange}
              onIonBlur={field.onBlur}
            />
          )}
        />
      </IonItem>
      <IonItem>
        <Controller
          name={`${name}.lte`}
          render={({ field }) => (
            <IonInput
              type="number"
              label={t('field.to')}
              clearInput
              value={field.value}
              onIonChange={field.onChange}
              onIonBlur={field.onBlur}
            />
          )}
        />
      </IonItem>
    </IonItemGroup>
  )
}

export default NumericFilterField

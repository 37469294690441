import { propOr } from 'ramda'

export const AppTheme = {
  customer: process.env.REACT_APP_CUSTOMER || 'default',
  logoUrl: process.env.REACT_APP_LOGO || 'logo-ines.png',
  appTitle: process.env.REACT_APP_TITLE || 'Datos en campo',
  primaryColorHex: process.env.REACT_APP_PRIMARY_COLOR_HEX || '#00a5d0',
  primaryColorRgb: process.env.REACT_APP_PRIMARY_COLOR_RGB || '0, 165, 208',
  secondaryColorHex: process.env.REACT_APP_SECONDARY_COLOR_HEX || '#2c4a7e',
  secondaryColorRgb: process.env.REACT_APP_SECONDARY_COLOR_RGB || '2, 102, 128',
  dangerColorHex: process.env.REACT_APP_DANGER_COLOR_HEX || '#eb445a',
  dangerColorRgb: process.env.REACT_APP_DANGER_COLOR_RGB || '235, 68, 90',
}

const CssTheme = {
  '--ion-color-primary': AppTheme.primaryColorHex,
  '--ion-color-primary-rgb': AppTheme.primaryColorRgb,
  '--ion-color-secondary': AppTheme.secondaryColorHex,
  '--ion-color-secondary-rgb': AppTheme.secondaryColorRgb,
  '--ion-color-danger': AppTheme.dangerColorHex,
  '--ion-color-danger-rgb': AppTheme.dangerColorRgb,
}

// set every var
const style = document.documentElement.style
Object.keys(CssTheme).forEach((key) => {
  style.setProperty(key, propOr('', key, CssTheme))
})

import React from 'react'
import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import './AddLinkFieldMenuItem.css'

export interface AddLinkFieldMenuItemProps {
  title: string
  icon?: string
  isRootNode?: boolean
  onClick: () => void
}

const AddLinkFieldMenuItem: React.FC<AddLinkFieldMenuItemProps> = ({
  title,
  icon,
  isRootNode,
  onClick,
}) => {
  return (
    <IonItem button lines="full" detail={false} onClick={onClick}>
      <IonIcon
        icon={add}
        slot="start"
        size="small"
        color="primary"
        className="add-link-field-item-icon"
      />
      <IonLabel
        color="dark"
        className="add-link-field-item-label ion-text-wrap"
      >
        {title}
      </IonLabel>
    </IonItem>
  )
}

export default AddLinkFieldMenuItem

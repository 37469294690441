import { useQuery } from 'react-query'

import { getIdFields } from 'services/layouts'

const useIdFields = (modelId: string) => {
  return useQuery(
    ['idFields', modelId],
    (_key, modelId: string) => getIdFields(modelId),
    // NOTE never dispose from cached, only after config is reloaded will the
    // the query be invalidated (see `useConfig`)
    // NOTE instead of preventing all kinds of refetching strategies, just set
    // the `staleTime` to infinity
    { cacheTime: Infinity, staleTime: Infinity },
  )
}

export default useIdFields

export interface Translation {
  /**
   * Locale/language key
   */
  locale: string
  /**
   * Property name
   */
  key: string
  /**
   * Translation for the property on the specified locale
   */
  value: string
}

interface TranslatableModel {
  locales?: Translation[]
}
/**
 *
 * @param locale Current locale to search
 * @param property Property name to translate
 * @param objectWithTranslations
 */
export function getLocalizedProperty(
  locale: string,
  property: string,
  objectWithTranslations: TranslatableModel,
): string {
  return (
    objectWithTranslations.locales?.find(
      (l) => l.key === property && l.locale === locale,
    )?.value || (objectWithTranslations as any)[property]
  )
}

import {
  Submission,
  SubmissionStatus,
  submissionTypeToOrder,
} from 'core/Submission'
import { prop, sortBy } from 'ramda'
import { getSubmissionByStatus, getSubmissions } from 'services/submissions'

export const CacheKeys = {
  SubmissionByStatus: 'submissionsByStatus',
  Submission: 'submission',
  RecentSubmissions: 'recentSubmissions',
}

const sortByLastUpdated = sortBy(prop('lastUpdatedAt'))

const sortByType = sortBy((submission: Submission) =>
  submissionTypeToOrder(submission.type),
)

const makeSortByStatus = (status: SubmissionStatus) => {
  switch (status) {
    case SubmissionStatus.uploaded:
    case SubmissionStatus.new:
    case SubmissionStatus.duplicated:
    case SubmissionStatus.draft:
      return sortByLastUpdated
    case SubmissionStatus.finished:
      return sortByType
  }
}

// REVIEW why are we using different sorting mechanisms? can we unify them?
export const getSubmissionsByStatus =
  (status: SubmissionStatus | SubmissionStatus[]) => () => {
    const sortFn = Array.isArray(status)
      ? sortByLastUpdated
      : makeSortByStatus(status)

    return getSubmissionByStatus(status).then(sortFn)
  }

export const getRecentSubmissions = () => () => {
  return getSubmissions().then((submissions) => {
    const now = new Date()
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000) // Fecha y hora hace 24 horas

    const recentSubmissions = submissions.filter((submission) => {
      const lastUpdatedAt = submission.lastUpdatedAt
        ? new Date(submission.lastUpdatedAt)
        : null
      return lastUpdatedAt && lastUpdatedAt >= twentyFourHoursAgo
    })

    const sortedRecentSubmissions =
      sortByLastUpdated(recentSubmissions).reverse()

    return sortedRecentSubmissions
  })
}

import React, { useCallback } from 'react'
import { IonText, IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { refreshOutline } from 'ionicons/icons'
import FormField from '../forms/FormField'
import { GeoLocation } from 'core/Geolocation'
import { FieldProps } from './common'
import useCurrentLocation from 'hooks/useCurrentLocation'
import useForm from 'hooks/useForm'
import { useDispatchContext } from 'context/FormContext'
import * as Actions from 'context/FormContext/actions'
import useTranslation from 'hooks/useTranslation'

export interface GeolocationProps extends FieldProps {
  value?: GeoLocation
  ownPosition?: boolean
}

const Geolocation: React.FC<GeolocationProps> = ({
  readOnly,
  name,
  value: fieldValue,
  label,
  errorText,
  hasError,
  isRequired,
  isLoading,
  onChange,
  ownPosition,
  tabIndex,
}) => {
  const { state } = useForm()
  const dispatch = useDispatchContext()

  const setLocation = useCallback(
    (result: GeoLocation) => {
      // NOTE store location in field value if `ownPosition` enabled
      if (ownPosition) onChange(name, result)
      // NOTE otherwise set the location as the global location
      else dispatch(Actions.setLocation(result))
    },
    [ownPosition, onChange, name, dispatch],
  )

  const { getCurrentLocation, isFetching } = useCurrentLocation((result) =>
    setLocation(result),
  )

  const t = useTranslation()

  const value = ownPosition ? fieldValue : state.location

  return (
    <FormField
      label={label}
      hint={`${t('settings.gpsErrorRange')}: ${value ? value.accuracy : '-'}m`}
      hasError={hasError}
      errorText={errorText}
      isRequired={isRequired}
      tabIndex={tabIndex}
    >
      <IonText className="select-values">
        {value ? (
          <IonText>{`${value.latitude}, ${value.longitude}`}</IonText>
        ) : (
          <IonText color="medium">{t('withoutValue')}</IonText>
        )}
      </IonText>
      {!readOnly ? (
        <IonButton
          slot="end"
          shape="round"
          fill="clear"
          className="ion-no-padding ion-margin-left"
          disabled={isLoading || isFetching}
          onClick={getCurrentLocation}
        >
          {isLoading || isFetching ? (
            <IonSpinner color="disabled" />
          ) : (
            <IonIcon slot="icon-only" color="primary" icon={refreshOutline} />
          )}
        </IonButton>
      ) : null}
    </FormField>
  )
}

export default Geolocation

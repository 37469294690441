import React, { useEffect, useState } from 'react'
import { readFile } from 'services/files'
import { IonImg } from '@ionic/react'
import './Image.css'

type HintImageProps = {
  fileId?: string
}

const HintImage: React.FC<HintImageProps> = (props) => {
  const [fileURL, setFileURL] = useState<string>()
  useEffect(() => {
    if (!props.fileId) return
    let url: string
    readFile(props.fileId).then((fileObj) => {
      if (fileObj?.blob) {
        url = URL.createObjectURL(fileObj.blob)
        setFileURL(url)
      }
    })

    return () => {
      url && URL.revokeObjectURL(url)
    }
  }, [props.fileId])
  if (!fileURL) return null
  return <IonImg className="resize-image" src={fileURL} />
}

export default React.memo(HintImage)

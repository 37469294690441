import React from 'react'
import { IonList } from '@ionic/react'
import AppLayout from 'ui/layouts/AppLayout'
import AssetNav from 'ui/components/menus/AssetNav'
import AssetNavItem from 'ui/components/menus/AssetNavItem'
import { ContentModel, getPluralTitle } from 'core/ContentModel'
import { useCurrentLocale } from 'context/LocaleContext'

interface ChooseAssetImportProps {
  assetModels: ContentModel[]
  isFetching?: boolean
  onImportModel: (id: string) => void
  title: string
}

const ChooseAssetImport: React.FC<ChooseAssetImportProps> = (props) => {
  const {
    assetModels = [],
    isFetching,
    onImportModel,
    title = 'Importar activos',
  } = props
  const locale = useCurrentLocale()
  return (
    <AppLayout pageTitle={title} hideBackLink>
      <IonList>
        <AssetNav isLoading={isFetching}>
          {assetModels.map((model) => (
            <AssetNavItem
              key={model.id}
              title={getPluralTitle(locale, model)}
              onClick={() => onImportModel(model.id)}
            />
          ))}
        </AssetNav>
      </IonList>
    </AppLayout>
  )
}

export default ChooseAssetImport

import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import {
  Prompt as RouterPrompt,
  useLocation,
  useHistory,
  PromptProps,
} from 'react-router'
import useModel from 'hooks/useModel'
import { useSubmission, useSaveSubmission } from 'hooks/submission'
import useForm from 'hooks/useForm'
//import { useAsset } from 'hooks/assets'
import {
  SubmissionStatus,
  Submission,
  SubmissionType,
  SubmissionSourceType,
} from 'core/Submission'
import { extractTitle, getTitle } from 'core/ContentModel'
import { getLocalizedProperty } from 'core/common'
import AppLayout from '../ui/layouts/AppLayout'
import EditLayout from '../ui/components/formLayouts'
import FormSkeleton from '../ui/components/loaders/FormSkeleton'
import LinkList from './LinkList'
import TreeNavigation from 'ui/components/menus/TreeNavigation'
import useCurrentLocation from 'hooks/useCurrentLocation'
import useTranslation from 'hooks/useTranslation'
import { useCurrentLocale } from 'context/LocaleContext'
import TreeNavigationButton from 'ui/components/buttons/TreeNavigationButton'
import { InspectedAssetProvider } from 'context/InspectedAssetContext'
import { ExpressionProvider } from 'context/ExpressionContext'
import EditorFooter from 'components/EditorFooter'
import ConfirmationAlert from 'ui/components/alerts/ConfirmationAlert'
import { useDispatchContext, useModelSummary } from 'context/FormContext'
import * as Actions from 'context/FormContext/actions'
import { useSubmissionTreeNavigation } from 'hooks/useSubmissionTreeNavigation'
import { propOr } from 'ramda'
import duplicateChildren from 'context/FormContext/duplicateChildren'
import { IonAlert, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import useSubmissionSource from 'hooks/submission/useSubmissionSource'

const Prompt = RouterPrompt as unknown as React.ComponentClass<PromptProps>

export type EditSubmissionParams = {
  modelId: string
  submissionId: string
  assetId?: string
}

export type EditSubmissionProps = {
  type: SubmissionType
  sourceType: SubmissionSourceType
  submissionId: string
  modelId: string
  assetId?: string
  isEdit: boolean
}

type LocationState = {
  initialData: Submission
  readOnly: boolean
}

export default function EditSubmission(props: EditSubmissionProps) {
  const {
    type,
    sourceType = SubmissionSourceType.Remote,
    modelId: initialModelId,
    assetId,
    isEdit = false,
  } = props

  const t = useTranslation()
  const locale = useCurrentLocale()

  const location = useLocation<LocationState | undefined>()
  const { goBack, push } = useHistory()

  const {
    state,
    model,
    currentHistory,
    getCurrentFormData,
    layoutWithFields,
    linkFields,
    isTreeStructure,
    selectedLinkField,
  } = useForm()
  const { getModelSummary, hasModelSummary } = useModelSummary()

  const dispatch = useDispatchContext()
  const [showTreeNavigation, setShowTreeNavigation] = useState<{
    open: boolean
    event?: Event
  }>({ open: false, event: undefined })
  const submissionIdRef = useRef<string>()

  const isViewVisible = useRef(true)

  useIonViewDidEnter(() => {
    isViewVisible.current = true
    submissionIdRef.current = props.submissionId
    submissionIdRef.current &&
      dispatch(Actions.init(submissionIdRef.current, initialModelId))

    if (location.state?.initialData) {
      dispatch(Actions.setInitialData(location.state.initialData))
    }
  }, [initialModelId, props.submissionId])

  useIonViewDidLeave(() => {
    isViewVisible.current = false
    submissionIdRef.current = undefined
    dispatch(Actions.reset())
  }, [])

  // if inspection, remote asset
  const { data: inspectedAsset } = useSubmissionSource({
    id: assetId,
    sourceType,
  })
  // if inspection, inspected asset model
  const { data: assetModel } = useModel(inspectedAsset?.assetType)
  const { saveSubmission } = useSaveSubmission()
  const { isSuccess: isSubmissionLoaded } = useSubmission(
    submissionIdRef.current!,
    {
      enabled: Boolean(submissionIdRef.current),
      onSuccess: (submission: Submission) => {
        if (submission !== undefined)
          dispatch(Actions.setInitialData(submission))
      },
    },
  )

  const {
    getCurrentLocation,
    isFetching: isFetchingLocation,
    error: positionError,
  } = useCurrentLocation((result) => {
    if (isViewVisible.current) dispatch(Actions.setLocation(result))
  })

  useEffect(() => {
    if (
      (type === SubmissionType.NewAsset ||
        type === SubmissionType.AssetRevision) &&
      isSubmissionLoaded &&
      !state.location &&
      !isFetchingLocation &&
      !positionError
    ) {
      getCurrentLocation()
    }
  }, [
    type,
    getCurrentLocation,
    isSubmissionLoaded,
    isFetchingLocation,
    positionError,
    state.location,
  ])

  const [showExitAlert, setShowExitAlert] = useState(false)

  const treeNavigationOptions = useSubmissionTreeNavigation()

  const handleBack = useCallback(() => {
    if (state.history.length > 1) {
      dispatch(Actions.goBack())
    } else {
      goBack()
    }
  }, [dispatch, goBack, state.history.length])

  const handleConfirmExit = useCallback(() => {
    push('/take-data/in-progress')
  }, [push])

  const handleDismissExitAlert = useCallback(() => {
    setShowExitAlert(false)
  }, [])

  const goToChild = useCallback(
    (
      modelId: string,
      childId: string,
      showIndex = false as boolean,
      index?: number | null,
    ) => {
      dispatch(Actions.goToChild(modelId, childId, showIndex, index))
    },
    [dispatch],
  )

  const handleSave = useCallback(
    async (withStatus: SubmissionStatus) => {
      if (model && submissionIdRef.current) {
        dispatch(Actions.markAsUntouched())
        await saveSubmission({
          id: submissionIdRef.current,
          type: type,
          status: withStatus,
          data: state.rootData,
          children: state.formChildren,
          modelId: initialModelId,
          modelType: model.modelType,
          files: state.files,
          sourceId: state.sourceId || assetId,
          sourceModelId: state.sourceModelId || assetModel?.id,
          //only for inspections of original assets
          inventoryId: state.inventoryId ?? inspectedAsset?.inventoryId,
          sourceType: sourceType,
          location: state.location,
        })
          .then(() => {
            if (withStatus === SubmissionStatus.finished) {
              push('/take-data/in-progress')
            }
          })
          .catch((e: Error) => {
            dispatch(Actions.setError(e.message))
          })
      }
    },
    [
      model,
      saveSubmission,
      type,
      sourceType,
      state.rootData,
      state.formChildren,
      state.files,
      state.sourceId,
      state.sourceModelId,
      state.inventoryId,
      state.location,
      initialModelId,
      assetId,
      assetModel,
      inspectedAsset,
      dispatch,
      push,
    ],
  )

  const pageTitle = useMemo(() => {
    const label = model ? getTitle(locale, model) : ''
    const index =
      currentHistory && currentHistory.indexInParent
        ? ' ' + currentHistory.indexInParent
        : ''

    if (location.state?.readOnly) return label + index

    const i18nKey = isEdit ? 'editor.editTitle' : 'editor.createTitle'
    return t(i18nKey, {
      label: label + index,
    })
  }, [location, model, t, locale, currentHistory, isEdit])

  const pageSubtitle = useMemo(() => {
    let assetModelLabel = assetModel
      ? getLocalizedProperty(locale, 'label', assetModel)
      : ''
    let assetTitle = extractTitle(assetModel, inspectedAsset)

    return inspectedAsset ? `${assetModelLabel || ''} ${assetTitle || ''}` : ''
  }, [assetModel, inspectedAsset, locale])

  const addChild = useCallback(
    (linkName: string, linkModelId: string) => {
      dispatch(Actions.addChild(linkName, linkModelId))
    },
    [dispatch],
  )
  const duplicateChild = useCallback(
    (childId: string) => {
      const { newId, ancestors, parentField, duplicatedChildren } =
        duplicateChildren(childId, state.formChildren, getModelSummary)
      dispatch(
        Actions.duplicateChild(
          newId,
          ancestors,
          parentField,
          duplicatedChildren,
        ),
      )
    },
    [dispatch, getModelSummary, state.formChildren],
  )

  return (
    <InspectedAssetProvider
      inspectedAssetId={assetId}
      model={model}
      sourceType={sourceType}
    >
      <ExpressionProvider
        rootData={state.rootData}
        modelId={currentHistory?.modelId}
        rootModelId={initialModelId}
        data={
          currentHistory?.childId
            ? state.formChildren[currentHistory.childId].data
            : state.rootData
        }
        formChildren={state.formChildren}
        ancestors={
          currentHistory?.childId
            ? state.formChildren[currentHistory.childId]?.ancestors
            : []
        }
      >
        <Prompt
          when={state.isDirty && !!isViewVisible.current}
          message={t('confirmation.pendingChanges')}
        />
        <IonAlert
          isOpen={state.lastError != null}
          header={t('notifications.saveError')}
          message={state.lastError}
          buttons={[
            {
              text: t('buttons.ok'),
              handler() {
                dispatch(Actions.cleanError())
              },
            },
          ]}
        />
        <AppLayout
          pageTitle={pageTitle}
          pageSubtitle={pageSubtitle}
          onBackClick={handleBack}
          footer={
            <EditorFooter
              hasAddLinkMenu={!!linkFields.length}
              onSave={() => handleSave(SubmissionStatus.draft)}
              onSaveAndFinish={() => handleSave(SubmissionStatus.finished)}
              onAddLink={addChild}
              onExit={goBack}
              showGPS={
                type === SubmissionType.NewAsset ||
                type === SubmissionType.AssetRevision
              }
              hasLocation={state.location != null}
              isFetchingLocation={isFetchingLocation}
              onGPSClick={getCurrentLocation}
              readOnly={location.state?.readOnly}
            />
          }
          extra={
            isTreeStructure && treeNavigationOptions.length ? (
              <div className="tree-navigation-container">
                <TreeNavigationButton
                  onClick={(e) =>
                    setShowTreeNavigation({
                      open: true,
                      event: e.nativeEvent,
                    })
                  }
                />
                <TreeNavigation
                  isOpen={showTreeNavigation.open}
                  event={showTreeNavigation.event}
                  treeItems={treeNavigationOptions}
                  onDidDismiss={() =>
                    setShowTreeNavigation({
                      open: false,
                      event: undefined,
                    })
                  }
                />
              </div>
            ) : null
          }
        >
          {selectedLinkField ? (
            <LinkList
              readOnly={location.state?.readOnly}
              children={state.formChildren}
              navigateTo={goToChild}
              onAdd={() =>
                addChild(selectedLinkField.name, selectedLinkField.ofType)
              }
              onRemove={(childId) => dispatch(Actions.removeChild(childId))}
              value={propOr([], selectedLinkField.name, getCurrentFormData())}
              onDuplicate={duplicateChild}
              allowDuplicate={hasModelSummary}
              selectedLinkField={selectedLinkField}
            />
          ) : layoutWithFields != null ? (
            <EditLayout
              key={initialModelId}
              layout={layoutWithFields}
              readOnly={location.state?.readOnly}
            />
          ) : (
            <FormSkeleton />
          )}
          <ConfirmationAlert
            isOpen={showExitAlert}
            acceptText={t('buttons.confirmSelection')}
            cancelText={t('buttons.cancelSelection')}
            description={t('confirmation.pendingChanges')}
            onAccept={handleConfirmExit}
            onDidDismiss={handleDismissExitAlert}
          />
          <IonAlert
            isOpen={state.confirmLocation}
            message={t('confirmation.replaceLocation')}
            buttons={[
              {
                text: t('buttons.cancel'),
                role: 'Cancel',
                cssClass: 'secondary',
                handler() {
                  dispatch(Actions.discardNewLocation())
                },
              },
              {
                text: t('buttons.ok'),
                handler() {
                  dispatch(Actions.replaceLocation())
                },
              },
            ]}
          />
        </AppLayout>
      </ExpressionProvider>
    </InspectedAssetProvider>
  )
}

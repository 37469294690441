import React from 'react'
import ContentHeader from 'ui/layouts/headers/ContentHeader'
import './GroupInnerContent.css'

export interface GroupInnerContentProps {
  title: string
  children: any
}

const GroupInnerContent: React.FC<GroupInnerContentProps> = ({
  title,
  children,
}) => {
  return (
    <div className="group-inner-content">
      <ContentHeader title={title} />
      {children}
    </div>
  )
}

export default GroupInnerContent

import React, { useState, useCallback, useMemo } from 'react'
import {
  IonText,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonContent,
  IonModal,
  IonFooter,
  IonButton,
  IonList,
  IonChip,
  IonButtons,
} from '@ionic/react'
import { caretDownSharp, trash } from 'ionicons/icons'
import { without, append } from 'ramda'
import FormField from '../forms/FormField'
import SelectOption from './SelectOption'
import './Select.css'
import { Translation, getLocalizedProperty } from 'core/common'
import useTranslation from 'hooks/useTranslation'
import { useCurrentLocale } from 'context/LocaleContext'
import { FieldProps } from './common'

const MIN_SEARCH_OPTIONS = Number(
  process.env.REACT_APP_SELECT_SEARCH_MIN_OPTIONS || 5,
)

export interface SelectProps extends FieldProps {
  options?: Array<{
    id?: string
    value: string
    label: string
    locales: Translation[]
    mediaFileId?: string
  }>
  isMultiple?: boolean
  isLabelSmall?: boolean
  value?: string | string[]
  onChange: (name: string, value: string | string[] | null) => void
}

const Select: React.FC<SelectProps> = ({
  readOnly,
  name,
  label,
  hint,
  options = [],
  isMultiple,
  isRequired,
  isLabelSmall,
  hasError,
  errorText,
  value,
  onChange,
  fileId,
}) => {
  const locale = useCurrentLocale()
  const [showModal, setShowModal] = useState(false)
  const [filterText, setFilterText] = useState('')
  const t = useTranslation()

  const handleCancelSelection = useCallback(() => {
    setShowModal(false)
  }, [])
  const handleAddSelection = useCallback(() => {
    setShowModal(false)
  }, [])
  const handleClearSelection = useCallback(() => {
    onChange(name, null)
    setShowModal(false)
  }, [name, onChange])

  const handleSearchChange = useCallback((e: any) => {
    if (e.detail.value) {
      setFilterText(e.detail.value)
    } else {
      setFilterText('')
    }
  }, [])

  const handleItemSelect = useCallback(
    (itemValue: string) => {
      if (isMultiple) {
        let currentValues = value || []
        // if item was already select, remove it
        // otherwise add it
        let transform = currentValues.includes(itemValue)
          ? without([itemValue])
          : append(itemValue)
        onChange(name, transform(currentValues as string[]))
      } else {
        onChange(name, itemValue)
        setShowModal(false)
      }
    },
    [isMultiple, onChange, name, value],
  )

  // list item "value" is stored as data
  // but matching "label" is displayed to the user
  const selectedLabels = useMemo(() => {
    switch (isMultiple) {
      case true: {
        const selectedOptions = Array.isArray(value)
          ? value.map((v) => options.find((opt) => opt.value === v))
          : []
        return selectedOptions
          .filter((x) => x != null)
          .map((x) => getLocalizedProperty(locale, 'label', x!))
      }
      case false:
        const selectedOption = value
          ? options.find((opt) => opt.value === value)
          : null
        return selectedOption
          ? getLocalizedProperty(locale, 'label', selectedOption)
          : ''
    }
  }, [value, options, isMultiple, locale])

  const filteredOptions = useMemo(() => {
    if (!filterText.trim()) return options
    return options.filter((o) => {
      const label = getLocalizedProperty(locale, 'label', o)
      return ~label.toUpperCase().indexOf(filterText.toUpperCase())
    })
  }, [locale, filterText, options])

  return (
    <>
      <FormField
        readOnly={readOnly}
        label={label}
        hint={hint}
        hasError={hasError}
        errorText={errorText}
        isRequired={isRequired}
        isClickable
        fileId={fileId}
        onClick={() => setShowModal(true)}
        isLabelSmall={isLabelSmall}
        classnames="select-field"
      >
        {value ? (
          <IonText className="select-values">
            {isMultiple ? (
              (selectedLabels as string[]).map((text: string) => (
                <IonChip outline className="select-chip" key={text}>
                  {text}
                </IonChip>
              ))
            ) : (
              <IonText>{selectedLabels}</IonText>
            )}
          </IonText>
        ) : (
          <IonText className="select-placeholder">
            {readOnly ? t('withoutValue') : t('select.placeholder')}
          </IonText>
        )}

        {!readOnly ? (
          <div className="select-icon-container">
            <IonIcon icon={caretDownSharp} slot="end" className="select-icon" />
          </div>
        ) : null}
      </FormField>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{label}</IonTitle>
          </IonToolbar>
          {options.length >= MIN_SEARCH_OPTIONS ? (
            <IonToolbar>
              <IonSearchbar
                placeholder={t('search.placeholder')}
                onIonChange={handleSearchChange}
              />
            </IonToolbar>
          ) : null}
        </IonHeader>
        <IonContent>
          <IonList>
            {filteredOptions.map((option) => (
              <div key={option.value}>
                <SelectOption
                  key={option.value}
                  value={option.value}
                  label={getLocalizedProperty(locale, 'label', option)}
                  onSelect={handleItemSelect}
                  selected={
                    isMultiple
                      ? (value || []).includes(option.value)
                      : value === option.value
                  }
                  fileId={option.mediaFileId}
                />
              </div>
            ))}
          </IonList>
        </IonContent>
        <IonFooter className="select-footer">
          <IonToolbar className="select-toolbar">
            <IonButtons className="select-button-group">
              <IonButton
                className="select-button-clear"
                color="danger"
                onClick={handleClearSelection}
              >
                <IonIcon icon={trash} size="small" />
                <IonText className="button-text">
                  {t('buttons.clearSelection')}
                </IonText>
              </IonButton>
              <IonButton
                className="select-button-cancel"
                expand="block"
                onClick={handleCancelSelection}
              >
                <IonText className="button-text">
                  {t('buttons.cancelSelection')}
                </IonText>
              </IonButton>
              <IonButton
                className="select-button-confirm"
                color="primary"
                fill="solid"
                expand="block"
                onClick={handleAddSelection}
              >
                <IonText className="button-text">
                  {t('buttons.confirmSelection')}
                </IonText>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  )
}

export default Select

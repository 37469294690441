import React from 'react'
import {
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButtons,
} from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'

type FullImagePreviewProps = {
  isOpen: boolean
  title?: string
  src?: string
  onClose: () => void
}

const FullImagePreview: React.FC<FullImagePreviewProps> = (props) => {
  const { isOpen, title, src, onClose } = props
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonIcon icon={closeCircleOutline} size="large" onClick={onClose} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            overflow: 'scroll',
            height: '100%',
            width: 'auto',
            //width: '100%'
          }}
        >
          <img
            className="img-full-zoom"
            style={{
              maxWidth: 'inherit',
              height: '100%',
            }}
            src={src}
            alt={title}
          />
        </div>
      </IonContent>
    </IonModal>
  )
}

export default FullImagePreview
